import {combineReducers} from "redux";
import Categories from "./Categories";
import Brands from "./Brands";
import vouchers from "./vouchers";
import Sliders from "./Sliders";
import Carts from "./Carts";
import OrderHistory from "./OrderHistory";


const mainReducers = combineReducers({
    Categories,
    Brands,
    vouchers,
    Sliders,
    Carts,
    OrderHistory
});

export default mainReducers;
