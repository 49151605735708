let initialState = {
    SlidersList: []
};

const Sliders = (state = initialState, action) => {
    switch (action.type) {
        case 'SET_SLIDERS':
            return {...state, SlidersList: action.setSliders};

        default:
            return state;
    }
};

export default Sliders;
