import {connect} from "react-redux";
import {ssoInstance} from "gg-react-utilities";
import Config from "../../core/Config";
import * as actions from "../../core/actions";
import OrderHistoryPage from "../components/OrderHistoryPage";

const mapStateToProps = state => {
    return {
        OrderHistoryData: state.OrderHistory.OrderHistoryList,
    }
};

const mapDispatchToProps = dispatch => {    
    return { 
        getOrderHistory: (url) => { 
            if (!url) url = Config.API_URL + "/order-history/1";
            
            return ssoInstance.get(url)
                .then((res) => { 
                    let sources = res.data;

                    if (sources.status === true)
                        dispatch(actions.setOrderHistory(sources.data));
                    else
                        dispatch(actions.setOrderHistory([]));
                })
                .catch((errors) => {
                    if (errors.response.status !== 401) {
                        /*let message = "";
                        if (errors.response) {
                            message = errors.response.message ? errors.response.message : "Something went wrong!";
                        } else {
                            message = "Something went wrong!";
                        }
                        Toaster.create({
                            position: Position.TOP,
                        }).show({message: message, intent: Intent.DANGER});*/

                        dispatch(actions.setOrderHistory([]));
                    }
                })
        }
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(OrderHistoryPage);
