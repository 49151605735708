import React, {Component} from 'react';
import {Button, ControlGroup, Dialog, FormGroup, InputGroup, Intent} from "@blueprintjs/core";
import ModalPopUpDefaultAddress from "./ModalPopUpDefaultAddress";
import {Auth,ssoInstance} from 'gg-react-utilities';
import Config from '../../core/Config';
import { Position, Toaster} from "@blueprintjs/core";
export default class BillingComponent extends Component {
    constructor(props) {
        super(props);

        this.state = {
            is_default_address_open: false,
            selected_address: "",
            selected_object_address: "",
            user_address: props.userAddress,
            billing_name: "",
            billing_email: "",
            billing_mobile: "",
            sameAddress:'',
            form_validation: props.form_validation
        };
    }

    componentWillMount() {
        if (Auth.isLoggedIn()) {
            let firstName = Auth.user().fname !== null && Auth.user().fname !== "" ? Auth.user().fname + " " : "";
            let middleName = Auth.user().mname !== null && Auth.user().mname !== ""  ? Auth.user().mname + " " : "";
            let lastName = Auth.user().lname !== null && Auth.user().lname !== "" ? Auth.user().lname : "";

            this.setState({
                billing_name: firstName + middleName + lastName,
                billing_email: Auth.user().email,
                billing_mobile: Auth.user().mobile
            })
        }
    }

    componentWillReceiveProps(nextProps) {
        if(nextProps.userAddress.length > 0) {
            var selectAddress = nextProps.userAddress.filter(a => a.is_default === '1');
            let data = selectAddress[0].street + ',' + selectAddress[0].landmark + ',' + selectAddress[0].area + ',' + selectAddress[0].city + ',' + selectAddress[0].state + '-' + selectAddress[0].pincode
            localStorage.setItem('checkoutBillingAdd', data)
            localStorage.setItem('billingCity', selectAddress[0].city)
            localStorage.setItem('billingState', selectAddress[0].state)
            localStorage.setItem('billingPincode', selectAddress[0].pincode)

            this.setState({
                sameAddress: data,
                user_address: nextProps.userAddress,
                form_validation: nextProps.form_validation
            })
        }
    }

    handleDefaultAddressOpen = () => {
        this.setState({'is_default_address_open': true})
    };
    
    handleDefaultAddressClose = () => {
        this.setState({
            selected_address: '',
            is_default_address_open: false,
            selected_object_address: ''
        }, () => {
            this.props.callbackBillingAddress(
                this.state.billing_name,
                this.state.billing_email,
                this.state.billing_mobile,
                this.state.selected_address,
                this.state.selected_object_address
            );
        });
    };

    handleSelectedAddressClose = (close, address, object_address) => {
         localStorage.setItem('newSelectAddress',address)
       this.updateAddress(object_address);
           this.setState({
            selected_address: address,
            is_default_address_open: false,
            selected_object_address: object_address
        }, () => {
            this.props.callbackBillingAddress(
                this.state.billing_name,
                this.state.billing_email,
                this.state.billing_mobile,
                this.state.selected_address,
                this.state.selected_object_address
            );
        });
    };
    updateAddress = (addressData) => {
        if (Auth.isLoggedIn()) {
            const ROOT_URL = Config.APP_ACCOUNT_API_URL;
            const UPDATE_ADDRESS_API = '/api/v1.0.2/user/' + Auth.user().user_id + '/address/'+addressData.add_id;
            let defultAdd={
            "street":addressData.street
            ,"landmark":addressData.landmark
            ,"area":addressData.area,
            "latitude":addressData.latitude,
            "longitude":addressData.longitude
            ,"places_id":addressData.places_id,
            "city":addressData.city,
            "state":addressData.state,
            "address_type":addressData.address_type,
            "is_default":"1",
            "pincode":addressData.pincode
        }
            return ssoInstance({
                baseURL: `${ROOT_URL}`,
                url: `${UPDATE_ADDRESS_API}`,
                method: 'patch',
                data:defultAdd
            }).then(res => {
                const result = res.data;
                Toaster.create({
                    position: Position.TOP,
                }).show({message: result.message, intent: Intent.SUCCESS})
                this.props.callbackAddressSave(true);
                this.closeDialog();
            }).catch(error => {
                /*(error.response.data.error instanceof Array) && error.response.data.error.map((message, key) => {
                    // toast.error(message, {
                    //     position: toast.POSITION.TOP_CENTER
                    // });
                })*/
            }).then(() => {
                this.setState({
                    showLoader: false,
                });
            });
        }
    };

    render() {
        let nameMSG = "";
        let emailMSG = "";
        let mobileMSG = "";
        let addrerssMSG = "";
        let nameClass = "";
        let emailClass = "";
        let mobileClass = "";
        let addrerssClass = "";

        if (this.state.form_validation === true) {
            if (this.state.billing_name === "") {
                nameMSG = "The name field should be required.";
                nameClass = Intent.DANGER;
            }

            if (this.state.billing_email === "") {
                emailMSG = "The email field should be required.";
                emailClass = Intent.DANGER;
            }

            if (this.state.billing_mobile === "") {
                mobileMSG = "The mobile field should be required.";
                mobileClass = Intent.DANGER;
            }

            if (this.state.sameAddress === "") {
                addrerssMSG = "The address field should be required.";
                addrerssClass = Intent.DANGER;
            }

            if (typeof this.state.billing_email !== "undefined" && this.state.billing_email !== null && this.state.billing_email !== "") {
                let pattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);
                if (!pattern.test(this.state.billing_email)) {
                    emailMSG = "Please enter valid email-ID.";
                    emailClass = Intent.DANGER;
                }
            }

            if (typeof this.state.billing_mobile !== "undefined" && this.state.billing_mobile !== null && this.state.billing_mobile !== "") {
                if (!this.state.billing_mobile.match(/^[0-9]{10}$/)) {
                    mobileMSG = "Please enter valid mobile no.";
                    mobileClass = Intent.DANGER;
                }
            }
        }

        return (
            <React.Fragment>
                <div className="row mb-2">
                    <div className="col-md-12 col-12">
                        <div className="panel panel-default">
                            <div className="panel-heading">Billing Details</div>
                            <div className="panel-body">
                                <div className="row">
                                    <div className="col-xl-12 col-lg-12 col-md-12 col-12">
                                        <form className="form-horizontal">
                                            <div className="row form-data">
                                                <div className="col-md-6 col-12 text-group">
                                                    <FormGroup
                                                        helperText={nameMSG} intent={nameClass}>
                                                        <InputGroup
                                                            intent={nameClass}
                                                            placeholder="Billing Full Name"
                                                            value={this.state.billing_name}
                                                            onChange={(e) => {
                                                                this.setState({billing_name: e.target.value}, () => {
                                                                    this.props.callbackBillingAddress(
                                                                        this.state.billing_name,
                                                                        this.state.billing_email,
                                                                        this.state.billing_mobile,
                                                                        this.state.selected_address,
                                                                        this.state.selected_object_address
                                                                    );
                                                                });
                                                            }}
                                                        />
                                                    </FormGroup>
                                                </div>
                                                <div
                                                    className="col-md-6 col-12 text-group mt-xl-0 mt-lg-0 mt-md-0 mt-sm-4 mt-s-3">
                                                    <FormGroup
                                                        helperText={emailMSG} intent={emailClass}>
                                                        <InputGroup
                                                            intent={emailClass}
                                                            placeholder="Billing Email"
                                                            value={this.state.billing_email}
                                                            onChange={(e) => {
                                                                this.setState({billing_email: e.target.value}, () => {
                                                                    this.props.callbackBillingAddress(
                                                                        this.state.billing_name,
                                                                        this.state.billing_email,
                                                                        this.state.billing_mobile,
                                                                        this.state.selected_address,
                                                                        this.state.selected_object_address
                                                                    );
                                                                });
                                                            }}
                                                        />
                                                    </FormGroup>
                                                </div>
                                                <div className="col-md-6 col-12 text-group mt-4 mt-s-3">
                                                    <FormGroup
                                                        helperText={mobileMSG} intent={mobileClass}>
                                                        <InputGroup
                                                            intent={mobileClass}
                                                            placeholder="Billing Mobile"
                                                            value={this.state.billing_mobile}
                                                            onChange={(e) => {
                                                                this.setState({billing_mobile: e.target.value}, () => {
                                                                    this.props.callbackBillingAddress(
                                                                        this.state.billing_name,
                                                                        this.state.billing_email,
                                                                        this.state.billing_mobile,
                                                                        this.state.selected_address,
                                                                        this.state.selected_object_address
                                                                    );
                                                                });
                                                            }}
                                                        />
                                                    </FormGroup>
                                                </div>
                                                <div className="col-md-6 col-12 text-group mt-4 mt-s-3">
                                                    <FormGroup
                                                        helperText={addrerssMSG} intent={addrerssClass}>
                                                        <ControlGroup fill={true} vertical={false}>
                                                            <InputGroup
                                                                intent={addrerssClass}
                                                                readOnly={"readOnly"}
                                                                placeholder="Billing Address"
                                                                defaultValue={this.state.selected_address!=''?this.state.selected_address:this.state.sameAddress}
                                                                
                                                            />
                                                            <Button intent={"primary"}
                                                                    className={"btn-group-icon"}
                                                                    icon="map-marker"
                                                                    onClick={this.handleDefaultAddressOpen}>&nbsp;</Button>
                                                        </ControlGroup>
                                                    </FormGroup>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Dialog
                    onClose={this.handleDefaultAddressClose}
                    title="Select Address"
                    isOpen={this.state.is_default_address_open}
                    lazy={true}
                    className="bp3-dialog-large"
                    canOutsideClickClose={false}
                >
                    <ModalPopUpDefaultAddress
                        userAddress={this.state.user_address}
                        callbackAddNewAddressCloseData={this.handleSelectedAddressClose}/>
                </Dialog>
            </React.Fragment>
        )
            ;
    }
}
