import { connect } from "react-redux";
import {Auth, ssoInstance} from "gg-react-utilities";
import Config from "../../core/Config";
import * as actions from "../../core/actions";
import GGVoucherComponent from '../component/GGVoucherComponent';

const mapStateToProps = state => {

    return {
    }
};

const mapDispatchToProps = dispatch => {
    return {
        getGGVoucherDetails : () => {
            return ssoInstance.get(Config.APP_WALLET_URL + "/v1.0.2/vouchers");
        },
        initiateOrder :(param) => {
            return ssoInstance.post(Config.APP_WALLET_URL + '/v1.0.2/vouchers/order/init', param);
        }
    }
};

export default connect( mapStateToProps, mapDispatchToProps )(GGVoucherComponent);
