import { connect } from "react-redux";
import { ssoInstance } from "gg-react-utilities";
import Config from "../../core/Config";
import * as actions from "../../core/actions";
import VoucherOrderDetails from '../component/ModalPopUpData'

const mapStateToProps = state => {

    return {
        voucherOrderDetails: state.vouchers.voucherOrderDetails,
        voucherStatus: state.vouchers.voucherStatus
    }
};

const mapDispatchToProps = dispatch => {
    return {
        getVoucherOrderDetail : (orderId,url) => {

            if(!url) url = Config.VOUCHER_APP_URL + "/v3/user/user-voucher-list/"+orderId;

            return ssoInstance.get(url)
                .then( (res) => {
                    const source = res.data;

                    if( source.status === true)
                        dispatch( actions.setVoucherOrderDetails( source.data) );
                    else
                        dispatch( actions.setVoucherOrderDetails( []) );
                })
                .catch( (error) => {
                    //const source = error.response;
                })
        },
        setVoucherStatus : (orderDetailId) => {

            let formData = new FormData();
            formData.append('voucher_order_detail_id',orderDetailId);

            return ssoInstance.post( Config.VOUCHER_APP_URL + "/v3/user/toggle-rewards",formData)
                .then( (res) => {
                    const source = res.data;

                    if(source.status === true)
                        dispatch( actions.setVoucherStatus( source.data))
                    else
                        dispatch( actions.setVoucherStatus([]))
                })
                .catch( (error) => {
                    //const source = error.response
                })
        }
    }
};

export default connect( mapStateToProps, mapDispatchToProps )(VoucherOrderDetails);