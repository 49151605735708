import {connect} from "react-redux";
import {ssoInstance} from "gg-react-utilities";
import Config from "../../core/Config";
import * as actions from "../../core/actions";
import CheckoutComponent from '../component/CheckoutComponent';
import {Toaster, Position, Intent} from "@blueprintjs/core";

const mapStateToProps = state => {
    return {
        CartListData: state.Carts.CartList,
    }
};

const mapDispatchToProps = dispatch => {
    return {
        unMount: () => {
            dispatch(actions.setCarts([]));
        },

        getCarts: (obj) => {
            return ssoInstance.post(Config.API_URL + "/get-cart", obj)
                .then((res) => {
                    let sources = res.data;

                    if (sources.status === true)
                        dispatch(actions.setCarts(sources.data));
                    else
                        dispatch(actions.setCarts([]));
                })
                .catch((errors) => {
                    if (errors.response.status !== 401) {
                        let message = "";
                        if (errors.response) {
                            message = errors.response.message ? errors.response.message : "Something went wrong!";
                        } else {
                            message = "Something went wrong!";
                        }
                        Toaster.create({
                            position: Position.TOP,
                        }).show({message: message, intent: Intent.DANGER});

                        dispatch(actions.setCarts([]));
                    }
                })
        },

        getUserAddress: (user_id) => {
            return ssoInstance.get(Config.APP_ACCOUNT_URL + "/" + user_id + "/address");
        }
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(CheckoutComponent);

