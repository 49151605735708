import React from 'react';
import LoadingOrderHistory from "./LoadingOrderHistory";
import {Button} from "@blueprintjs/core";
import VoucherOrderDetailsContainer from "../container/voucherOrderDetailsContainer";
import DetailDialog from './DetailDialog';
import EmptyState from "../../core/components/EmptyState"
import {Helper} from "gg-react-utilities";

export default class voucherOrderHistoryComponent extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            voucherOrderListing: [],
            voucherLoadMoreUrl:'',
            isLoadMore:true,
            loadingData : true,
            order_id : null
        }
    }

    componentWillMount() {

        this.props.getVoucherOrderList();
    }

    componentWillReceiveProps(nextProps) {

        if(this.state.isLoadMore) {
            this.setState({
                voucherOrderListing: this.state.voucherOrderListing.concat(nextProps.voucherOrderListing.data),
                voucherLoadMoreUrl: nextProps.voucherOrderListing.next_page_url,
                loadingData: false,
                isLoadMore: false
            })
        }
    }

    handleOpen = (order_id) => {
        this.setState({
            'order_id':order_id
        })
        this.setState({'is_dialog_open': true})
    };

    handleClose = () => {
        this.setState({'is_dialog_open': false})
    };

    LoadVoucherOrders (){

       this.props.getVoucherOrderList(this.state.voucherLoadMoreUrl);

        this.setState({
            isLoadMore:true
        })
    }

    render(){
        return(
            <React.Fragment>
                <div className="order-history mb-4 mx-4">
                    {
                        this.state.loadingData ?
                            <LoadingOrderHistory/>
                    :
                    !this.state.voucherOrderListing.length ?

                        <EmptyState stateType={"voucher_order_detail"}/>
                    :
                        <div className="">
                            <table className="bp3-html-table table-borderless">
                                <tbody>
                                {

                                    this.state.voucherOrderListing.map((list, index) => {
                                        return (
                                            <tr key={index}>
                                                <td>
                                                    <div className="border-rounded">
                                                        <img src={list.voucher_image} alt={list.voucher_name}/>
                                                    </div>
                                                </td>
                                                <td>
                                                    <div className="oh-bold"> {list.voucher_name}</div> <small>Order id: {list.id}</small>
                                                </td>
                                                <td>
                                                    Qty: {list.quantity}
                                                </td>
                                                <td>
                                                    {list.order_date}
                                                </td>
                                                <td>
                                                    Coins: <span className="oh-bold">{list.used_coins ? list.used_coins : 0}</span>
                                                </td>
                                                <td>
                                                    <div className="oh-bold">{ Helper.formatMoney(list.payable_total) }</div>
                                                </td>
                                                <td>
                                                    <div className="oh-bold">{list.cb_choice_title}</div>
                                                </td>
                                                <td>
                                                    <div style={{color:list.order_status_color}}>{list.order_status_text}</div>
                                                </td>
                                                <td className="brand-button">
                                                    {
                                                        list.order_status !== 0 ?
                                                            <DetailDialog
                                                                title={"Coin Vouchers Voucher Order Details"}
                                                                icon={"info-sign"}
                                                                className={"bp3-dialog bp3-dialog-large"}
                                                            >
                                                                <VoucherOrderDetailsContainer orderId={list.id} />
                                                            </DetailDialog>

                                                         :
                                                            <Button small={true} intent="inform" disabled={true} className="btn-view mr-2" text="View" />
                                                    }
                                                </td>
                                            </tr>
                                        )
                                    })
                                }
                                </tbody>
                            </table>
                            <div className="col-12 bs-list-more text-center mt-4">
                                {
                                    this.state.voucherLoadMoreUrl ?
                                        <Button small={true} intent="primary" text="Load More"
                                                className="btn-view mr-2"
                                                onClick={() => this.LoadVoucherOrders()}/>
                                    :
                                        <Button small={true} intent="inform" disabled="true" className="btn-view mr-2" text="That's it." />
                                }
                                <hr/>
                            </div>
                        </div>
                    }
                </div>
                <div className="clearfix"></div>
            </React.Fragment>
        );
    }
}
