import React from 'react';
import OrderHistory from "./OrderHistory";
import LoadingOrderHistory from "./LoadingOrderHistory";
import EmptyState from "../../core/components/EmptyState";
import {Alignment, Button, Intent} from "@blueprintjs/core";

export default class OrderHistoryPage extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            loadingData: true,
            order_history: [],
            hide_load_more: false,
            api_order_history_next_url: true,
            btn_loading: false
        }
    }

    componentWillMount() {
        this.props.getOrderHistory();
    }

    componentWillReceiveProps(nextProps) {
        if (this.state.api_order_history_next_url === false) {
            this.setState({order_history: nextProps.OrderHistoryData.data})
        } else {
            this.setState({order_history: this.state.order_history.concat(nextProps.OrderHistoryData.data)})
        }

        this.setState({
            loadingData: false,
            api_order_history_next_url: nextProps.OrderHistoryData.next_page_url,
            btn_loading: false
        });

        if (nextProps.OrderHistoryData.next_page_url === null) {
            this.setState({hide_load_more: true})
        }
    }

    handleOrderHistoryDataUpdate = () => {
        let url = this.state.api_order_history_next_url;

        this.setState({btn_loading: true});

        if (url === null) {
            this.setState({hide_load_more: true});
            return true;
        }

        this.setState({hide_load_more: false});
        this.props.getOrderHistory(url);
    };

    render() {
        return (
            <React.Fragment>
                <div className="order-history mb-4 mx-4">
                    {
                        this.state.loadingData ?
                            <LoadingOrderHistory/>
                            :
                            this.state.order_history.length === 0 ?
                                <EmptyState stateType={"order_history"}/>
                                :
                                <React.Fragment>
                                    <div className="table-responsive">
                                        <table className="bp3-html-table table-borderless">
                                            <tbody>
                                            {
                                                this.state.order_history.map((list, i) => {
                                                    return (
                                                        <OrderHistory order_history={list} key={i}/>
                                                    )
                                                })
                                            }
                                            </tbody>
                                        </table>
                                    </div>
                                </React.Fragment>
                    }
                </div>
                <div className="col-12 oh-list-more text-center mb-5">
                    {
                        this.state.hide_load_more ? '' :
                            <React.Fragment>
                                <Button
                                    minimal={true}
                                    className="bp3-small"
                                    loading={this.state.btn_loading}
                                    alignText={Alignment.CENTER}
                                    intent={Intent.PRIMARY}
                                    text={"Load More"}
                                    onClick={() => this.handleOrderHistoryDataUpdate()}
                                />
                            </React.Fragment>
                    }
                </div>
                <div className="clearfix"></div>
            </React.Fragment>
        );
    }
}
