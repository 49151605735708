import React from 'react';
import {AnchorButton, Button, Dialog, Intent,Toaster,Position} from "@blueprintjs/core";
import GGVoucherTrasactionModalPopUpData from "./GGVoucherTrasactionModalPopUpData";
import defaultBrand from "../../core/images/OrderHistory/Defaultbrands.png";
import * as Classes from "@blueprintjs/core/lib/esm/common/classes";
import {changeDateFormat} from "../../core/utils/Helper";
import {Helper} from "gg-react-utilities";

export default class OrderHistory extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            order_history: props.order_history,
            is_dialog_open: false
        }
    }

    /*componentWillReceiveProps(nextProps) {
        this.setState({
            'order_history': nextProps.order_history
        })
    }*/

    handleOpen = () => {
        this.setState({'is_dialog_open': true})
    };

    handleClose = () => {
        this.setState({'is_dialog_open': false})
    };

    dateFormat = (date, separator) => {
        return changeDateFormat(date, separator)
    };

    downloadInvoice(orderId){
        this.props.getGGVoucherInvoice(orderId);

    }
    render() {

        return (
            <React.Fragment>
                <tr>
                    <td>
                        <div className="border-rounded">
                            <img src={defaultBrand} alt={"default brand"}/>
                        </div>
                    </td>
                    <td>
                        <div className="oh-bold">
                            {this.state.order_history.id ? this.state.order_history.id : "--"}
                        </div>
                    </td>
                    <td>
                        <div className="oh-bold">
                            {this.state.order_history.initial_balance ? this.state.order_history.initial_balance : "--"}
                        </div>
                    </td>
                    <td>
                        <div className="oh-bold">
                            {this.state.order_history.current_balance ? this.state.order_history.current_balance : "--"}
                        </div>
                    </td>
                    <td>
                        <div className="oh-bold">
                            {this.state.order_history.valid_till ? changeDateFormat(this.state.order_history.valid_till,"-") : "--"}
                        </div>
                    </td>
                    <td>
                        <div className="oh-bold">
                            {this.state.order_history.created_at ? changeDateFormat(this.state.order_history.created_at,"-") : "--"}
                        </div>
                    </td>
                    <td className="brand-button">


                        {
                            this.state.order_history.redemption_txns ?
                                <Button
                                    small={true}
                                    intent="primary"
                                    text="View"
                                    className="btn-view"
                                    onClick={() => this.handleOpen()}
                                />
                                : ""
                        }
                    </td>
                </tr>
                <Dialog
                    onClose={this.handleClose}
                    title="Purchase Details"
                    isOpen={this.state.is_dialog_open}
                    lazy={true}
                    className="bp3-dialog-large"
                    canOutsideClickClose={false}
                >
                    <GGVoucherTrasactionModalPopUpData redemptionList={this.state.order_history.redemption_txns}/>

                    <div className={Classes.DIALOG_FOOTER}>
                        <div className={Classes.DIALOG_FOOTER_ACTIONS}>
                            <Button intent={Intent.DANGER} onClick={this.handleClose}>Close</Button>
                        </div>
                    </div>
                </Dialog>
            </React.Fragment>
        )
    }
}
