import React, {Component} from 'react';
import {Button, Intent, Toaster, Position, Alignment, RadioGroup, Radio, Dialog} from "@blueprintjs/core";
import Loading from "./loading";
import TopBar from "../../core/components/TopBar";
import FormComponent from "./FormComponent";
import _ from "lodash";
import {Auth, ssoInstance} from "gg-react-utilities";
import AddressDialog from "./AddressDialog";
import Config from "../../core/Config";
import EmptyState from "../../core/components/EmptyState";
import QRCode from "react-qr-code";
import moment from "moment";
import * as Classes from "@blueprintjs/core/lib/esm/common/classes";
import { Helper } from "gg-react-utilities";

export default class CheckoutComponent extends Component {
    constructor(props) {
        super(props);

        this.payuform = React.createRef();

        this.state = {
            loadingData: true,
            cart_lists: {},
            redeem_gg_coin: 0,
            grand_total: 0,
            is_add_new_address_open: false,
            provider: props.type ? props.type : "",
            platform: "web",
            billingName: "",
            billingEmail: "",
            billingAddressLine1: "",
            billingCity: "",
            billingState: "",
            billingCountry: "",
            billingZip: "",
            billingMobile: "",
            receiversName: "",
            receiversEmail: "",
            shippingAddressLine1: "",
            shippingCity: "",
            shippingState: "",
            shippingCountry: "",
            shippingZip: "",
            shippingMobile: "",
            form_validation: false,
            isDialogOpen: false,
            skeleton: true,
            dialogTitle: "Add New Address",
            singleAddressData: {},
            is_address_save: false,
            pg_data: {},
            createPayuForm: false,
            btn_loading: false,
            is_upi:"1",
            upiOpen: false,
            upiURL: '',
            time:"05",
            txnid:''
        };
    }

    

    componentWillMount() {      
        this.setState({loadingData: true});
        let obj = {
            provider : this.props.type ? this.props.type : "",
            is_upi: '1'
        }

        this.props.getCarts(obj);
    }
    

    componentWillUnmount() {
        this.props.unMount();
    }
   
    componentWillReceiveProps(nextProps) {
         this.setState({
            cart_lists: nextProps.CartListData,
            grand_total: nextProps.CartListData.grand_total,
            is_add_new_address_open: false
        }, () => {
            if (Auth.isLoggedIn()) {
                let address= localStorage.getItem('checkoutBillingAdd')
                let ShippingAddress=localStorage.getItem('checkoutShippingAdd')
                let billingCity=localStorage.getItem('billingCity')
                let billingState=localStorage.getItem('billingState')
                let billingPincode=localStorage.getItem('billingPincode')
                let shippingCity=localStorage.getItem('shippingCity')
                let shippingState=localStorage.getItem('shippingState')
                let shippingPincode=localStorage.getItem('shippingPincode')
            
                this.setState({
                    billingName: Auth.user().fname + " " + Auth.user().mname + " " + Auth.user().lname,
                    billingEmail: Auth.user().email,
                    billingMobile: Auth.user().mobile,
                    receiversName: Auth.user().fname + " " + Auth.user().mname + " " + Auth.user().lname,
                    receiversEmail: Auth.user().email,
                    shippingMobile: Auth.user().mobile,                    
                });
                if(address != "" && address != null){
                  
                    this.setState({
                    billingAddressLine1:address,
                    shippingAddressLine1:ShippingAddress,
                    billingCity: billingCity,
                    billingState: billingState,
                    billingCountry: "india",
                    billingZip: billingPincode,
                    shippingCity: shippingCity,
                    shippingState:shippingState,
                    shippingCountry: "india",
                    shippingZip: shippingPincode,
                    })
                }
            }
            if(this.state.cart_lists.length !== 0) {
                this.setState({
                    loadingData: false
                })
            }
        });
    }

    handleFormData = (newStateData) => {
        let address= localStorage.getItem('checkoutBillingAdd')
        let ShippingAddress=localStorage.getItem('checkoutShippingAdd')
        let billingCity=localStorage.getItem('billingCity')
        let billingState=localStorage.getItem('billingState')
        let billingPincode=localStorage.getItem('billingPincode')
        let shippingCity=localStorage.getItem('shippingCity')
        let shippingState=localStorage.getItem('shippingState')
        let shippingPincode=localStorage.getItem('shippingPincode')
       
       this.setState({
            billingName: newStateData.billingName===''?Auth.user().fname + " " + Auth.user().mname + " " + Auth.user().lname:newStateData.billingName,
            billingEmail: newStateData.billingEmail===''?Auth.user().email:newStateData.billingEmail,
            billingAddressLine1: newStateData.billingAddressLine1===''?address:newStateData.billingAddressLine1,
            billingCity: newStateData.billingCity===''?billingCity:newStateData.billingCity,
            billingState: newStateData.billingState===''?billingState:newStateData.billingState,
            billingCountry: newStateData.billingCountry===''?"india":newStateData.billingCountry,
            billingZip: newStateData.billingZip===""?billingPincode:newStateData.billingZip,
            billingMobile: newStateData.billingMobile===''?Auth.user().mobile:newStateData.billingMobile,
            receiversName: newStateData.receiversName===''? Auth.user().fname + " " + Auth.user().mname + " " + Auth.user().lname: newStateData.receiversName,
            receiversEmail: newStateData.receiversEmail===''?Auth.user().email:newStateData.receiversEmail,
            shippingAddressLine1: newStateData.shippingAddressLine1===''?ShippingAddress:newStateData.shippingAddressLine1,
            shippingCity: newStateData.shippingCity===''?shippingCity:newStateData.shippingCity,
            shippingState: newStateData.shippingState===''?shippingState:newStateData.shippingState,
            shippingCountry: newStateData.shippingCountry===''?"india":newStateData.shippingCountry,
            shippingZip: newStateData.shippingZip===""?shippingPincode:newStateData.shippingZip,
            shippingMobile: newStateData.shippingMobile===''?Auth.user().mobile:newStateData.shippingMobile,
        }, () => {
        });
    };

    handleProceedToCheckout = () => {
        
        let address= localStorage.getItem('checkoutBillingAdd')
        let ShippingAddress=localStorage.getItem('checkoutShippingAdd')
        let billingCity=localStorage.getItem('billingCity')
        let billingState=localStorage.getItem('billingState')
        let billingPincode=localStorage.getItem('billingPincode')
        let shippingCity=localStorage.getItem('shippingCity')
        let shippingState=localStorage.getItem('shippingState')
        let shippingPincode=localStorage.getItem('shippingPincode')
        let is_upi= this.state.is_upi
        if(this.state.billingAddressLine1 === '' || this.state.shippingAddressLine1===''){
           if(address != "" && address != null){
          
                this.setState({
                billingAddressLine1:address,
                shippingAddressLine1:ShippingAddress,
                billingCity: billingCity,
                billingState: billingState,
                billingCountry: "india",
                billingZip: billingPincode,
                shippingCity: shippingCity,
                shippingState:shippingState,
                shippingCountry: "india",
                shippingZip: shippingPincode,
                })
            }
        }
        this.setState({btn_loading: true});

        let isTrue = true;
        let getState = _.omit(this.state, [
            'loadingData',
            'cart_lists',
            'redeem_gg_coin',
            'grand_total',
            'is_add_new_address_open',
            'form_validation',
            'isDialogOpen',
            'skeleton',
            'dialogTitle',
            'singleAddressData',
            'is_address_save',
            'pg_data',
            'createPayuForm',
            'btn_loading',
            'time',
            'txnid',
            'upiOpen',
            'upiURL'
        ]);
        let isUndefined = Object.values(getState).indexOf(undefined) >= 0;
        let isNull = Object.values(getState).indexOf(null) >= 0;
        let isEmpty = Object.values(getState).indexOf("") >= 0;
        if (isUndefined || isNull || isEmpty) {
            this.setState({form_validation: true}, () => {
                return false;
            });
            isTrue = false;
        }
        if ((typeof this.state.billingEmail !== "undefined" && this.state.billingEmail !== null && this.state.billingEmail !== "") ||
            (typeof this.state.receiversEmail !== "undefined" && this.state.receiversEmail !== null && this.state.receiversEmail !== "")
        ) {
            let pattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);
            if (!pattern.test(this.state.billingEmail)) {
                this.setState({form_validation: true}, () => {
                    return false;
                });
                isTrue = false;
            }
        }

        if ((typeof this.state.billingMobile !== "undefined" && this.state.billingMobile !== null && this.state.billingMobile !== "") ||
            (typeof this.state.shippingMobile !== "undefined" && this.state.shippingMobile !== null && this.state.shippingMobile !== "")
        ) {
            if (!this.state.billingMobile.match(/^[0-9]{10}$/)) {
                this.setState({form_validation: true}, () => {
                    return false;
                });
                isTrue = false;
            }
        }

        if (isTrue === false) {
            this.setState({btn_loading: false});
        }

        if (isTrue === true) {
            this.payNowAction(getState);
        }
    };

    withoutPayment = (pgData) => {
        ssoInstance.post(Config.API_URL + "/after-payment", pgData)
            .then((res) => {
                let sources = res.data;
                if (sources.status === true) {
                    window.location.href = '/payment/success?orderid=' + sources.data.order_id;
                } else {
                    window.location.href = '/payment/failed?orderid=' + sources.data.order_id;
                }
            })
            .catch((errors) => {
                window.location.href = '/payment/failed?orderid=';
            });
    };

    payNowAction = (orderData) => {
        if (Auth.isLoggedIn()) {
            ssoInstance.post(Config.API_URL + "/before-payment", orderData)
                .then((res) => {
                    let sources = res.data;
                    if (sources.status === true) {
                        if (false === sources.data.need_payment) {
                            this.withoutPayment(sources.data);
                        } else {

                            if(sources.data.is_upi == "1"){
                                this.setState({
                                    upiOpen: true,
                                    upiURL: sources.data.upi_url,
                                    txnid: sources.data.txnid
                                },()=>{
                                    this.paymentTime()
                                })
                            }else {
                                this.setState({
                                    pg_data: sources.data,
                                    createPayuForm: true
                                });
                                window.document.payuform.submit()
                            }
                        }
                    } else {
                        this.setState({btn_loading: false});

                        Toaster.create({
                            position: Position.TOP,
                        }).show({message: sources.message, intent: Intent.DANGER});
                    }
                })
                .catch((errors) => {
                    if(errors.response.status === 422 && errors.response.data.message != ''){
                        Toaster.create({
                            position: Position.TOP,
                        }).show({message: errors.response.data.message, intent: Intent.DANGER});
                    }else if (errors.response.status !== 401 && errors.response.status !== 424) {
                        let message = "";
                        if (errors.response) {
                            message = errors.response.message ? errors.response.message : "Something went wrong!";
                        } else {
                            message = "Something went wrong!";
                        }
                        Toaster.create({
                            position: Position.TOP,
                        }).show({message: message, intent: Intent.DANGER});
                    } else {
                        this.setState({btn_loading: false});
                    }
                });
        }
    };

    handleOpen = () => {
        this.setState({upiOpen: true})
    };

    handleClose = () => {
        this.setState({upiOpen: false,btn_loading:false})
    };

    paymentTime = () => {
        let secTimer = setInterval(() => {

            let time = moment(this.state.time, 'mmss').subtract(1, 'seconds').format('mm:ss');
            this.setState({
                time
            })

            if(moment(time, 'mmss').format('ss') % 15 == 0){
                this.PgCheck();
            }

            if(this.state.upiOpen == false){
                clearInterval(secTimer);
            }

            if(time == '00:00'){
                clearInterval(secTimer);
                window.location.href = "/payment/awaiting?upi=true&message=We are processing your payment request please check back in sometimes!&title=Payment Awaiting!";
            }

        }, 1000)

    }

    PgCheck = () => {

        ssoInstance.get(Config.API_URL + "/upi-order-status-check/" + this.state.txnid)
            .then((res)=>{
                let source = res.data;
                if(source.status){
                    if(source.data.payment_status) {
                        window.location.href = "/payment/success?upi=true&message=" + source.message + "&title=Payment Success!";
                    }
                }
            })
            .catch((error)=>{
                this.setState({
                    btn_loading:false
                })
                if (error.response && 401 !== error.response.status) {
                    Toaster.create({position: Position.TOP}).show({
                        message: error.response.data.message,
                        intent: Intent.DANGER
                    });
                }
            })

    }
    renderPayUForm() {
        let pg_data = this.state.pg_data;
        return React.createElement('div', {className: "hide"},
            React.createElement('form', {
                    method: 'post',
                    action: pg_data.url,
                    ref: this.payuform,
                    id: "payuform",
                    name: "payuform"
                },
                React.createElement('input', {
                    type: 'hidden',
                    className: 'hide',
                    name: 'key',
                    defaultValue: pg_data.key
                }),
                React.createElement('input', {
                    type: 'hidden',
                    className: 'hide',
                    name: 'txnid',
                    defaultValue: pg_data.txnid
                }),
                React.createElement('input', {
                    type: 'hidden',
                    className: 'hide',
                    name: 'amount',
                    defaultValue: pg_data.amount
                }),
                React.createElement('input', {
                    type: 'hidden',
                    className: 'hide',
                    name: 'productinfo',
                    defaultValue: pg_data.productinfo
                }),
                React.createElement('input', {
                    type: 'hidden',
                    className: 'hide',
                    name: 'firstname',
                    defaultValue: pg_data.firstname
                }),
                React.createElement('input', {
                    type: 'hidden',
                    className: 'hide',
                    name: 'email',
                    defaultValue: pg_data.email
                }),
                React.createElement('input', {
                    type: 'hidden',
                    className: 'hide',
                    name: 'phone',
                    defaultValue: pg_data.phone
                }),
                React.createElement('input', {
                    type: 'hidden',
                    className: 'hide',
                    name: 'service_provider',
                    defaultValue: pg_data.service_provider
                }),
                React.createElement('input', {
                    type: 'hidden',
                    className: 'hide',
                    name: 'udf1',
                    defaultValue: pg_data.udf1
                }),
                React.createElement('input', {
                    type: 'hidden',
                    className: 'hide',
                    name: 'udf2',
                    defaultValue: pg_data.udf2
                }),
                React.createElement('input', {
                    type: 'hidden',
                    className: 'hide',
                    name: 'udf3',
                    defaultValue: pg_data.udf3
                }),
                React.createElement('input', {
                    type: 'hidden',
                    className: 'hide',
                    name: 'udf4',
                    defaultValue: pg_data.udf4
                }),
                React.createElement('input', {
                    type: 'hidden',
                    className: 'hide',
                    name: 'udf5',
                    defaultValue: pg_data.udf5
                }),
                React.createElement('input', {
                    type: 'hidden',
                    className: 'hide',
                    name: 'hash',
                    defaultValue: pg_data.hash
                }),
                React.createElement('input', {
                    type: 'hidden',
                    className: 'hide',
                    name: 'surl',
                    defaultValue: pg_data.surl
                }),
                React.createElement('input', {
                    type: 'hidden',
                    className: 'hide',
                    name: 'furl',
                    defaultValue: pg_data.furl
                }),
            )
        );
    }

    addNewAddress = () => {
        this.setState(
            {
                dialogTitle: "Add New Address",
            },
            () => this.onDialogOpen()
        );
    };

    onDialogOpen = () => {
        this.setState({isDialogOpen: true});
    };

    onDialogClose = () => {
        this.setState({
            isDialogOpen: false,
            singleAddressData: {
                street: null,
                area: null,
                landmark: null,
                city: null,
                state: null,
                pincode: null,
                is_default: null,
            },
        });
    };

    handleAddressSaved = (boolean) => {
        this.setState({
            is_address_save: boolean
        })
    };

    render() {
      
        let userCoinBalance = this.state.cart_lists.user_coin ? this.state.cart_lists.user_coin : 0;

        let breadcrumbs = [
            {
                to: "/cart",
                label: "Cart"
            },
            {
                to: "/checkout/" + this.state.provider + "/order-place",
                label: "Checkout"
            }
        ];

        return (
            <React.Fragment>
                <div className="container checkout">
                    <TopBar title="Checkout" links="cart" isGGCoin={userCoinBalance} breadcrumbs={breadcrumbs}/>
                    {
                        this.state.loadingData ? <Loading/> :
                            this.state.cart_lists.length === 0 ?
                                <EmptyState stateType={"checkout"}/>
                                :
                                <div className="row mb-5">
                                    <div className="col-xl-8 col-lg-8 col-md-8 col-sm-7 col-12">
                                        <div className="row mb-2">
                                            <div className="col-md-12 col-12">
                                                <div className="row no-gutters">
                                                    <div className="col-xl-8 col-lg-8 col-md-8 col-sm-6 col-12">
                                                        <h2 className="c-title">Checkout Details</h2>
                                                    </div>
                                                    <div className="col-xl-4 col-lg-4 col-md-4 col-sm-6 col-12">
                                                        <div className="row pull-right mt-2">
                                                            <Button
                                                                onClick={this.addNewAddress}
                                                                className={"pull-right text-primary new-address no-border"}
                                                                minimal={true}
                                                                icon="plus"
                                                                intent={Intent.PRIMARY}
                                                                text={"Add New Address"}/>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <AddressDialog
                                                {...this.props}
                                                openDialog={this.onDialogOpen}
                                                closeDialog={this.onDialogClose}
                                                dialogState={this.state.isDialogOpen}
                                                dialogTitle={this.state.dialogTitle}
                                                dialogData={this.state.singleAddressData}
                                                profile={this.props.profile}
                                                callbackAddressSave={this.handleAddressSaved}
                                            />
                                        </div>

                                        <FormComponent {...this.props} isAddressSave={this.state.is_address_save}
                                                       form_validation={this.state.form_validation}
                                                       callBackFormData={this.handleFormData}/>
                                    </div>
                                    <div className="col-xl-4 col-lg-4 col-md-4 col-sm-5 col-12">
                                        <div className='bg-basic mb-cart-total'>
                                            <div className="col-xl-12 mb-5">
                                                <h2 className="title">Payable Amount </h2>

                                                {
                                                    this.state.cart_lists.payment.map((data,key)=>{
                                                        if(data.key == 'grand_total' || data.key == 'payble_total') {
                                                            return (
                                                                <React.Fragment>
                                                                    {
                                                                        data.key == 'grand_total' ?
                                                                            <div className='border-bottom mt-2'/>
                                                                            : ''
                                                                    }
                                                                    <div className="row">
                                                                        <div
                                                                            className="col-xl-8 col-lg-8 col-md-7 col-sm-8 col-8">
                                                                            <h4 className="sub-text-bold"><b>{data.title}</b></h4>
                                                                        </div>
                                                                        <div
                                                                            className="col-xl-4 col-lg-4 col-md-5 col-sm-4 col-4 text-right text-nowrap">
                                                                            <h4 className="sub-text-bold"><b>{data.type +' '+ Helper.formatMoney(data.value)}</b></h4>
                                                                        </div>
                                                                    </div>
                                                                </React.Fragment>
                                                            )
                                                        }else{
                                                            return(
                                                                <div className="row">
                                                                    <div className="col-xl-8 col-lg-8 col-md-7 col-sm-8 col-8">
                                                                        <h4 className="sub-text">{data.title}</h4>
                                                                    </div>
                                                                    <div
                                                                        className="col-xl-4 col-lg-4 col-md-5 col-sm-4 col-4 text-right text-nowrap">
                                                                        {
                                                                            data.key == 'total_applied_coin' ?
                                                                                <h4 className="sub-text">{data.type +' '+ data.value}</h4>
                                                                            :
                                                                                <h4 className="sub-text">{data.type +' '+ Helper.formatMoney(data.value)}</h4>
                                                                        }
                                                                    </div>
                                                                </div>
                                                            )
                                                        }
                                                    })
                                                }

                                                <div className='border-bottom mt-2' />
                                                <h2 className="title">Payment Method</h2>
                                                <RadioGroup
                                                    className='d-flex mt_10 mb-2'
                                                    selectedValue={this.state.is_upi}
                                                    onChange={(e)=>{
                                                        this.setState({
                                                            is_upi:e.target.value,
                                                            loadingData: true
                                                        })

                                                        let obj = {
                                                            provider : this.state.provider,
                                                            is_upi: e.target.value
                                                        }

                                                        this.props.getCarts(obj);
                                                    }}>
                                                    <Radio label={'UPI (PG charges not applicable)'} value={"1"} />
                                                    <Radio label={'Debit / Credit / Net Banking / Wallet'} value={"0"} />
                                                </RadioGroup>

                                                <Button
                                                    loading={this.state.btn_loading}
                                                    alignText={Alignment.CENTER}
                                                    intent={Intent.PRIMARY}
                                                    className={"bp3-button bp3-intent-primary pull-right"}
                                                    text={"Proceed To Checkout"}
                                                    onClick={this.handleProceedToCheckout}
                                                />

                                                <Dialog
                                                    onClose={this.handleClose}
                                                    title="UPI Payment"
                                                    isOpen={this.state.upiOpen}
                                                    lazy={true}
                                                    className="bp3-dialog-large"
                                                    canOutsideClickClose={false}
                                                >
                                                    <div className='ggvoucher'>
                                                        <div bp="grid">
                                                            <div bp="12">
                                                                <center>
                                                                    <QRCode
                                                                        value={this.state.upiURL}
                                                                        title={this.state.upiURL}
                                                                        level="Q" />
                                                                </center>
                                                            </div>

                                                            <div bp="12">
                                                                <center>
                                                                    <h4 style={{color:"red"}}>Make Payment: {this.state.time}</h4>
                                                                </center>
                                                            </div>

                                                        </div>

                                                    </div>

                                                    <div className={Classes.DIALOG_FOOTER}>
                                                        <div className={Classes.DIALOG_FOOTER_ACTIONS}>
                                                            <Button intent={Intent.DANGER} onClick={this.handleClose}>Close</Button>
                                                        </div>
                                                    </div>
                                                </Dialog>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                    }
                </div>
                {
                    this.state.createPayuForm ?
                        this.renderPayUForm()
                        : ""
                }
            </React.Fragment>
        );
    }
}
