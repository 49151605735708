import React from 'react';
import { Alignment, Button, Intent, Position, Tab, Tabs, Toaster } from "@blueprintjs/core";
import CouponCardSecond from "../../../core/components/CouponCardSecond";
import Select from 'react-select';
import EmptyState from "../../../core/components/EmptyState";
import _ from "lodash";
import { Auth } from "gg-react-utilities";
import { withRouter } from 'react-router-dom';
import { getAllUrlParams } from "../../../core/utils/Helper";


class BrandDetails extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            loadingData: true,
            details: {},
            price: [],
            quantity: [],
            selected_price: 0,
            provider: null,
            selected_quantity: 0,
            btn_loading: false,
            range: false

        };
    }

    getURL = () => {
        let setURL = getAllUrlParams();

        if (setURL) {
            this.setState({
                selected_price: setURL.price ? setURL.price : 0,
                selected_quantity: setURL.quantity ? parseInt(setURL.quantity, 10) : 0
            })
        }
    };

    getBrandDetailApi = () => {
        this.props.getBrandDetails(this.props.slug)
            .then((res) => {
                let sources = res.data;
                if (sources.status === true) {
                    this.setState({
                        details: sources.data,
                        loadingData: false,
                    });

                    if (sources.data !== null) {
                        if (sources.data.brand_price) {

                            this.props.callBackTitle(sources.data.meta_title);
                            // this.props.callBackLabelTitle(sources.data.brand_name);
                            let priceList = sources.data.brand_price;

                            if (priceList.length !== 0) {
                                let price = this.createPrice(priceList.length, priceList);
                                this.setState({ price: price })
                            }
                        }

                        if (sources.data.provider) {
                            this.setState({ provider: sources.data.provider })
                        }
                    }

                    this.setState({ quantity: this.createQuantity(sources.data.max_quantity_allow) });
                }
            })
            .catch((errors) => {
                if (errors.response != undefined && errors.response.status !== 401) {
                    let message = "";
                    if (errors.response) {
                        message = errors.response.message ? errors.response.message : "Something went wrong!";
                    } else {
                        message = "Something went wrong!";
                    }
                    Toaster.create({
                        position: Position.TOP,
                    }).show({ message: message, intent: Intent.DANGER });
                }
            });
    };

    componentDidMount() {
        this.modalTarget = document.createElement('div');

        this.getURL();
        this.getBrandDetailApi();
    }

    componentWillReceiveProps(nextProps) {
        this.setState({
            details: nextProps.BrandDetailData
        }, () => {
            this.setState({
                loadingData: false
            })
        });


        if (nextProps.BrandDetailData !== null) {
            if (nextProps.BrandDetailData.brand_price) {
                let priceList = nextProps.BrandDetailData.brand_price;

                if (priceList.length !== 0) {
                    let price = this.createPrice(priceList.length, priceList);
                    this.setState({ price: price })
                }
            }

            if (nextProps.BrandDetailData.provider) {
                this.setState({ provider: nextProps.BrandDetailData.provider })
            }
        }

        this.setState({ quantity: this.createQuantity(nextProps.BrandDetailData.max_quantity_allow) });
    }

    createPrice = (count, priceData) => {
        let priceOptions = [];

        for (let i = 0; i < count; i++) {
            if (priceData[i]['valueType'] === "Fixed") {
                if (this.state.selected_price === priceData[i]['price']) {
                    this.setState({
                        selectedOptionPrice: {
                            value: priceData[i]['price'],
                            label: priceData[i]['price']
                        }
                    });
                }

                priceOptions.push({ value: priceData[i]['price'], label: priceData[i]['price'] });
            } else {
                if (priceData[i]['min_value'] !== null && priceData[i]['max_value'] !== null) {

                    /*for (let j = priceData[i]['min_value']; j <= priceData[i]['max_value']; j++) 
                    {

                        if (this.state.selected_price === j) {
                            this.setState({
                                selectedOptionPrice: {value: j, label: j}
                            });
                        }

                        priceOptions.push({value: j, label: j})
                    }*/

                    this.setState({
                        selectedOptionPrice: {
                            min_value: priceData[i]['min_value'],
                            max_value: priceData[i]['max_value']
                        },
                        range: true
                    });



                }
            }
        }

        return priceOptions
    };

    createQuantity = (maxCount) => {
        let quantityOptions = [];

        for (let i = 1; i <= maxCount; i++) {
            quantityOptions.push({ value: i, label: i });

            if (this.state.selected_quantity === i) {
                this.setState({ selectedOptionQuantity: { value: i, label: i } });
            }
        }
        return quantityOptions
    };

    handleChangePrice = (selectedOptionPrice) => {
        this.setState({ selectedOptionPrice, selected_price: selectedOptionPrice.value });
    };

    handleRangePrice = (selectedOptionPrice) => {

        this.setState({ selected_price: selectedOptionPrice.target.value });
    };

    handleChangeQuantity = (selectedOptionQuantity) => {
        this.setState({ selectedOptionQuantity, selected_quantity: selectedOptionQuantity.value });
    };

    handleAddToCart = () => {
        if (!Auth.isLoggedIn()) {
            this.handleLoginRefresh("add");
        }

        this.setState({ btn_loading: true });

        let checkValidate = this.state.details.check_validate;
        let price = this.state.selected_price;
        let quantity = this.state.selected_quantity;
        let slug = this.props.slug;
        let provider = this.state.provider;

        let selected_price = parseFloat(this.state.selected_price);
        let min_value = parseFloat(this.state.selectedOptionPrice.min_value);
        let max_value = parseFloat(this.state.selectedOptionPrice.max_value);


        if (this.state.selected_price === 0) {
            this.handleLoginRefresh("remove");

            Toaster.create({
                position: Position.Top,
            }).show({ message: "The price should be required", intent: Intent.DANGER });

            this.setState({ btn_loading: false });

            return false;
        }

        if (
            (selected_price < min_value) || (selected_price > max_value)
        ) {

            Toaster.create({
                position: Position.Top,
            }).show({ message: "You can enter only between minimum price " + this.state.selectedOptionPrice.min_value + " to maximum price " + this.state.selectedOptionPrice.max_value, intent: Intent.DANGER });

            this.setState({ btn_loading: false });

            return false;
        }



        //this.state.selectedOptionPrice.min_value

        if (this.state.selected_quantity === 0) {
            this.handleLoginRefresh("remove");
            Toaster.create({
                position: Position.Top,
            }).show({ message: "The quantity should be required", intent: Intent.DANGER });

            this.setState({ btn_loading: false });

            return false;
        }

        if (checkValidate === 1) {
            let cartAmount = price * quantity;
            let max_purchase_limit = this.state.details.max_purchase_limit;
            let remain_purchase = this.state.details.remain_purchase;

            if (cartAmount <= max_purchase_limit && cartAmount <= remain_purchase) {
                this.props.postAddToCart(price, quantity, slug, provider);

                this.setState({
                    btn_loading: false
                }, () => {
                    this.handleLoginRefresh("remove");
                });

            } else {
                Toaster.create({
                    position: Position.Top,
                }).show({ message: "you have exceeded your purchase limit.", intent: Intent.DANGER });

                return false;
            }

            this.setState({ btn_loading: false });
        } else {
            this.props.postAddToCart(price, quantity, slug, provider);

            if (Auth.isLoggedIn()) {
                this.setState({ btn_loading: false }, () => {
                    this.handleLoginRefresh("remove");
                });
            } else {
                this.setState({ btn_loading: false }, () => {
                    this.handleLoginRefresh("add");
                });
            }
        }
    };

    handleLoginRefresh = (type) => {
        if (type === "add") {
            /*let currentUrlParams = new URLSearchParams(window.location.search);
            currentUrlParams.set('price', this.state.selected_price);
            currentUrlParams.set('quantity', this.state.selected_quantity);
            this.props.history.push(window.location.pathname + "?" + currentUrlParams.toString());*/
        }

        if (type === "remove") {
            // this.props.history.push(window.location.pathname);
        }
    };

    render() {
        let { selectedOptionPrice } = this.state;
        let { selectedOptionQuantity } = this.state;


        let brand = {};
        let isAddToCart = 1;
        let isDescription = "";
        let isTerms = "";

        if (this.state.details) {
            brand = {
                brand_id: this.state.details.brand_id,
                brand_name: this.state.details.brand_name,
                slug: this.state.details.slug,
                is_favorite: this.state.details.is_favorite,
                image: this.state.details.image,
                tat: this.state.details.tat,
                coin_applicable: this.state.details.coin_applicable,
                product_type: this.state.details.product_type,
                color_code: this.state.details.color_code
            };

            isAddToCart = this.state.details.order_allowed;
            isDescription = this.state.details.description;
            isTerms = this.state.details.terms;
        }

        let counter = 1;

        let priceSymbole = "₹";
        if (this.state.loadingData === false && (_.isEmpty(this.state.details) === true)) {
            return (
                <React.Fragment>
                    <EmptyState stateType={"brand_detail"} />
                </React.Fragment>
            );
        } else {
            /*var meta = document.createElement('meta');
            var meta1 = document.createElement('meta');
            meta.name = "description";
            meta.content = this.state.details.meta_desc;
            meta1.name = 'keywords';
            meta1.content = this.state.details.meta_keyword;
            document.getElementsByTagName('head')[0].appendChild(meta);
            document.getElementsByTagName('head')[0].appendChild(meta1);*/

            let metaData = document.getElementsByTagName("meta");
            metaData['keywords'].content = this.state.details.meta_keyword ? this.state.details.meta_keyword : '';
            metaData['description'].content = this.state.details.meta_desc ? this.state.details.meta_desc : '';

            return (
                <React.Fragment>
                    {
                        this.state.loadingData ?
                            <React.Fragment>
                                <div className="col-12 mb-4">
                                    <div className="bp3-skeleton"
                                        style={{ width: "95%", height: "200px", margin: "0 auto" }}>&nbsp;</div>
                                </div>

                                <div className="col-12 mb-4">
                                    <div className="bp3-skeleton"
                                        style={{ width: "95%", height: "200px", margin: "0 auto" }}>&nbsp;</div>
                                </div>
                            </React.Fragment>
                            :
                            <div className="brand-details col-12 mb-5">
                                <div className="bd-box">
                                    <div className="bd-header bg-blue p-5 px-s-2">
                                        <div className="col-12 justify-content-center d-flex">
                                            <div className="bd-middle col-sm-9 col-md-7 col-lg-5 col-xl-5 px-s-0">
                                                <div className="sec-card mb-3">
                                                    <CouponCardSecond {...this.props} cardData={brand} cardNumber={counter}
                                                        cardStyleType={3} />
                                                </div>

                                                {/*<div class="bp3-form-content">
                                                <input 
                                                className={"bp3-input"} 
                                                type="text" 
                                                placeholder={"INR (" + priceSymbole + ")"}
                                                value=""/>
                                                
                                                </div>*/}


                                                <div className="sec-form">
                                                    <div className="mb-2">
                                                        {
                                                            this.state.range ?
                                                                <React.Fragment>

                                                                    <input
                                                                        className={"bp3-input"}
                                                                        type="text"
                                                                        placeholder={"Enter INR (" + priceSymbole + this.state.selectedOptionPrice.min_value + " TO " + priceSymbole + this.state.selectedOptionPrice.max_value + ")"}
                                                                        onChange={this.handleRangePrice}
                                                                        value={this.state.rangePrice}
                                                                    />

                                                                </React.Fragment>
                                                                :
                                                                <React.Fragment>
                                                                    <Select
                                                                        isSearchable={false}
                                                                        className={"bd-price-select"}
                                                                        value={selectedOptionPrice}
                                                                        onChange={this.handleChangePrice}
                                                                        options={this.state.price}
                                                                        placeholder={"INR (" + priceSymbole + ")"}
                                                                    />
                                                                </React.Fragment>
                                                        }
                                                    </div>
                                                    <div className="mb-2 mt-3">
                                                        <Select
                                                            isSearchable={false}
                                                            className={"bd-quantity-select"}
                                                            value={selectedOptionQuantity}
                                                            onChange={this.handleChangeQuantity}
                                                            options={this.state.quantity}
                                                            placeholder={"Quantity"}
                                                        />
                                                    </div>
                                                    <div className="text-right bd-add-to-cart mt-4">
                                                        <Button
                                                            loading={this.state.btn_loading}
                                                            alignText={Alignment.CENTER}
                                                            small={true}
                                                            text={isAddToCart ? "Add to Cart" : "Not allowed"}
                                                            className={"detail-cart-btn"}
                                                            disabled={!isAddToCart}
                                                            onClick={this.handleAddToCart}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="bd-body">
                                        <Tabs
                                            animate={true}
                                            id="TabsDetails"
                                            key="horizontal"
                                            renderActiveTabPanelOnly={true}
                                            vertical={false}
                                            className="bd-tabs"
                                        >
                                            <Tab className="bd-tab" id={"tab_description"} title={"Description"}
                                                panel={<div className="tab-content"
                                                    dangerouslySetInnerHTML={{ __html: isDescription }}></div>} />
                                            <Tab className="bd-tab" id={"tab_terms"} title={"Terms"}
                                                panel={<div className="tab-content"
                                                    dangerouslySetInnerHTML={{ __html: isTerms }}></div>} />
                                        </Tabs>
                                    </div>
                                </div>
                            </div>
                    }
                </React.Fragment>
            );
        }
    }
}

export default withRouter(BrandDetails);