import React from 'react';
import VoucherDetailsContainer from "./container/GGVoucherContainer";
import TopBar from "../core/components/TopBar";

export default class VoucherDetails extends React.Component {

    render() {
        
        let breadcrumbs = [
            {
                to: "/brand",
                label: "Brands"
            },
            {
                to: "/ggvoucher",
                label: "GG Vouchers"
            }
        ];
        

        return (
            <React.Fragment>
                {
                    <div className="container mg-brand-details">
                        <div className="row">
                            <TopBar title="GG Vouchers" links="" breadcrumbs={breadcrumbs}/>
                        </div>
                        <div className="row">
                            <VoucherDetailsContainer />
                        </div>
                    </div>
                }
            </React.Fragment>            
        )
    }


}
