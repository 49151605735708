import React from 'react';
import * as Classes from "@blueprintjs/core/lib/esm/common/classes";

const LoadingOrderHistory = () => (
    <div className={Classes.DIALOG_BODY + " popup-voucher"}>
        <div className="row">

            <div className="col-4 mb-4">
                <div className="bp3-skeleton" style={{width: "100%", height: "60px"}}>&nbsp;</div>
            </div>

            <div className="col-4 mb-4">
                <div className="bp3-skeleton" style={{width: "100%", height: "60px"}}>&nbsp;</div>
            </div>

            <div className="col-4 mb-4">
                <div className="bp3-skeleton" style={{width: "100%", height: "60px"}}>&nbsp;</div>
            </div>

            <div className="col-4 mb-4">
                <div className="bp3-skeleton" style={{width: "100%", height: "60px"}}>&nbsp;</div>
            </div>
            <div className="col-4 mb-4">
                <div className="bp3-skeleton" style={{width: "100%", height: "60px"}}>&nbsp;</div>
            </div>

            <div className="col-4 mb-4">
                <div className="bp3-skeleton" style={{width: "100%", height: "60px"}}>&nbsp;</div>
            </div>

            <div className="col-12 mb-4">
                <div className="bp3-skeleton" style={{width: "100%", height: "80px"}}>&nbsp;</div>
            </div>

            <div className="col-12 mb-4">
                <div className="bp3-skeleton" style={{width: "100%", height: "200px"}}>&nbsp;</div>
            </div>
        </div>
    </div>
);

export default LoadingOrderHistory;
