let initialState = {
    OrderHistoryList: [],
    GGOrderHistory: [],
    GGVoucherTransaction: [],
    GGVoucherTransactionDetails: [],
};

const OrderHistory = (state = initialState, action) => {
    switch (action.type) {
        case 'SET_ORDER_HISTORY':
            return {...state, OrderHistoryList: action.setOrderHistory};

        case 'SET_GG_ORDER_HISTORY':
            return {...state, GGOrderHistory: action.setGGOrderHistory};

        case 'SET_GG_VOUCHER_TRANSACTION':
            return {...state, GGVoucherTransaction: action.setGGVoucherTransaction};

        case 'SET_GG_VOUCHER_TRANSACTION_DETAILS':
            return {...state, GGVoucherTransactionDetails: action.setGGVoucherTransactionDetails};

        default:
            return state;
    }
};

export default OrderHistory;
