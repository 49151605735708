import React from 'react';

const LoadingSideBar = () => (
    <div className="row">
        <div className="col-12 mb-2">
            <div className="bp3-skeleton" style={{width: "100%", height: "500px"}}>&nbsp;</div>
        </div>
    </div>
);

export default LoadingSideBar;
