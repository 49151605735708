import React from 'react';
import TopBar from "../core/components/TopBar";
import OrderHistoryContainer from './containers/OrderHistoryContainer';
import GGVoucherTransaction from './containers/GGVoucherTransactionContainer';
import {Auth, NoLogin} from "gg-react-utilities";
import {RadioGroup, Radio} from "@blueprintjs/core";

export default class History extends React.Component {

    constructor(props){
        super(props);

        this.state={
            selectedOption:1
        };
        this.handleOption=this.handleOption.bind(this);
    }

    handleOption(e){
        this.setState({
            selectedOption:Number(e.target.value)
        });
    }

    render() {
        let breadcrumbs = [
            {
                to: "/gg-order-history",
                label: "History"
            },
        ];

        return (
            <React.Fragment>
                {
                    Auth.isLoggedIn() ?
                        <div className="container mg-brand-history">
                            <div className="row">
                                <TopBar title="Order History"
                                        tabOrderHistory={"ggVoucherOrderHistory"}
                                        breadcrumbs={breadcrumbs}/>
                            </div>

                            <div className="row">
                                <div className="megabrand">
                                    <div className="megabrandOption">
                                        <RadioGroup
                                            onChange={this.handleOption}
                                            name="type"
                                            className="d-inline option-type"
                                            selectedValue={this.state.selectedOption}>
                                            <Radio label="My GG Vouchers" value={1} />
                                            <Radio label="My GG Voucher Redeem" value={2} />
                                        </RadioGroup>
                                    </div>
                                    {
                                        this.state.selectedOption===1 ?
                                            <OrderHistoryContainer/>
                                            :
                                            this.state.selectedOption===2 ?
                                                <GGVoucherTransaction/>
                                                :
                                                ''
                                    }
                                </div>
                            </div>
                        </div>
                        :
                        <div className="container">
                            <div className="no-login">
                                <div className="no-login-middle">
                                    <NoLogin imageShow={true}/>
                                </div>
                            </div>
                        </div>
                }
            </React.Fragment>
        )
    }
}
