import React from 'react';
import Cart from './container/CartContainer';
import {Auth, NoLogin} from "gg-react-utilities";

export default class CartComponent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoggedIn: false,
        }
    }

    componentDidMount() {
        this.checkAuth();
    }

    async checkAuth() {
        setTimeout(() => {
            Auth.isLoggedInAsync().then((res) => {
                this.setState({
                    isLoggedIn: res,
                })
            });

            if (this.state.isLoggedIn === false) {
                this.checkAuth();
            }
        }, 1);
    }


    render() {
        if (this.state.isLoggedIn === false) {
            return (
                <div className="container">
                    <div className="no-login">
                        <div className="no-login-middle">
                            <NoLogin imageShow={true}/>
                        </div>
                    </div>
                </div>
            );
        }
        return (
            <React.Fragment>
            
                <Cart/>
            </React.Fragment>
        )
    }
}

