import React, { Component } from 'react';
import PrivateRoute from './PrivateRoute';
import { Switch } from 'react-router-dom';
import '../../node_modules/normalize.css/normalize.css';
import "@blueprintjs/core/lib/css/blueprint.css";
import "@blueprintjs/icons/lib/css/blueprint-icons.css";
import "gg-react-utilities/src/css/style.css";
import "gg-react-utilities/src/css/gg-styles.css";
import Home from '../home';
import Brands from '../brand';
import BrandDetailPage from '../brand/details/DetailPage';
import Cart from '../cart';
import Checkout from '../checkout';
import Payments from '../payment'
import History from '../history';
import ggHistory from '../ggHistory';
import Vouchers from '../vouchers';
import GGVouchers from '../GGVoucher';
import VoucherDetails from '../voucherDetails';
import VoucherOrderHistory from '../voucherOrderHistory';
import { Notfound, Maintance, GgUtils } from "gg-react-utilities";
import Config from "./Config";

export default class Root extends Component {
    componentDidMount = () => {
        if (process.env.REACT_APP_ENV === 'production') {
            GgUtils.googleAnalyticalScripts(Config.REACT_APP_GOOGLE_ANALYTICAL_CODE);
            GgUtils.sentryScripts(Config.REACT_APP_SENTRY_URL);
        }
    };

    render() {
        return (
            <React.Fragment>
                {
                    Config.IS_MAINTENANCE_MODE ?
                        <Switch>
                            <PrivateRoute path="*" component={Maintance} />
                        </Switch>
                        :
                        <Switch>
                            <PrivateRoute exact path="/" component={Brands} />
                            <PrivateRoute exact path="/brand" component={Brands} />
                            <PrivateRoute exact path="/brand/:slug" component={BrandDetailPage} />
                            <PrivateRoute exact path="/order-history" component={History} />
                            <PrivateRoute exact path="/gg-order-history" component={ggHistory} />
                            <PrivateRoute exact path="/cart" component={Cart} />
                            <PrivateRoute exact path="/checkout/:type/order-place" component={Checkout} />
                            <PrivateRoute exact path="/payment/:status" component={Payments} />
                            <PrivateRoute exact path="/vouchers" component={Vouchers} />
                            <PrivateRoute exact path="/voucher/:slug" component={VoucherDetails} />
                            <PrivateRoute exact path="/voucher-history" component={VoucherOrderHistory} />
                            <PrivateRoute exact path="/voucher/payment/:status" component={Payments} />
                            <PrivateRoute exact path="/ggvoucher" component={GGVouchers} />
                            <PrivateRoute exact path='/gg-vouchers/payment/:status' component={Payments} />
                            <PrivateRoute path="*" component={Notfound} />
                        </Switch>
                }
            </React.Fragment>
        );
    }
}
