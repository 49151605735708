import React from 'react';
import VoucherDetailsContainer from "./container/VoucherDetailsContainer";

export default class VoucherDetails extends React.Component {

    componentDidMount() {
        window.scrollTo(0, 0)
    }

    render(){
        return(
            <div className="container mg-brand-details">
                <div className="row">
                    <VoucherDetailsContainer
                        voucher_type_slug={this.props.match.params.slug} />
                </div>
            </div>
        );
    }
}
