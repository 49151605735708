import React from 'react';
import CoinsImage from "../../core/images/gg_coins.svg";
import { Helper } from 'gg-react-utilities';

export default class VoucherCard extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            VoucherDetail: props.VoucherDetail
        };
    }

    componentWillReceiveProps(nextProps) {
        this.setState({
            VoucherDetail: nextProps.VoucherDetail
        });
    }

    render() {
        let colorCode = this.state.VoucherDetail.color_code.split(',');
        return (
            <React.Fragment>
                {
                    <div className="voucher-card">
                        <div className="brand-card d-flex justify-content-between item-align-center" style={{backgroundImage: 'linear-gradient(135deg, ' + colorCode[0] + ' 0%, ' + colorCode[1] + ' 100%)'}}>
                            <p className="brand-name">{this.state.VoucherDetail.voucher_name}</p>
                            {
                                this.state.VoucherDetail.coins_redeem != 0 ?
                                    <img src={CoinsImage} className="coin-img pt-1 pr-1"/>
                                :''
                            }
                        </div>
                        <div className="text-center">
                            <img src={this.state.VoucherDetail.voucher_image} className="img-fluid brand-img" />
                        </div>
                        <div className="d-flex justify-content-between item-align-center pl-3 pr-3 pt-3 pb-2">
                            {/*<p className="brand-delivery text-center m-auto">GG Coin Applicable</p>*/}
                            <p className="brand-coin text-center m-auto">
                                {
                                    this.state.VoucherDetail.discount > 0 ?
                                        <span>₹ {this.state.VoucherDetail.amount - this.state.VoucherDetail.discount} &nbsp;
                                            <sub><strike
                                                className="strike-price">₹ {this.state.VoucherDetail.amount} </strike></sub></span>
                                        :
                                        <span>{Helper.formatMoney(this.state.VoucherDetail.amount)}</span>
                                }
                                </p>
                        </div>
                    </div>
                }
            </React.Fragment>
        );
    }
}
