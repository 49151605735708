import React, {Component} from 'react';
import TopBar from "../../core/components/TopBar";
import CartFilterComponent from "./CartFilterComponent";
import {Intent, Position, Tag, Toaster} from "@blueprintjs/core";
import {GgUtils, ssoInstance} from "gg-react-utilities";
import Config from "../../core/Config";

export default class CartComponent extends Component {
    constructor(props) {
        super(props);

        this.state = {
            loadingData: true,
            cart_lists: {},
            cart_counts: {},
            provider: "0",
            freshGgCoin: 0
        };
    }

    componentWillMount() {
        this.props.getCarts(this.state.provider);
      
        this.setGGCoinIfNotFound();

    }

    componentWillUnmount() {
        this.props.unMountCart();
    }

    componentWillReceiveProps(nextProps) {
        this.setState({
            cart_lists: nextProps.CartListData,
            cart_counts: nextProps.CartCountData,
        }, () => {
                this.setState({loadingData: false});
        });
    }

    setGGCoinIfNotFound = () => {
        ssoInstance.get(Config.API_URL + "/user-coin-balance")
            .then((res) => {
                let sources = res.data;
                if (sources.status === true)
                    this.setState({freshGgCoin: sources.data.balance})
            })
            .catch((errors) => {});
    };

    changeProvider = (provider) => {
        this.setState({provider: provider, loadingData: true}, () => {
            this.props.getCarts(provider);
        });
    };

    UpdateCart = (slug, price, quantity, applied_coin) => {
        this.setState({
            loadingData: true
        });

        this.props.postUpdateCart(slug, price, quantity, applied_coin)
            .then((res) => {
                let sources = res.data;
                let message = res.data.message;
                let alertColor = Intent.DANGER;
                if (sources.status === true) {
                    message = "Your cart updated successfully";
                    alertColor = Intent.SUCCESS;

                    this.props.getCarts(this.state.provider);
                }
                Toaster.create({
                    position: Position.TOP,
                }).show({message: message, intent: alertColor})

            })
            .catch((errors) => {
                if (errors.response.status !== 401) {
                    let message = "";
                    if (errors.response) {
                        message = errors.response.message ? errors.response.message : "Something went wrong!";
                    } else {
                        message = "Something went wrong!";
                    }
                    Toaster.create({
                        position: Position.TOP,
                    }).show({message: message, intent: Intent.DANGER});

                    this.setState({
                        loadingData: false
                    });
                }
            });
    };

    RemoveCart = (slug, price) => {
        this.setState({
            loadingData: true
        });

        this.props.postRemoveCart(slug, price)
            .then((res) => {
                let sources = res.data;
                let message = res.data.message;
                let alertColor = Intent.DANGER;
                if (sources.status === true) {
                    message = "Brand successfully removed from your cart";
                    alertColor = Intent.SUCCESS;

                    GgUtils.cartRefresh();

                    this.props.getCarts(this.state.provider);
                }
                Toaster.create({
                    position: Position.TOP,
                }).show({message: message, intent: alertColor})

            })
            .catch((errors) => {
                if (errors.response.status !== 401) {
                    let message = "";
                    if (errors.response) {
                        message = errors.response.message ? errors.response.message : "Something went wrong!";
                    } else {
                        message = "Something went wrong!";
                    }
                    Toaster.create({
                        position: Position.TOP,
                    }).show({message: message, intent: Intent.DANGER});

                    this.setState({
                        loadingData: false
                    });
                }
            });
    };

    render() {
        let userCoinBalance = this.state.cart_lists.user_coin ?
            this.state.cart_lists.user_coin :
            this.state.freshGgCoin ? this.state.freshGgCoin : 0;

        let megaBrandCount = 0;
        let GGConnectCount = 0;
        if (this.state.cart_counts) {
            megaBrandCount = this.state.cart_counts.total_mb_count;
            GGConnectCount = this.state.cart_counts.total_gg_connect_count;
        }

        let breadcrumbs = [
            {
                to: "/cart",
                label: "Cart"
            }
        ];

        return (
            <React.Fragment>
                {
                    <div className="container">
                        <TopBar title="Cart-Mega Brands" links="cart" isGGCoin={userCoinBalance} breadcrumbs={breadcrumbs}/>

                        <div className="row cart-tab mb-4">
                            <div className="col-12 px-s-0">
                                <div className="bp3-tabs">
                                    <ul className="bp3-tab-list bp3-large">
                                        <li
                                            className="bp3-tab"
                                            role="tab"
                                            onClick={() => {
                                                this.changeProvider("0")
                                            }}
                                            aria-selected={this.state.provider === "0" ? true : false}>
                                            Mega Brands
                                            <Tag className={"mb-cart-tt-count"} intent={Intent.DANGER}>
                                                {megaBrandCount}
                                            </Tag>
                                        </li>
                                        <li
                                            className="bp3-tab"
                                            role="tab"
                                            onClick={() => {
                                                this.changeProvider("1")
                                            }}
                                            aria-selected={this.state.provider === "1" ? true : false}>
                                            Coin Vouchers
                                            <Tag className={"mb-cart-tt-count"} intent={Intent.DANGER}>
                                                {GGConnectCount}
                                            </Tag>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>

                        {
                            <CartFilterComponent {...this.props}
                                                 provider={this.state.provider}
                                                 cart_lists={this.state.cart_lists}
                                                 loadingData={this.state.loadingData}
                                                 callBackUpdateCart={this.UpdateCart}
                                                 callBackRemoveCart={this.RemoveCart}
                            />
                        }
                    </div>
                }
            </React.Fragment>
        );
    }
}
