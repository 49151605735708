import React from 'react';
import LoadingSideBar from "./LoadingSideBar";
import {Checkbox, Label} from "@blueprintjs/core";

export default class SideBar extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            loading: props.loading,
            sidebar: props.sidebar,
            isShow: false
        };
    }

    toggleShow = () => {
        this.setState({isShow: !this.state.isShow});
    };

    componentDidMount() {
        this.setState({
            loading: this.state.loading,
            sidebar: this.state.sidebar
        })
    }

    componentWillReceiveProps(nextProps) {
        this.setState({
            loading: nextProps.loading,
            sidebar: nextProps.sidebar
        })
    }

    handleChkRef = (event, type) => {
        let value = event.target.value;
        let isChecked = event.target.checked;

        this.props.onSideBarFilter(isChecked, type, value)
    };

    render() {
        return (
            <React.Fragment>
                <div className="brand-sidebar">
                    {
                        this.state.loading ?
                            <div>
                                <LoadingSideBar/>
                            </div>
                            :
                            <React.Fragment>
                                <article>
                                    <div className={"ar-header"}>
                                        <Label className="sb-title">Select Category</Label>
                                    </div>
                                    <div className={this.state.isShow ? "ar-body" : "ar-body fix-overflow"}>
                                        {
                                            this.state.sidebar.length ?
                                                this.state.sidebar.map((list, key) => {
                                                    return (
                                                        <Checkbox value={list.category}
                                                                  onChange={(e) => this.handleChkRef(e, "category")}
                                                                  label={list.category + "(" + list.count + ")"}
                                                                  className="sb-chk-text"
                                                                  key={key}/>
                                                    )
                                                })
                                                : ""
                                        }
                                    </div>
                                    <div className="ar-footer">
                                        <Label
                                            className="sb-more"
                                            onClick={this.toggleShow}>
                                            {this.state.isShow ? "Collapse" : "View All"}</Label>
                                    </div>
                                </article>
                                <article>
                                    <div className="ar-header">
                                        <Label className="sb-title">By GG-Coin</Label>
                                    </div>
                                    <div className={"ar-body"}>
                                        <Checkbox value={"1"}
                                                  onChange={(e) => this.handleChkRef(e, "coin")}
                                                  label={"GG-Coin Applicable"}
                                                  className="sb-chk-text"/>
                                    </div>
                                </article>
                                <article>
                                    <div className="ar-header">
                                        <Label className="sb-title">Voucher Type</Label>
                                    </div>
                                    <div className={"ar-body"}>
                                        <Checkbox value={"Physical"}
                                                  onChange={(e) => this.handleChkRef(e, "voucher")}
                                                  label={"Physical Voucher"}
                                                  className="sb-chk-text"/>
                                        <Checkbox value={"Digital"}
                                                  onChange={(e) => this.handleChkRef(e, "voucher")}
                                                  label={"Digital Voucher"}
                                                  className="sb-chk-text"/>
                                    </div>
                                </article>
                                <article>
                                    <div className="ar-header">
                                        <Label className="sb-title">Delivery</Label>
                                    </div>
                                    <div className={"ar-body"}>
                                        <Checkbox value={"Instant"}
                                                  onChange={(e) => this.handleChkRef(e, "delivery")}
                                                  label={"Instant Delivery"}
                                                  className="sb-chk-text"/>
                                        <Checkbox value={"Normal"}
                                                  onChange={(e) => this.handleChkRef(e, "delivery")}
                                                  label={"Normal Delivery"}
                                                  className="sb-chk-text"/>
                                    </div>
                                </article>
                            </React.Fragment>
                    }
                </div>
            </React.Fragment>
        );
    }
}
