import React from 'react';
import LoadingImageList from "./LoadingImageList";
import {Link} from "react-router-dom";

export default class ImageList extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: props.loading,
            images: props.images ? props.images : []
        };
    }

    componentWillReceiveProps(nextProps) {
        this.setState(
            {
                loading: nextProps.loading,
                images: nextProps.images ? nextProps.images : []
            }
        );
    }

    render() {
        return (
            this.state.loading ?
                <LoadingImageList/>
                :
                this.state.images.length ?
                    <div className="row images">
                        {
                            this.state.images.map((image, key) => {
                                if (0 === key) {
                                    return (
                                        <div className="col-lg-6 mb-4 mb-s-2" key={key}>
                                            <Link to={"/brand/" + image.slug}>
                                                <img src={image.image} alt={image.title}/>
                                            </Link>
                                        </div>
                                    )
                                } else if (this.state.images.length === key + 1) {
                                    return (
                                        <div className="col-lg-6" key={key}>
                                            <Link to={"/brand/" + image.slug}>
                                                <img className="img-border" src={image.image} alt={image.title}/>
                                            </Link>
                                        </div>
                                    )
                                } else {
                                    return (
                                        <div className="col-lg-6 col-6 mb-4 pro-image mb-s-2" key={key}>
                                            <Link to={"/brand/" + image.slug}>
                                                <img src={image.image} alt={image.title}/>
                                            </Link>
                                        </div>
                                    );
                                }
                            })
                        }
                    </div>
                    : ""
        );
    }
}


