import React, {Component} from 'react';

export default class LoadingComponent extends Component {
    render() {
        return (
            <div className="row no-gutters">
                <div className="col-md-8 col-xl-8 col-lg-8 col-12">
                    <div className="col-md-12 col-xl-12 col-lg-12 col-sm-12 col-12 mb-4">
                        <div className="bp3-skeleton" style={{width: "100%", height: "200px"}}>&nbsp;</div>
                    </div>
                    <div className="col-md-12 col-xl-12 col-lg-12 col-sm-12 col-12 mb-4">
                        <div className="bp3-skeleton" style={{width: "100%", height: "200px"}}>&nbsp;</div>
                    </div>
                </div>
                <div className="col-md-4 col-xl-4 col-lg-4 col-sm-6 col-12 mb-5">
                    <div className="bp3-skeleton" style={{width: "100%", height: "420px"}}>&nbsp;</div>
                </div>
            </div>
        );
    }
}
