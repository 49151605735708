let initialState = {
    CartList: [],
    CartCount: {}
};

const Carts = (state = initialState, action) => {
    switch (action.type) {
        case 'SET_CARTS':
            return {...state, CartList: action.setCarts};

        case 'SET_CART_COUNTS':
            return {...state, CartCount: action.setCartCounts};

        default:
            return state;
    }
};

export default Carts;
