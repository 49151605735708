let initialState = {
    categoryList: []
};

const Categories = (state = initialState, action) => {
    switch (action.type) {
        case 'SET_CATEGORIES':
            return {...state, categoryList: action.setCategories};

        default:
            return state;
    }
};

export default Categories;
