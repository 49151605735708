import React from 'react';
import TopBar from "../core/components/TopBar";
import OrderHistoryContainer from './containers/OrderHistoryContainer';
import OrderHistoryContainer1 from './containers/OrderHistoryContainer1';
import OrderHistoryContainer2 from './containers/OrderHistoryContainer2';
import {Auth, NoLogin} from "gg-react-utilities";
import {RadioGroup, Radio} from "@blueprintjs/core";

export default class History extends React.Component {
    
    constructor(props){ //console.log("props",props);
        super(props);
        //alert('1');
        this.state={
            selectedOption:0
        };//console.log('this',this);
        this.handleOption=this.handleOption.bind(this);
    }


    handleOption(e){ 
        this.setState({
            selectedOption:Number(e.target.value)
        });
    }


    render() {

        let breadcrumbs = [
            {
                to: "/order-history",
                label: "History"
            },
        ];

        return (
            <React.Fragment>
                { 
                    Auth.isLoggedIn() ?
                        <div className="container mg-brand-history">
                            <div className="row">
                                <TopBar title="Order History"
                                        tabOrderHistory={"megaBrandsOrderHistory"}
                                        breadcrumbs={breadcrumbs}/>
                            </div>

                            <div className="row">
                                <div className="row">
                                <div className="megabrand">
                                    <div className="megabrandOption">
                                        <RadioGroup
                                            onChange={this.handleOption}
                                            name="type"
                                            className="d-inline option-type"
                                            selectedValue={this.state.selectedOption}>
                                            <Radio label="All Orders" value={0} />
                                            <Radio label="Used Voucher Orders" value={1} />
                                            <Radio label="Unused Voucher Orders" value={2} />
                                        </RadioGroup>
                                    </div>
                                   
                                </div>
                            </div>
                            </div>

                            <div className="row">


                                {
                                    this.state.selectedOption===0 ?
                                        <OrderHistoryContainer/>
                                        :
                                        this.state.selectedOption===1 ?
                                        <OrderHistoryContainer1/>
                                        :
                                        this.state.selectedOption===2 ?
                                        <OrderHistoryContainer2/>
                                        :
                                        ''
                                }

                                {/*<OrderHistoryContainer/>*/}
                            

                            </div>
                        </div>
                        :
                        <div className="container">
                            <div className="no-login">
                                <div className="no-login-middle">
                                    <NoLogin imageShow={true}/>
                                </div>
                            </div>
                        </div>
                }
            </React.Fragment>
        )
    }
}
