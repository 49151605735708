import React from 'react';
import * as Classes from "@blueprintjs/core/lib/esm/common/classes";
import {changeDateFormat} from "../../core/utils/Helper";
import EmptyState from "../../core/components/EmptyState";

export default class ModalPopUpData extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            loadingData: true,
            redemptionList: props.redemptionList,
            voucher_view: []
        }
    }

    render() {
        return (
            <React.Fragment>
                <div className={Classes.DIALOG_BODY + " popup-gg-voucher"} style={{height: "100%",maxHeight: "590px",overflowY: "scroll"}}>
                    {
                        this.state.redemptionList.length === 0 ?
                            <EmptyState stateType={"voucher_detail"}/>
                        :
                        <div className="table-responsive">
                            <table className="bp3-html-table table-borderless">
                                <thead>
                                <tr>
                                    <td><b>Purchase No</b></td>
                                    <td><b>Domain</b></td>
                                    <td><b>Type</b></td>
                                    <td><b>Amount</b></td>
                                    <td><b>Purchase Date</b></td>
                                </tr>
                                </thead>
                                <tbody>
                                {
                                    this.state.redemptionList.map((list, key) => {
                                        return (
                                            <React.Fragment key={key}>
                                                <tr>
                                                    <td>
                                                        <div className="oh-bold">
                                                            {key + 1}
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div className="oh-bold">
                                                            {list.redemption_master.domain ? list.redemption_master.domain : "--"}
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div className="oh-bold">
                                                            {
                                                                list.txn_type === 0 ?
                                                                    <span>Refund</span>
                                                                :
                                                                    <span>Purchase</span>
                                                            }
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div className="oh-bold">
                                                            {list.amount ? list.amount : "--"}
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div className="oh-bold">
                                                            {list.redemption_master.created_at ? changeDateFormat(list.redemption_master.created_at, "-") : "--"}
                                                        </div>
                                                    </td>
                                                </tr>
                                            </React.Fragment>
                                        )
                                    })
                                }
                                </tbody>
                            </table>
                        </div>
                    }
                </div>
            </React.Fragment>
        );
    }
}
