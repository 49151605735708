import React from 'react';
import TopBar from "../core/components/TopBar";
import BrandContainer from "./containers/BrandContainer";

export default class Brand extends React.Component {
    render() {
        let breadcrumbs = [
            {
                to: "/brand",
                label: "Brands"
            }
        ];

        return (
            <div className="container mg-brand-list">
                <div className="row">
                    <TopBar 
                        title="Brands" 
                        description="Buy your favorite brand from Global Garner & get cashback voucher on your favorite brands at Global Garner, enjoy shopping with Global Garner"
                        keywords="Myntra , Myntra Online Shopping, Pantaloons, Fast track, Makemytrip, big basket, bookmyshow, Big Bazzar, Flipkart,flipkart Sale, flipkart online shopping flipkartoffers, Mega Brand, Cashback voucher, gift vouchers, discount gift cards, online cashback vouchers, deals"
                        links="brand" breadcrumbs={breadcrumbs} isvideoDialog={true}
                        />
                </div>

                <div className="row">
                    <BrandContainer/>
                </div>
            </div>
        )
    }
}


