import React from 'react';
import {Link} from "react-router-dom";
import CouponCardSecond from "../../core/components/CouponCardSecond";
import EmptyState from "../../core/components/EmptyState";
import GGImage from "../../core/images/gg.png";

export default class Lists extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            brand_data: props.brands
        };
    }

    componentWillReceiveProps(nextProps) {
        this.setState({
            brand_data: nextProps.brands
        });
    }

    render() {
        let counter = 0;
        return (
            <React.Fragment>
                <aside className="col-xl-4 col-lg-4 col-md-6 col-sm-6 col-12 mt-4 mb-4 mt-s-0 brand-col">
                    <Link to={'/ggvoucher'}>
                        <React.Fragment>
                            <div className={"mega-brand-card"}>
                                <img
                                    src={GGImage}
                                    alt="no image found"
                                    onError={false}
                                />
                            </div>
                        </React.Fragment>
                    </Link>
                </aside>
                {
                    this.state.brand_data.length ?
                        this.state.brand_data.map((brand, key) => {
                            if (brand) {
                                counter++;
                                if (counter > 6) counter = 1;
                                return (
                                    <aside className="col-xl-4 col-lg-4 col-md-6 col-sm-6 col-12 mb-4 mt-4 mt-s-0 brand-col"
                                           key={key}>
                                        <Link to={'/brand/' + brand.slug}>
                                            <CouponCardSecond {...this.props} cardData={brand} cardNumber={counter}
                                                              cardStyleType={1}/>
                                        </Link>
                                    </aside>
                                )
                            } else {
                                return '';
                            }
                        })
                        : <EmptyState stateType={"brand"}/>
                }
            </React.Fragment>
        );
    }
}
