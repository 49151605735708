import {connect} from "react-redux";
import {GgUtils, ssoInstance} from "gg-react-utilities";
import Config from "../../../core/Config";
import BrandDetails from "../components/BrandDetails";
import {Toaster, Position, Intent} from "@blueprintjs/core";

const mapStateToProps = state => {
    return {
        BrandDetailData: state.Brands.BrandDetailList,
    }
};

const mapDispatchToProps = () => {
    return {
        getBrandDetails: (slug) => {
            return ssoInstance.get(Config.API_URL + "/brand/" + slug);
        },

        postAddToCart: (price, quantity, slug, provider) => {
            let filterData = {
                price: price,
                quantity: quantity,
                slug: slug,
                provider: provider
            };

            return ssoInstance.post(Config.API_URL + "/add-to-cart", filterData)
                .then((res) => {
                    let sources = res.data;
                    let message = res.data.message;
                    let alertColor = Intent.DANGER;
                    let action = {};

                    if (sources.status === true) {
                        message = "Brand added to cart successfully";
                        alertColor = Intent.SUCCESS;
                        action = {
                            href: "/cart",
                            text: "View Cart"
                        };

                        GgUtils.cartRefresh();
                    }

                    Toaster.create({
                        position: Position.TOP,
                    }).show({message: message, intent: alertColor, action: action});

                })
                .catch((errors) => {
                    if (errors.response.status !== 401) {
                        let message = "";
                        if (errors.response) {
                            message = errors.response.message ? errors.response.message : "Something went wrong!";
                        } else {
                            message = "Something went wrong!";
                        }
                        Toaster.create({
                            position: Position.TOP,
                        }).show({message: message, intent: Intent.DANGER});
                    }
                })
        },

        putBrands: (brand_id) => {
            return ssoInstance.put(Config.API_URL + "/favBrand/" + brand_id);
        }
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(BrandDetails);
