import { connect } from "react-redux";
import {Auth, ssoInstance} from "gg-react-utilities";
import Config from "../../core/Config";
import * as actions from "../../core/actions";
import VoucherDetailsComponent from '../component/VoucherDetailsComponent'
import {Intent, Position, Toaster} from "@blueprintjs/core";

const mapStateToProps = state => {

    return {
        voucherDetails: state.vouchers.voucherDetails
    }
};

const mapDispatchToProps = dispatch => {
    return {
        getVoucherDetails : (voucher_type_slug) => {
            let url = '';
            if(Auth.isLoggedIn()){
                url ="/v3/user/get-voucher-detail/"+voucher_type_slug;
            }else{
                url ="/v3/user/get-voucher-detail-react/"+voucher_type_slug;
            }

            return ssoInstance.get( Config.VOUCHER_APP_URL + url)
                .then( (res) => {
                    const sources = res.data;

                    if( sources.status === true )
                        dispatch( actions.setVoucherDetails( sources.data ) );
                    else
                        dispatch( actions.setVoucherDetails([]) );
                } )
                .catch( (error) => {
                    if(error){

                        const sources = error.response.data;

                        if(sources.errors){
                            Object.keys(sources.errors).forEach((key)=>{

                                error.response.data.errors[key].forEach((error)=>{

                                    Toaster.create({
                                        position: Position.TOP,
                                    }).show({message: error, intent:Intent.DANGER});

                                });

                            });
                            return new Promise((resolve,reject)=>{
                                reject(error)
                            });
                        }else{
                            if(sources.status===false){

                                Toaster.create({
                                    position: Position.TOP,
                                }).show({message: sources.message, intent:Intent.DANGER});

                            }else{

                                Toaster.create({
                                    position: Position.TOP,
                                }).show({message: sources.message, intent:Intent.DANGER});
                            }
                            return new Promise((resolve,reject)=>{
                                resolve(sources)
                            });
                        }
                        // dispatch(actions.paymentCatchResponse(sources));
                    }
                } )
        },
        orderPlace : (voucherDetail) => {

            return ssoInstance.post( Config.VOUCHER_APP_URL + "/v3/user/voucher/order-place",voucherDetail)
                .then( (response) => {

                    const sources = response.data;

                    if(sources.status===false){
                        Toaster.create({
                            position: Position.TOP,
                        }).show({message: sources.message, intent:Intent.DANGER});

                    }else{

                        if(sources.data.amount > 0) {
                            const paymentSignature = sources.data.pg;

                            let form = document.createElement("form");
                            form.setAttribute("method", 'POST');
                            form.setAttribute("action", paymentSignature.url);

                            for (let key in paymentSignature) {

                                let hiddenField = document.createElement("input");
                                hiddenField.setAttribute("type", "hidden");
                                hiddenField.setAttribute("name", key);
                                hiddenField.setAttribute("value", paymentSignature[key]);

                                form.appendChild(hiddenField);

                            }
                            document.body.appendChild(form);
                            form.submit();

                        }else{

                            let orderConfirmData = {
                                "voucher_order_id":sources.data.voucher_order_id,
                                "domain":sources.data.domain,
                                "merchant_txn_id":sources.data.merchant_txn_id
                            };

                            return ssoInstance.post( Config.VOUCHER_APP_URL + "/v3/user/voucher/order-conform",orderConfirmData)
                                .then( (response) => {

                                    const sources = response.data;

                                    if(sources.status===true){
                                        window.location.href = "/voucher/payment/success?isVoucher=true&message="+sources.message;
                                    }

                                    if(sources.status===false){
                                        window.location.href = "/voucher/payment/failed?isVoucher=true&message="+sources.message;
                                    }
                                    return new Promise((resolve,reject)=>{
                                        resolve(sources)
                                    });
                                })
                                .catch( (error) => {
                                    if(error){
                                        const sources = error.response.data;

                                        if(sources.errors){
                                            Object.keys(sources.errors).forEach((key)=>{

                                                error.response.data.errors[key].forEach((error)=>{

                                                    window.location.href = "/voucher/payment/failed?isVoucher=true&message=" + error;

                                                });

                                            });
                                            return new Promise((resolve,reject)=>{
                                                reject(error)
                                            });
                                        }else{
                                            if(sources.status===false){

                                                window.location.href = "/voucher/payment/failed?isVoucher=true&message=" + sources.message;

                                            }else{

                                                window.location.href = "/voucher/payment/failed?isVoucher=true&message=" + sources.message;
                                            }
                                            return new Promise((resolve,reject)=>{
                                                reject(sources)
                                            });
                                        }
                                        // dispatch(actions.paymentCatchResponse(sources));
                                    }
                                })
                        }

                    }
                    return new Promise((resolve,reject)=>{
                        resolve(sources)
                    });
                })
                .catch( (error) => {
                    if(error){
                        const sources = error.response.data;

                        if(sources.errors){
                            Object.keys(sources.errors).forEach((key)=>{

                                error.response.data.errors[key].forEach((error)=>{

                                    Toaster.create({
                                        position: Position.TOP,
                                    }).show({message: error, intent:Intent.DANGER});

                                });

                            });
                            return new Promise((resolve,reject)=>{
                                reject(error)
                            });
                        }else{
                            if(sources.status===false){

                                Toaster.create({
                                    position: Position.TOP,
                                }).show({message: sources.message, intent:Intent.DANGER});

                            }else{

                                Toaster.create({
                                    position: Position.TOP,
                                }).show({message: sources.message, intent:Intent.DANGER});
                            }
                            return new Promise((resolve,reject)=>{
                                reject(sources)
                            });
                        }
                        // dispatch(actions.paymentCatchResponse(sources));
                    }
                })
        }
    }
};

export default connect( mapStateToProps, mapDispatchToProps )(VoucherDetailsComponent);
