import { connect } from "react-redux";
import { ssoInstance } from "gg-react-utilities";
import Config from "../../core/Config";
import * as actions from "../../core/actions";
import Brands from "../components/Brands";

const mapStateToProps = state => {
    return {
        SliderData: state.Sliders.SlidersList,
        CategoriesData: state.Categories.categoryList,
        BrandsData: state.Brands.BrandList
    }
};

const mapDispatchToProps = dispatch => {
    return {
        getCategories: () => {
            return ssoInstance.get(Config.API_URL + "/categories")
                .then((res) => {
                    let sources = res.data;
                    if (sources.status === true)
                        dispatch(actions.setCategories(sources.data));
                    else
                        dispatch(actions.setCategories([]));
                })
                .catch((errors) => {
                    if (errors.response.status !== 401) {
                        /*let message = "";
                        if (errors.response) {
                            message = errors.response.message ? errors.response.message : "Something went wrong!";
                        } else {
                            message = "Something went wrong!";
                        }
                        Toaster.create({
                            position: Position.TOP,
                        }).show({message: message, intent: Intent.DANGER});*/

                        dispatch(actions.setCategories([]));
                    }
                })
        },

        getSliders: () => {
            return ssoInstance.get(Config.API_URL + "/get-all-slider")
                .then((res) => {
                    let sources = res.data;

                    if (sources.status === true)
                        dispatch(actions.setSliders(sources.data));
                    else
                        dispatch(actions.setSliders([]));
                })
                .catch((errors) => {
                    if (errors.response != undefined && errors.response.status !== 401) {

                        dispatch(actions.setSliders([]));
                    }
                })
        },
        unMountBrandPage: () => {
            dispatch(actions.setBrands([]));
            dispatch(actions.setCategories([]));
        },

        getBrands: (url, brand_name, type, category, tat, coin_applicable) => {
            if (!url) url = Config.API_URL + "/all-brands";
            let filterData = {};
            if (brand_name && brand_name !== "") filterData.brand_name = brand_name;
            if (type && type.length !== 0) filterData.type = type;
            if (category && category.length !== 0) filterData.category = category;
            if (tat && tat.length !== 0) filterData.tat = tat;
            if (coin_applicable && coin_applicable !== 0) filterData.coin_applicable = coin_applicable;

            return ssoInstance.post(url, filterData)
                .then((res) => {
                    let sources = res.data;

                    if (sources.status === true)
                        dispatch(actions.setBrands(sources.data));
                    else
                        dispatch(actions.setBrands([]));
                })
                .catch((errors) => {
                    if (errors.response.status !== 401) {
                        /*let message = "";
                        if (errors.response) {
                            message = errors.response.message ? errors.response.message : "Something went wrong!";
                        } else {
                            message = "Something went wrong!";
                        }
                        Toaster.create({
                            position: Position.TOP,
                        }).show({message: message, intent: Intent.DANGER});*/

                        dispatch(actions.setBrands([]));
                    }
                })
        },

        putBrands: (brand_id) => {
            return ssoInstance.put(Config.API_URL + "/favBrand/" + brand_id);
        }
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(Brands);
