import React,{Component} from 'react';
import {Button, Tab, Tabs, Toaster, Position, Intent, Alignment, Toaster as toast} from "@blueprintjs/core";
import { Auth } from 'gg-react-utilities';
import LoadingList from "./LoadingList";
import GGImage from "../../core/images/gg.png";
import Select from 'react-select';

export default class GGVoucherComponent extends Component{

    constructor(props){
        super(props);
        this.state = {
            voucherDetails:[],
            quantity: [],
            selected_price: 0,
            selected_quantity: 0,
            loadingData:false,
            isDescription : "",
            isTerms : "",
            loadingBtnStatus: false,
        }
    }

    getGGVoucherDetails = () => {
        this.props.getGGVoucherDetails(this.props.slug)
            .then((res) => {
                let sources = res.data;

                if (sources.status === true) {
                    this.setState({
                        voucherDetails: sources.data,
                        loadingData: false,
                    });

                    if (sources.data !== null) {

                        let priceList = sources.data;
                        if (priceList.length !== 0) {

                            let price = this.createPrice(priceList.length, priceList);
                            this.setState({price: price})

                            this.setState({quantity: this.createQuantity(10)});
                        }

                    }
                }
            })
            .catch((errors) => {
                if (errors.response.status !== 401) {
                    let message = "";
                    if (errors.response) {
                        message = errors.response.message ? errors.response.message : "Something went wrong!";
                    } else {
                        message = "Something went wrong!";
                    }
                    Toaster.create({
                        position: Position.TOP,
                    }).show({message: message, intent: Intent.DANGER});
                }
            });
    };

    createPrice = (count, priceData) => {
        let priceOptions = [];
        for (let i = 0; i < count; i++) {
            if (this.state.selected_price === priceData[i]['price']) {
                this.setState({
                    selectedOptionPrice: {
                        value: priceData[i]['denomination'],
                        label: priceData[i]['denomination']
                    }
                });
            }
            priceOptions.push({value: priceData[i]['denomination'], label: priceData[i]['denomination'], 'description':priceData[i]['how_to_redeem'], 'terms':priceData[i]['terms']});
        }
        return priceOptions
    };

    createQuantity = (maxCount) => {
        let quantityOptions = [];

        for (let i = 1; i <= maxCount; i++) {
            quantityOptions.push({value: i, label: i});

            if (this.state.selected_quantity === i) {
                this.setState({selectedOptionQuantity: {value: i, label: i}});
            }
        }
        return quantityOptions
    };
    componentDidMount() {
        this.getGGVoucherDetails();
    }

    componentWillReceiveProps(nextProps) {
        this.setState({
            voucherDetails:nextProps.GGVoucherDetail,
            loadingData:false
        })
    }

    handleChangePrice = (selectedOptionPrice) => {
        this.setState({selectedOptionPrice, selected_price: selectedOptionPrice.value, isDescription: selectedOptionPrice.description, isTerms: selectedOptionPrice.terms});
    };

    handleChangeQuantity = (selectedOptionQuantity) => {
        this.setState({selectedOptionQuantity, selected_quantity: selectedOptionQuantity.value});
    };

    placeOrder = async () => {

        if(this.state.selectedOptionPrice === undefined || this.state.selectedOptionPrice.value === ""){
			toast.create({position: Position.TOP}).show({message:'Please select voucher denomination.',intent: Intent.DANGER});
			return false;
        }

        if(this.state.selectedOptionQuantity === undefined || this.state.selectedOptionQuantity.value === ""){
			toast.create({position: Position.TOP}).show({message:'Please select voucher quantity.',intent: Intent.DANGER});
			return false;
        }
        
        let users = await Auth.user();

        var denominationsArr = [];
        for (var i = 0; i < this.state.selectedOptionQuantity.value; i++) {
            denominationsArr.push(this.state.selectedOptionPrice.value);
        }
    
        let orderRequest = {
            platform: "WEB",
            denominations:denominationsArr
        }

        this.setState({loadingBtnStatus: true});
        
        this.props.initiateOrder(orderRequest).then((res) => {
            
            this.setState({loadingBtnStatus: false});
                const resdata = res.data;
            
            if (resdata.status === true) {
                
                const PayUBiz = resdata.data.pg;
                let form = document.createElement("form");
                form.setAttribute("method", "POST");
                form.setAttribute("action", PayUBiz.url);

                for (let key in PayUBiz) {
                    let hiddenField = document.createElement("input");
                    hiddenField.setAttribute("type", "hidden");
                    hiddenField.setAttribute("name", key);
                    hiddenField.setAttribute("value", PayUBiz[key]);
                    form.appendChild(hiddenField);
                }
                document.body.appendChild(form);
                form.submit();
            } else {
                Toaster.create({position: Position.TOP,}).show({message: resdata.message, intent: Intent.DANGER});
            }

        }).catch((error) => {
            this.setState({loadingBtnStatus: false});
            const sources = error.response
            if (sources.data.message === "Validation Error") {
                sources.data.error.map((res) => {
                    Toaster.create({position: Position.TOP}).show({message:res,intent: Intent.DANGER});
                    return res
                })
            } else {
                Toaster.create({position: Position.TOP}).show({message:sources.data.message,intent: Intent.DANGER});
            }
        });
	}

    render() {

        let {selectedOptionPrice} = this.state;
        let {selectedOptionQuantity} = this.state;
        let priceSymbole = "₹";



        if(this.state.loadingData){
            return(
                <React.Fragment>
                    <LoadingList />
                </React.Fragment>
            )
        } else {
            return (
                <React.Fragment>
                    <div className="brand-details col-12 mb-5 ">
                        <div className="bd-box">
                            <div className="bd-header bg-blue p-5 px-s-2">
                                <div className="col-12 justify-content-center d-flex">
                                    <div className="bd-middle col-sm-9 col-md-7 col-lg-5 col-xl-5 px-s-0">
                                        <div className="sec-card mb-3">
                                            <div className={"mega-brand-card " } >
                                                <div className="brand-card d-flex justify-content-between item-align-center"
                                                     style={{backgroundImage: 'linear-gradient(135deg, 0%,  100%)'}}
                                                >
                                                    {/*<p className="brand-name">{this.state.cardData.brand_name}</p>
                                                    <p className="mt-minus">{favicon}</p>*/}
                                                </div>
                                                <div className="text-center">
                                                    <img
                                                        src={GGImage}
                                                        className="pt-3"
                                                        alt="no image found"
                                                    />
                                                </div>
                                                <div className="d-flex justify-content-between item-align-center pl-3 pr-3 pt-3 pb-2">
                                                    {/*<p className="brand-delivery text-center m-auto">Delivery: {tat}</p>
                                                    <p className="brand-product-type text-center m-auto">{this.state.cardData.product_type} Voucher</p>*/}
                                                </div>
                                            </div>
                                        </div>
                                        <div className="sec-form">
                                            <div className="mb-2">
                                                
                                                <Select
                                                    isSearchable={false}
                                                    className={"bd-price-select"}
                                                    value={selectedOptionPrice}
                                                    onChange={this.handleChangePrice}
                                                    options={this.state.price}
                                                    placeholder={"INR (" + priceSymbole + ")"}
                                                />
                                                
                                            </div>
                                            <div className="mb-2 mt-3">
                                                <Select
                                                    isSearchable={false}
                                                    className={"bd-quantity-select"}
                                                    value={selectedOptionQuantity}
                                                    onChange={this.handleChangeQuantity}
                                                    options={this.state.quantity}
                                                    placeholder={"Quantity"}
                                                />
                                            </div>
                                            <div className="text-right bd-add-to-cart mt-4">
                                                
                                                <Button
                                                    loading={this.state.loadingBtnStatus}
                                                    alignText={Alignment.CENTER}
                                                    small={true}
                                                    text={"Buy Now"}
                                                    className={"detail-cart-btn"}
                                                    onClick={this.placeOrder}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            
                                {
                                    this.state.isDescription !== "" && this.state.isTerms !== "" ?
                                        <div className="bd-body">
                                            <Tabs
                                                animate={true}
                                                id="TabsDetails"
                                                key="horizontal"
                                                renderActiveTabPanelOnly={true}
                                                vertical={false}
                                                className="bd-tabs"
                                            >
                                                <Tab className="bd-tab" id={"tab_description"} title={"Description"}
                                                     panel={<div className="tab-content"
                                                                 dangerouslySetInnerHTML={{__html: this.state.isDescription}}></div>}/>
                                                <Tab className="bd-tab" id={"tab_terms"} title={"Terms"}
                                                     panel={<div className="tab-content"
                                                                 dangerouslySetInnerHTML={{__html: this.state.isTerms}}></div>}/>
                                            </Tabs>
                                        </div>
                                    :''
                                }
                            
                        </div>
                    </div>
                </React.Fragment>
            );
        }
    }
}
