import React from 'react';

const LoadingOrderHistory = () => (
    <div>
        <div className="col-12 mb-4 mt-4">
            <div className="bp3-skeleton" style={{width: "100%", height: "60px"}}>&nbsp;</div>
        </div>
        <div className="col-12 mb-4">
            <div className="bp3-skeleton" style={{width: "100%", height: "60px"}}>&nbsp;</div>
        </div>
        <div className="col-12 mb-4">
            <div className="bp3-skeleton" style={{width: "100%", height: "60px"}}>&nbsp;</div>
        </div>
        <div className="col-12 mb-4">
            <div className="bp3-skeleton" style={{width: "100%", height: "60px"}}>&nbsp;</div>
        </div>
        <div className="col-12 mb-4">
            <div className="bp3-skeleton" style={{width: "100%", height: "60px"}}>&nbsp;</div>
        </div>
        <div className="col-12 mb-4">
            <div className="bp3-skeleton" style={{width: "100%", height: "60px"}}>&nbsp;</div>
        </div>
        <div className="col-12 mb-4">
            <div className="bp3-skeleton" style={{width: "100%", height: "60px"}}>&nbsp;</div>
        </div>
        <div className="col-12 mb-4">
            <div className="bp3-skeleton" style={{width: "100%", height: "60px"}}>&nbsp;</div>
        </div>
    </div>
);

export default LoadingOrderHistory;
