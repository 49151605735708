import React from 'react';

const LoadingPopularBrand = () => (
    <div className="row mt-5">
        <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
            <div className="bp3-skeleton" style={{width: "100%", height: "200px"}}>&nbsp;</div>
        </div>
    </div>
);

export default LoadingPopularBrand;
