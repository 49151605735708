import React from 'react';
import {AnchorButton, Button, Dialog, Intent,Toaster,Position} from "@blueprintjs/core";
import ModalPopUpData from "./ModalPopUpData";
import defaultBrand from "../../core/images/OrderHistory/Defaultbrands.png";
import * as Classes from "@blueprintjs/core/lib/esm/common/classes";
import {changeDateFormat} from "../../core/utils/Helper";
import {Helper} from "gg-react-utilities";

export default class OrderHistory extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            order_history: props.order_history,
            is_dialog_open: false
        }
    }

    componentWillReceiveProps(nextProps) {
        this.setState({
            'order_history': nextProps.order_history
        })
    }

    handleOpen = () => {
        this.setState({'is_dialog_open': true})
    };

    handleClose = () => {
        this.setState({'is_dialog_open': false})
    };

    dateFormat = (date, separator) => {
        return changeDateFormat(date, separator)
    };

    downloadInvoice(orderId){
        this.props.getGGVoucherInvoice(orderId);
            
    }
    render() {
        /*
            1->Awaiting For Payment - yellow
            2->Payment Success - green
            3->Payment Fail - orange red
            4->Order Failed - orange red
            5->Success - green
        */
        let status_color = "";

        
        if (this.state.order_history.payment_id){
            status_color = "bp3-text-success";
        }else{
            status_color = "bp3-text-danger";
        } 
        
        return (
            <React.Fragment>
                <tr>
                    <td>
                        <div className="border-rounded">
                            <img src={defaultBrand} alt={"default brand"}/>
                        </div>
                    </td>
                    <td>
                        <div className="oh-bold">
                            {this.state.order_history.id ? this.state.order_history.id : "--"}
                        </div>
                    </td>
                    <td>
                        <div className="oh-bold">
                            {this.state.order_history.cb_choice_title ? this.state.order_history.cb_choice_title : "--"}
                        </div>
                    </td>
                    <td>
                        <div className="oh-bold">
                            {this.state.order_history.basic_amount ? this.state.order_history.basic_amount : "--"}
                        </div>
                    </td>
                    <td>
                        <div className="oh-bold">
                            {this.state.order_history.pg_charge ? this.state.order_history.pg_charge : "--"}
                        </div>
                    </td>
                    <td>
                        <div className="oh-bold">
                            {this.state.order_history.amount ? this.state.order_history.amount : "--"}
                        </div>
                    </td>
                    <td>

                        <div className={"oh-bold " + status_color}>
                            {this.state.order_history.payment_id ? 'Success' : "Payment Failed"}
                        </div>
                    </td>
                    <td>
                        <div className="oh-bold">
                            {this.state.order_history.formatted_created_at ? this.state.order_history.formatted_created_at : "--"}
                        </div>
                    </td>
                    <td className="brand-button">
                        

                    {
                        this.state.order_history.invoice_download_link && this.state.order_history.payment_id
                            ?
                            <Button 
                                small={true} 
                                intent="inform" 
                                className="btn-view mr-2" 
                                title="download Invoice" 
                                icon="import"
                                onClick={
                                    () => {            
                                    this.downloadInvoice(this.state.order_history.id)
                                }}
                            />
                            : ""
                    }

                    </td>
                    
                </tr>
                
            </React.Fragment>
        )
    }
}
