import React from 'react';

const LoadingImageList = () => (
    <div className="row mt-4">
        <div className="col-md-12 mb-4">
            <div className="bp3-skeleton" style={{width: "100%", height: "200px"}}>&nbsp;</div>
        </div>
        <div className="col-md-4 mb-4">
            <div className="bp3-skeleton" style={{width: "100%", height: "200px"}}>&nbsp;</div>
        </div>
        <div className="col-md-4 mb-4">
            <div className="bp3-skeleton" style={{width: "100%", height: "200px"}}>&nbsp;</div>
        </div>
        <div className="col-md-4 mb-4">
            <div className="bp3-skeleton" style={{width: "100%", height: "200px"}}>&nbsp;</div>
        </div>
        <div className="col-md-12 mb-4">
            <div className="bp3-skeleton" style={{width: "100%", height: "200px"}}>&nbsp;</div>
        </div>
    </div>
);

export default LoadingImageList;
