import React from 'react';

const LoadingList = () => (
    <div className="row mb-5">
        <div className="col-12 mb-4">
            <div className="bp3-skeleton" style={{width: "95%", height: "40px", margin: "0 auto"}}>&nbsp;</div>
        </div>

        <div className="col-12 mb-4">
            <div className="bp3-skeleton" style={{width: "95%", height: "485px", margin: "0 auto"}}>&nbsp;</div>
        </div>
    </div>
);

export default LoadingList;
