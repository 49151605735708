import React from 'react';
import {Link} from "react-router-dom";
import PopularBrand from "./PopularBrand";
import ImageList from "./ImageList";

export default class HomePage extends React.Component {
    
    constructor(props) {
        super(props);

        this.state = {
            loadingSliders: true,
            loadingBrands: true,
            images_list: [],
            popular_brand: []
        };
    }

    componentDidMount() {
        this.props.getSliders();
        this.props.getPopularBrands();
    }

    componentWillUnmount() {
        this.props.unMountHome();
    }

    componentWillReceiveProps(nextProps) {
        this.setState({
            images_list: nextProps.SliderData.data
        }, () => {
            this.setState({loadingSliders: false})
        });

        this.setState({
            popular_brand: nextProps.PopularBrandData
        }, () => {
            this.setState({loadingBrands: false})
        });
    }

    render() {
        return (
            <React.Fragment>
                <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                    <div className="section brand-titlebox">

                        {/*<div className="col-md-6 d-flex mt-2 pull-left">
                            <img
                                src={require("../../core/images/Home/mega_brand_breadcrumb.png")}
                                alt="Mega Brands"
                                className="img-fluid"
                            />
                        </div>*/}

                        <div className="col-md-4 pull-right mt-2">
                            <h2 className="brand-title">Mega Brands</h2>
                            <p className="brand-view mt-4"><Link to="/brand">View all</Link></p>
                        </div>
                    </div>
                </div>
                <div className="clearfix"></div>
                <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                    <PopularBrand {...this.props} loading={this.state.loadingBrands}
                                  brands={this.state.popular_brand}/>
                </div>
                <div className="clearfix"></div>
                <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 mt-4 ml-2">
                    <div className="row">
                        <Link to={'/ggvoucher'}>
                            <img src="https://gg-statics.s3.ap-south-1.amazonaws.com/gg-voucher-img.png" alt="GG Voucher" style={{width:'100%'}}></img>
                        </Link> 
                    </div>
                </div>
                <div className="clearfix"></div>
                <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 py-4">
                    <ImageList loading={this.state.loadingSliders} images={this.state.images_list}/>
                </div>
                <div className="clearfix"></div>
            </React.Fragment>
        )
    }
}
