import {connect} from "react-redux";
import {ssoInstance} from "gg-react-utilities";
import Config from "../../core/Config";
import * as actions from "../../core/actions";
import OrderHistoryPage from "../components/OrderHistoryPage";

const mapStateToProps = state => {
    return {
        GGOrderHistory: state.OrderHistory.GGOrderHistory,
    }
};

const mapDispatchToProps = dispatch => {
    return {
        getOrderHistory: (url) => { 
            if (!url) url = Config.APP_WALLET_URL + "/v1.0.2/vouchers/order/list";
            return ssoInstance.get(url)
                .then((res) => {
                    let sources = res.data;
    //console.log("sources",sources);
                    if (sources.status === true)
                        dispatch(actions.setGGOrderHistory(sources.data));
                    else
                        dispatch(actions.setGGOrderHistory([]));
                })
                .catch((errors) => {
                    if (errors.response.status !== 401) {
                        /*let message = "";
                        if (errors.response) {
                            message = errors.response.message ? errors.response.message : "Something went wrong!";
                        } else {
                            message = "Something went wrong!";
                        }
                        Toaster.create({
                            position: Position.TOP,
                        }).show({message: message, intent: Intent.DANGER});*/

                        dispatch(actions.setGGOrderHistory([]));
                    }
                })
        },

        getGGVoucherInvoice:(orderId) =>{//alert("gg");

            return ssoInstance.post(Config.APP_WALLET_URL +'/v1.0.2/vouchers/order/'+orderId+'/download-invoice',{},{
                responseType: 'blob'
            })
            .then((response) => {
                const url = window.URL.createObjectURL(new Blob([response.data], {type: 'application/pdf'}));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', "GG-Voucher-Invoice.pdf");
                document.body.appendChild(link);
                link.click();
                this.setState({
                    downloadLoader:false
                })
            })
            .catch((error) =>{
                /*Toaster.create({position: Position.TOP}).show({message:"Something went wrong",intent: Intent.DANGER});
                this.setState({
                    downloadLoader:false
                })*/
            })
        }
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(OrderHistoryPage);
