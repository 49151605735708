import React,{Component} from 'react';
import {Button, ControlGroup, InputGroup, Intent, Position, Toaster} from "@blueprintjs/core";
import {Link} from "react-router-dom";
import LoadingList from "./LoadingList";
import EmptyState from "../../core/components/EmptyState";
import VoucherCard from "./VoucherCard";

export default class VoucherComponent extends Component{

    constructor(props) {
        super(props);

        this.state = {
            voucherListing: [],
            voucherData: [],
            loadingData:true,
            emptyMessage:''
        }
    }

    componentDidMount(){
        this.props.getVoucherList()
    }

    componentWillReceiveProps(nextProps){

        if(nextProps.voucherListing.status == true) {
            this.setState({
                voucherListing: this.state.voucherListing.concat(nextProps.voucherListing.data.data),
                voucherData: this.state.voucherData.concat(nextProps.voucherListing.data.data),
                loadingData: false,
                emptyMessage:nextProps.voucherListing.message
            })
        }else{
            this.setState({
                voucherListing: [],
                voucherData: [],
                loadingData: false,
                emptyMessage:''
            })
        }
    }

    handleVouchersSearchOnChange = (e) => {
        if(this.state.voucherData.length) {
            var updatedList = this.state.voucherData;
            updatedList = updatedList.filter(function (item) {
                return item.voucher_name.toLowerCase().search(
                    e.target.value.toLowerCase()) !== -1;
            });
            this.setState({voucherListing: updatedList, emptyMessage: "We didn't find any voucher!"});
        }
    };

    /*handleBrandSearchOnClick = () => {

    };*/

    render() {

        return (
            <React.Fragment>
                <div className="brands col-12 mb-5">
                    <div className="row ">
                        {
                            this.state.loadingData ? <LoadingList/> :
							<div className="brand-items col-12">
								<div className="row">
									<div className="search-box col-12">
										<ControlGroup fill={true} vertical={false} className="brand-search">
											<InputGroup onChange={this.handleVouchersSearchOnChange} placeholder="Search your favourite Coin Vouchers Voucher" className="bs-input-search"/>
											<Button onClick={this.handleBrandSearchOnClick} icon="search" className="bs-input-icon"/>
										</ControlGroup>
									</div>

									<div className="col-lg-12 col-xl-12 col-md-12 col-sm-12 col-12 p-0 justify-content-center d-flex">
										<div className="col-12">
											<div className="row">
												{
													!this.state.voucherListing.length ?
														<div className="col-12 p-0 border">
															<EmptyState Message={this.state.emptyMessage} stateType={"voucher_detail"}/>
														</div>
													:
													this.state.voucherListing.map((obj, index) => {
														return (
															<div className="col-lg-3 col-xl-3 col-md-4 col-sm-6 col-12 pb-4">
																<Link to={'/voucher/' + obj.voucher_slug} title={obj.voucher_name}
                                                                      onClick={()=>{
                                                                          if(obj.send_upv == 0) {
                                                                              Toaster.create({
                                                                                  position: Position.TOP,
                                                                              }).show({
                                                                                  message: "Cashback is not applicable On " + obj.voucher_name,
                                                                                  intent: Intent.WARNING
                                                                              });
                                                                          }
                                                                      }}
                                                                >
																	<VoucherCard VoucherDetail={obj} />
																</Link>	
															</div>
														)
													})
												}
											</div>
										</div>
									</div>
								</div>
							</div>
                        }
                    </div>
                </div>
            </React.Fragment>
        );

    }
}
