let initialState = {
    voucherListing: null,
    voucherDetails: null,
    voucherOrderListing: null,
    voucherOrderDetails: null,
    voucherStatus: null
};
const vouchers = (state = initialState, action) => {
    switch (action.type) {
        case 'SET_VOUCHERS':
            return {...state, voucherListing: action.setVouchers};
        case 'SET_VOUCHER_DETAILS':
            return {...state, voucherDetails: action.setVoucherDetails};
        case 'SET_VOUCHER_ORDER_HISTORY':
            return {...state, voucherOrderListing: action.setVoucherOrderHistory};
        case 'SET_VOUCHER_ORDER_DETAILS':
            return {...state, voucherOrderDetails: action.setVoucherOrderDetails};
        case 'SET_VOUCHER_STATUS':
            return {...state, voucherStatus: action.setVoucherStatus};
        default:
            return state
    }
};

export default vouchers
