import { connect } from "react-redux";
import { ssoInstance } from "gg-react-utilities";
import Config from "../../core/Config";
import * as actions from "../../core/actions";
import VoucherComponent from "../component/VoucherComponent";

const mapStateToProps = state => {

    return {
        voucherListing: state.vouchers.voucherListing
    }
};

const mapDispatchToProps = dispatch => {
    return {
        getVoucherList : () => {
            return ssoInstance.get( Config.VOUCHER_APP_URL + "/v3/user/get-voucher-list" )
                .then( (res) => {

                    const sources = res.data;

                    dispatch( actions.setVouchers( sources ) );
                } )
                .catch( (error) => {
                    //const sources = error.response;
                } )
        }
    }
};

export default connect( mapStateToProps, mapDispatchToProps )(VoucherComponent);