import React, {Component} from 'react';
import ImageHome from "../images/EmptyState/Orderhistory.svg";
import ImageBrand from "../images/EmptyState/Orderhistory.svg";
import ImageBrandDetail from "../images/EmptyState/Orderhistory.svg";
import ImageCart from "../images/EmptyState/Orderhistory.svg";
import ImageCheckout from "../images/EmptyState/Orderhistory.svg";
import ImageCheckoutAddress from "../images/EmptyState/Orderhistory.svg";
import ImageOrderHistory from "../images/EmptyState/Orderhistory.svg";
import ImageOrderDetail from "../images/EmptyState/Orderhistory.svg";

export default class EmptyState extends Component {
    constructor(props) {
        super(props);

        this.state = {
            type: props.stateType,
            newMessage: props.Message ? props.Message : ''
        }
    }

    componentWillReceiveProps(nextProps) {
        this.setState({
            type: nextProps.stateType,
            newMessage: nextProps.Message ? nextProps.Message : ''
        })
    }

    render() {
        let image = "";
        let message = "<b>Oh snap,</b> We can't find it.";

        if ("home" === this.state.type) {
            image = ImageHome;
        }

        if ("brand" === this.state.type) {
            image = ImageBrand;
            message = "<b>We didn't find any brand!</b>";
        }

        if ("brand_detail" === this.state.type) {
            image = ImageBrandDetail;
            message = "<b>No brand available currently with this name. Try different one!</b>";
        }

        if ("cart" === this.state.type) {
            image = ImageCart;
            message = "<b>No brands added in cart yet! Start to add your favorite brand.</b>";
        }

        if ("checkout" === this.state.type) {
            image = ImageCheckout;
            message = "<b>Oh!</b> We didn't find any details. Please try after sometime";
        }

        if ("checkout_address" === this.state.type) {
            image = ImageCheckoutAddress;
            message = "<b>You have not added any address yet! Add new Address to select.</b>";
        }

        if ("order_history" === this.state.type) {
            image = ImageOrderHistory;
            message = "<b>No order history available yet!</b>";
        }

        if ("order_detail" === this.state.type) {
            image = ImageOrderDetail;
            message = "<b>Oh!</b> We didn't find any details. Please try after sometime";
        }
        if ("voucher_detail" === this.state.type) {
            image = ImageOrderDetail;
        }

        if ("voucher_order_detail" === this.state.type) {
            image = ImageOrderDetail;
        }

        if ("voucher_history" === this.state.type) {
            image = ImageOrderHistory;
            message = "<b>No voucher history available yet!</b>";
        }

        if ("voucher_detail" === this.state.type) {
            image = ImageOrderDetail;

            message = this.state.newMessage != '' ? "<b>"+this.state.newMessage+"</b>" : "<b>Currently, No Vouchers Available for purchase.</b>";
        }

        return (
            <React.Fragment>
                <div className="col-md-12 p-4">
                    <div className={"mg-empty-state"}>
                        <div className="text-center">
                            <img src={image} className="mr-2 empty-img" alt="empty state"/>
                            <p className="mt-4 mb-4" dangerouslySetInnerHTML={{__html: message}}></p>
                            {/*<Link className="bp3-button bp3-intent-primary bp3-button bp3-intent-primary mt-2" to="/">Go Home</Link>*/}
                        </div>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}
