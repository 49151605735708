import React from 'react';
import TopBar from "../core/components/TopBar";
import VoucherOrderHistoryCantainer from './container/voucherOrderHistoryContainer'
import {Auth, NoLogin} from "gg-react-utilities";
import noLoginImage from "../core/images/NoLogin/no_login.svg";

export default class VoucherOrderHistory extends React.Component {

    componentDidMount() {
        window.scrollTo(0, 0)
    }

    render(){

        let breadcrumbs = [
            {
                to: "/voucher-history",
                label: "History"
            },
        ];

        return(
            <React.Fragment>
                {
                    Auth.isLoggedIn() ?
                        <div className="container mg-brand-history">
                            <div className="row">
                                <TopBar title="Order History"
                                        tabOrderHistory={"coinVoucherOrderHistory"}
                                        isCoinsVoucher={true}
                                        breadcrumbs={breadcrumbs}/>
                            </div>

                            <div className="row mb-5">
                                <VoucherOrderHistoryCantainer/>
                            </div>
                        </div>
                        :
                        <div className="container">
                            <div className="no-login">
                                <div className="no-login-middle">
                                    <div className="text-center">
                                        <img src={noLoginImage} alt={"No Login"}/>
                                    </div>
                                    <NoLogin/>
                                </div>
                            </div>
                        </div>
                }
            </React.Fragment>
        );
    }
}
