import {connect} from "react-redux";
import {ssoInstance} from "gg-react-utilities";
import Config from "../../core/Config";
import * as actions from "../../core/actions";
import HomePage from "../components/HomePage";

const mapStateToProps = state => {
    return {
        SliderData: state.Sliders.SlidersList,
        PopularBrandData: state.Brands.PopularBrandList
    }
};

const mapDispatchToProps = dispatch => {
    return {
        unMountHome: () => {
            dispatch(actions.setSliders([]));
            dispatch(actions.setPopularBrands([]));
        },

        getSliders: () => {
            return ssoInstance.get(Config.API_URL + "/get-all-slider")
                .then((res) => {
                    let sources = res.data;

                    if (sources.status === true)
                        dispatch(actions.setSliders(sources.data));
                    else
                        dispatch(actions.setSliders([]));
                })
                .catch((errors) => {
                    if (errors.response.status !== 401) {
                        /*let message = "";
                        if (errors.response) {
                            message = errors.response.message ? errors.response.message : "Something went wrong!";
                        } else {
                            message = "Something went wrong!";
                        }
                        Toaster.create({
                            position: Position.TOP,
                        }).show({message: message, intent: Intent.DANGER});*/

                        dispatch(actions.setSliders([]));
                    }
                })
        },

        getPopularBrands: () => {
            return ssoInstance.get(Config.API_URL + "/popular-brands")
                .then((res) => {
                    let sources = res.data;

                    if (sources.status === true)
                        dispatch(actions.setPopularBrands(sources.data));
                    else
                        dispatch(actions.setPopularBrands([]));
                })
                .catch((errors) => {
                    if (errors.response.status !== 401) {
                        /*let message = "";
                        if (errors.response) {
                            message = errors.response.message ? errors.response.message : "Something went wrong!";
                        } else {
                            message = "Something went wrong!";
                        }
                        Toaster.create({
                            position: Position.TOP,
                        }).show({message: message, intent: Intent.DANGER});*/

                        dispatch(actions.setPopularBrands([]));
                    }
                })
        },

        putBrands: (brand_id) => {
            return ssoInstance.put(Config.API_URL + "/favBrand/" + brand_id);
        }
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(HomePage);
