import React, { Component } from 'react';
import Loading from "./loading";
import CartListComponent from "./CartListComponent";
import RedeemVoucher from "./RedeemVoucher";
import { Link } from "react-router-dom";
import EmptyState from "../../core/components/EmptyState";
import { Helper } from "gg-react-utilities";

export default class CartFilterComponent extends Component {
    constructor(props) {

        super(props);

        this.state = {
            loadingData: props.loadingData,
            cart_lists: props.cart_lists,
            provider: props.provider,
            userGGVouchers: props.CartListData.active_vouchers
        }
    };

    componentWillReceiveProps(nextProps) {
        this.setState({
            loadingData: nextProps.loadingData,
            cart_lists: nextProps.cart_lists,
            provider: nextProps.provider,
            userGGVouchers: nextProps.CartListData.active_vouchers,
        });
    }

    handleUpdateCart = (slug, price, quantity, applied_coin) => {
        this.setState({ loadingData: true });
        this.props.callBackUpdateCart(slug, price, quantity, applied_coin);
    };

    handleRemoveCart = (slug, price) => {
        this.setState({ loadingData: true });
        this.props.callBackRemoveCart(slug, price);
    };

    handleGGCoin = (getGG) => {
        let countGgCoin = 0;
        Object.keys(getGG).map(function (item, i) {
            countGgCoin = parseInt(countGgCoin, 10) + parseInt(getGG[item], 10);

            return countGgCoin;
        });

        return countGgCoin;
    };

    render() {
        let userCoinBalance = this.state.cart_lists.user_coin ? this.state.cart_lists.user_coin : 0;
        return (
            <React.Fragment>
                {
                    this.state.loadingData ? <Loading /> :
                        this.state.cart_lists.length === 0 ?
                            <EmptyState stateType={"cart"} />
                            :
                            <div className="row cart-list mb-5">
                                <div className="col-xl-8 col-lg-8 col-md-8 col-sm-7 col-12">
                                    <CartListComponent {...this.props}
                                        cartData={this.state.cart_lists.carts}
                                        callbackUpdateCart={this.handleUpdateCart}
                                        callbackRemoveCart={this.handleRemoveCart}
                                        callbackGGCoin={this.handleGGCoin}
                                        userCoinBalance={userCoinBalance}
                                    />
                                </div>

                                <div className="col-xl-4 col-lg-4 col-md-4 col-sm-5 col-12">

                                    <div className='bg-basic mb-cart-total'>
                                        <RedeemVoucher
                                            userGGVouchers={this.state.userGGVouchers}
                                            provider={this.state.provider}
                                        />
                                        <div className="col-xl-12 mb-5">
                                            <h2 className="title">Payable Amount</h2>
                                            {
                                                this.state.cart_lists.payment.map((data,key)=>{
                                                    if(data.key == 'grand_total' || data.key == 'payble_total') {
                                                        return (
                                                            <React.Fragment>
                                                                {
                                                                    data.key == 'grand_total' ?
                                                                        <div className='border-bottom mt-2'/>
                                                                        : ''
                                                                }
                                                                <div className="row">
                                                                    <div
                                                                        className="col-xl-8 col-lg-8 col-md-7 col-sm-8 col-8">
                                                                        <h4 className="sub-text-bold">{data.title}</h4>
                                                                    </div>
                                                                    <div
                                                                        className="col-xl-4 col-lg-4 col-md-5 col-sm-4 col-4 text-right text-nowrap">
                                                                        <h4 className="sub-text-bold">{data.type +' '+ Helper.formatMoney(data.value)}</h4>
                                                                    </div>
                                                                </div>
                                                            </React.Fragment>
                                                        )
                                                    }else{
                                                        return(
                                                            <div className="row">
                                                                <div className="col-xl-8 col-lg-8 col-md-7 col-sm-8 col-8">
                                                                    <h4 className="sub-text">{data.title}</h4>
                                                                </div>
                                                                <div
                                                                    className="col-xl-4 col-lg-4 col-md-5 col-sm-4 col-4 text-right text-nowrap">
                                                                    {
                                                                        data.key == 'total_applied_coin' ?
                                                                            <h4 className="sub-text">{data.type +' '+ data.value}</h4>
                                                                            :
                                                                            <h4 className="sub-text">{data.type +' '+ Helper.formatMoney(data.value)}</h4>
                                                                    }
                                                                </div>
                                                            </div>
                                                        )
                                                    }
                                                })
                                            }
                                            <Link to={"/checkout/" + this.state.provider + "/order-place"}
                                                className={"bp3-button bp3-intent-primary pull-right mt-4"}>Proceed To
                                                Checkout</Link>
                                        </div>

                                    </div>
                                    <div className="col-xl-12 mb-5">
                                        <p>*Attention!</p>
                                        <span>Do you want to make transaction with 0% payment gateway charge? contact our UPOS agent.</span>
                                    </div>


                                </div>
                            </div>
                }
            </React.Fragment>
        );
    }
}
