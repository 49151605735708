import React from 'react';
import GGVoucherOrderHistory from "./GGVoucherTransactionDetailComponent";
import LoadingOrderHistory from "./LoadingOrderHistory";
import EmptyState from "../../core/components/EmptyState";
import {Alignment, Button, Intent} from "@blueprintjs/core";
import {Auth} from "gg-react-utilities";

export default class OrderHistoryPage extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            loadingData: true,
            GGVoucherTransactionList: [],
        }
    }

    componentWillMount() {
        let user = Auth.user();
        this.props.getGGVoucherTransaction(user.user_id);
    }

    componentWillReceiveProps(nextProps) {
        this.setState({
            loadingData: false,
            GGVoucherTransactionList: nextProps.GGVoucherTransaction,
        });
    }

    render() {
        return (
            <React.Fragment>
                <div className="order-history mb-4 mx-4">
                    {
                        this.state.loadingData ?
                            <LoadingOrderHistory/>
                            :
                            this.state.GGVoucherTransactionList.length === 0 ?
                                <EmptyState stateType={"voucher_history"}/>
                                :
                                <React.Fragment>
                                    <div className="table-responsive">
                                        <table className="bp3-html-table table-borderless">
                                            <thead>
                                                <tr>
                                                    <td></td>
                                                    <td><b>Voucher No</b></td>
                                                    <td><b>Initial Balance</b></td>
                                                    <td><b>Current Balance</b></td>
                                                    <td><b>Valid Till</b></td>
                                                    <td><b>Order Date</b></td>
                                                    <td></td>
                                                </tr>
                                            </thead>
                                            <tbody>
                                            {
                                                this.state.GGVoucherTransactionList.map((list, i) => {
                                                    return (
                                                        <GGVoucherOrderHistory
                                                            order_history={list} key={i}/>
                                                    )
                                                })
                                            }
                                            </tbody>
                                        </table>
                                    </div>
                                </React.Fragment>
                    }
                </div>
                <div className="clearfix"></div>
            </React.Fragment>
        );
    }
}
