import { connect } from "react-redux";
import { ssoInstance } from "gg-react-utilities";
import Config from "../../core/Config";
import * as actions from "../../core/actions";
import VoucherOrderHistory from '../component/voucherOrderHistoryComponent'

const mapStateToProps = state => {

    return {
        voucherOrderListing: state.vouchers.voucherOrderListing,
        voucherOrderDetails: state.vouchers.voucherOrderDetails
    }
};

const mapDispatchToProps = dispatch => {
    return {
        getVoucherOrderList : (url) => {

            if(!url) url = Config.VOUCHER_APP_URL + "/v3/user/user-voucher-order-list";

            return ssoInstance.get(url)
                .then( (res) => {
                    const sources = res.data;

                    if( sources.status === true )
                        dispatch( actions.setVoucherOrderHistory( sources.data ) );
                    else
                        dispatch( actions.setVoucherOrderHistory([]) );
                } )
                .catch( (error) => {
                    //const sources = error.response;
                } )
        }
    }
};

export default connect( mapStateToProps, mapDispatchToProps )(VoucherOrderHistory);