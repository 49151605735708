import React from 'react';

const LoadingList = () => (
    <div className="col-md-12">
        <div className="row">

            <div className="col-12 mb-4">
                <div className="bp3-skeleton" style={{height: "40px", margin:"0 auto"}}>&nbsp;</div>
            </div>

            <div className="col-12 mb-4">
                <div className="bp3-skeleton" style={{height: "450px", margin:"0 auto"}}>&nbsp;</div>
            </div>

        </div>
    </div>
);

export default LoadingList;
