const ENV = {
    // Server local, beta, staging or production
    APP_ENV: 'production',
    BASE_URL: 'https://',
    Extension: '.com',
    MEGABRAND_DEFAULT_IMAGE: 'https://s3.ap-south-1.amazonaws.com/gg-mega-brands/brand_images/test/default.png',
    GOOGLE_TRACKING_ID: 'UA-92068611-5',
    YOUTUBE_PLAYER_ID: 'UGN1H3l5fnE',
};

if (ENV.APP_ENV === 'dev') {
    ENV.BASE_URL = 'https://';
    ENV.Extension = '.in';
} else if (ENV.APP_ENV === 'production') {
    ENV.BASE_URL = 'https://';
    ENV.Extension = '.com';
} else if (ENV.APP_ENV === 'local') {
    ENV.BASE_URL = 'https://beta-';
    ENV.Extension = '.in';
}

const config = {
    APP_ENV: ENV.APP_ENV,
    APP_NAME: 'Global Garner',
    API_URL: ENV.BASE_URL + 'mega-brands-api.globalgarner' + ENV.Extension + '/user/v1.0.3',
    APP_WALLET_URL: ENV.BASE_URL + 'wallet.globalgarner' + ENV.Extension + '/api',
    APP_ACCOUNT_URL: ENV.BASE_URL + 'accounts.globalgarner' + ENV.Extension + '/api/v1.0.2/user',
    APP_ACCOUNT_API_URL: ENV.BASE_URL + 'accounts.globalgarner' + ENV.Extension + '/',
    VOUCHER_APP_URL: ENV.BASE_URL + 'voucher.globalgarner' + ENV.Extension,
    IS_MAINTENANCE_MODE: false,
    Extension: ENV.Extension,
    BASE_URL: ENV.BASE_URL,
    MEGABRAND_DEFAULT_IMAGE: ENV.MEGABRAND_DEFAULT_IMAGE,
    GOOGLE_TRACKING_ID: ENV.GOOGLE_TRACKING_ID,
    YOUTUBE_PLAYER_ID: ENV.YOUTUBE_PLAYER_ID,
    REACT_APP_GOOGLE_ANALYTICAL_CODE: 'UA-92068611-5',
    REACT_APP_SENTRY_URL: 'https://1019839690894038b597a18e3bd6fada@sentry.io/1413283',
};

if (ENV.APP_ENV === 'local') {
    config.API_URL = ENV.BASE_URL + 'mega-brands-api.globalgarner' + ENV.Extension + '/user/v1.0.3';
    config.APP_WALLET_URL = ENV.BASE_URL + 'wallet.globalgarner' + ENV.Extension + '/api';
    config.APP_ACCOUNT_URL = ENV.BASE_URL + 'accounts.globalgarner' + ENV.Extension + '/api/v1.0.2/user';
    config.APP_ACCOUNT_API_URL = ENV.BASE_URL + 'accounts.globalgarner' + ENV.Extension + '/';
    config.VOUCHER_APP_URL = ENV.BASE_URL + 'voucher.globalgarner' + ENV.Extension;
    config.IS_MAINTENANCE_MODE = false;
    config.Extension = ENV.Extension;
    config.BASE_URL = ENV.BASE_URL;
}

export default config;

export const packageConfigure = {
    APP_URL: "https://mega-brands.globalgarner.com",
    ACCOUNT_API_URL: ENV.BASE_URL + 'accounts.globalgarner' + ENV.Extension + '/',
    //MEGABRAND_API_URL: ENV.BASE_URL + 'mega-brands-api.globalgarner' + ENV.Extension + '/user/v1.0.3/',
    //STORE_API_URL: ENV.BASE_URL + 'store-api.globalgarner' + ENV.Extension + '/v1.0.3/',
};
