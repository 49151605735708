import React from 'react';
import TopBar from "../../core/components/TopBar";
import BrandsDetailsContainer from "./containers/BrandDetailsContainer";

export default class BrandDetailPage extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            title: ""
        };
    }

    handleTitle = (title) => {
        this.setState({
            title: title
        })
    };

    render() { 
        let slug = this.props.match.params.slug ? this.props.match.params.slug : "";

        let breadcrumbs = [
            {
                to: "/brand",
                label: "Brands"
            },
            {
                to: "/brand/" + slug,
                label: this.state.title
            }
        ];

        return (
            <React.Fragment>
                {
                    <div className="container mg-brand-details">
                        <div className="row">
                            <TopBar title={this.state.title} links="" breadcrumbs={breadcrumbs}/>
                        </div>
                        <div className="row">
                            <BrandsDetailsContainer slug={slug} callBackTitle={this.handleTitle} />
                        </div>
                    </div>
                }
            </React.Fragment>
        )
    }
}
