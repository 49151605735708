import React, { Component } from 'react';
import { Button, Tab, Tabs, NumericInput, Toaster, Position, Intent } from "@blueprintjs/core";
import LoadingList from "./LoadingList";
import TopBar from "../../core/components/TopBar";
import Loader from "../../core/components/Loader";
import { isNumber } from "@material-ui/core/es/styles/transitions";
import CoinsImage from "../../core/images/gg_coins.png";
import { Auth } from "gg-react-utilities";
import VoucherCard from "../../vouchers/component/VoucherCard";
import { Helper } from 'gg-react-utilities';

export default class VoucherDetailsComponent extends Component {

    constructor(props) {
        super(props);
        this.state = {
            voucherDetails: [],
            loadingData: true,
            voucherQty: '',
            paynowLoader: false,
            coinsIsDisabled: false,
            payableTotal: 0,
            coinsApplicable: 0,
            pgCharge: 0,
            voucherCoinsRedeem: '',
            voucherCoinsInsert: '',
            breadcrumbs: [],
            showOrderSection: false
        }
    }

    componentDidMount() {
        this.props.getVoucherDetails(this.props.voucher_type_slug);
    }

    componentWillReceiveProps(nextProps) {

        this.setState({
            voucherDetails: nextProps.voucherDetails,
            loadingData: false
        }, () => {
            this.calculateAmount()
        })
    }

    voucherQtyInput = (Qty) => {

        if (Qty < 0) {
            Toaster.create({

                position: Position.TOP,
            }).show({ message: "Voucher quantity must be in positive value", intent: Intent.DANGER });
            Qty = '';
        }

        if (!isNumber(Qty) || Qty == 0)
            Qty = '';

        if (Qty > 100000)
            Qty = 100000;

        this.setState({
            voucherQty: Qty
        }, () => {
            this.coinsRemove()
        })
    }

    voucherCoinsRedeemInput = (Coins) => {

        if (Coins < 0) {
            Toaster.create({

                position: Position.TOP,
            }).show({ message: "Coins must be in positive value", intent: Intent.DANGER });
            Coins = '';
        }

        if (!isNumber(Coins) || Coins == 0)
            Coins = '';

        if (Coins > 100000000000000000000)
            Coins = 100000000000000000000;

        this.setState({
            'voucherCoinsInsert': Coins
        })
    }

    coinsRedeem = () => {

        if (!Auth.isLoggedIn()) {

            Auth.login(true);
            return false;
        }

        let coinsApplicable = this.state.coinsApplicable;

        if (this.state.voucherQty == '') {

            Toaster.create({

                position: Position.TOP,
            }).show({ message: "Please enter quantity", intent: Intent.DANGER });
            return false;
        }

        if (this.state.voucherCoinsInsert > coinsApplicable || this.state.voucherCoinsInsert == '') {

            if (coinsApplicable == 0) {
                Toaster.create({
                    position: Position.TOP,
                }).show({ message: "You can't redeem coin in this voucher", intent: Intent.DANGER });
            } else {
                Toaster.create({
                    position: Position.TOP,
                }).show({ message: "Redeem coin value must be between 1 and " + coinsApplicable, intent: Intent.DANGER });
            }
            return false;
        }

        if (this.state.voucherCoinsInsert > this.state.voucherDetails.available_coins) {

            Toaster.create({
                position: Position.TOP,
            }).show({ message: "You don't have sufficient GG coins available in your account", intent: Intent.DANGER });
            return false;
        }

        if (coinsApplicable == 0) {

            Toaster.create({
                position: Position.TOP,
            }).show({ message: "You can't Redeem coins in this voucher", intent: Intent.DANGER });
            return false;
        }

        this.setState({
            'coinsIsDisabled': true,
            'payableTotal': this.state.payableTotal - this.state.voucherCoinsInsert
        }, () => {
            this.calculateAmount()
        })

    }

    coinsRemove = () => {
        this.setState({
            coinsIsDisabled: false,
            voucherCoinsInsert: ''
        }, () => {
            this.calculateAmount()
        })
    }

    calculateAmount = () => {

        let subTotal = (this.state.voucherDetails.amount - this.state.voucherDetails.discount) * this.state.voucherQty;
        let grandTotal = subTotal - this.state.voucherCoinsInsert;
        let payableTotal = 0;
        let PgCharge = 0;
        if (grandTotal > 0) {
            PgCharge = ((grandTotal * this.state.voucherDetails.pg_percentage) / 100);
            payableTotal = grandTotal + PgCharge;
        } else {
            payableTotal = grandTotal;
        }
        let redeemCoins = this.state.voucherDetails.coins_redeem * this.state.voucherQty;

        this.setState({
            coinsApplicable: redeemCoins ? redeemCoins : this.state.voucherDetails.coins_redeem,
            subTotal: subTotal,
            payableTotal: payableTotal,
            grandTotal: grandTotal,
            pgCharge: PgCharge,
            voucherCoinsRedeem: this.state.voucherCoinsInsert
        })

    }

    BuyNow = () => {

        if (this.state.voucherDetails == null) {
            Toaster.create({
                position: Position.TOP,
            }).show({ message: "Can not load coin voucher details", intent: Intent.DANGER });

            return false;
        }

        if (parseFloat(this.state.voucherCoinsRedeem) > (parseFloat(this.state.voucherDetails.coins_redeem) * parseFloat(this.state.voucherQty))) {

            Toaster.create({
                position: Position.TOP,
            }).show({ message: "Invalid coins amount", intent: Intent.DANGER });

            return false;
        }

        this.setState({
            paynowLoader: true
        })

        let request = {
            "voucher_type_slug": this.state.voucherDetails.voucher_slug,
            "amount": parseInt(this.state.voucherDetails.amount),
            "sub_total": this.state.subTotal,
            "coins_offers_amount": parseFloat(this.state.voucherDetails.coins_redeem),
            "offer_discount_amount": parseFloat(this.state.voucherDetails.discount),
            "grand_total": parseFloat(this.state.grandTotal),
            "payable_total": parseFloat(this.state.payableTotal),
            "domain": "USER-VOUCHER",
            "quantity": parseInt(this.state.voucherQty),
            "platform": "web",
            "used_coins": parseFloat(this.state.voucherCoinsRedeem),
            "pg_charge": this.state.pgCharge
        }

        this.props.orderPlace(request, this.props.userInfo)
            .then((response) => {

            })
            .catch((error) => {
                this.setState({
                    paynowLoader: false,
                    isOpen: false
                })
            });
    }

    showPlaceBox = () => {

        if (!Auth.isLoggedIn()) {

            Auth.login(true);
            return false;
        }
        if (!this.state.voucherQty) {
            Toaster.create({
                position: Position.TOP,
            }).show({ message: "Please enter quantity", intent: Intent.DANGER });
            return false;
        }

        this.setState({
            showOrderSection: true
        })
    }
    EditOrder = () => {
        this.setState({
            showOrderSection: false
        })
    }

    render() {
        let breadcrumbs = [];
        if (this.state.loadingData) {
            return (
                <React.Fragment>
                    <LoadingList />
                </React.Fragment>
            )
        } else {

            breadcrumbs = [
                {
                    to: "/voucher",
                    label: "Voucher"
                },
                {
                    to: "/voucher/" + this.state.voucherDetails.voucher_slug,
                    label: this.state.voucherDetails.meta_title
                },
            ];
            // let metaData = document.getElementsByTagName("meta");
            // metaData['keywords'].content = this.state.voucherDetails.meta_keyword;
            // metaData['description'].content = this.state.voucherDetails.meta_desc;

            return (
                <React.Fragment>
                    <TopBar title={this.state.voucherDetails.meta_title}
                        ggUserCoins={true}
                        isLoggedIn={this.state.voucherDetails.isLoggedIn}
                        userAvailableCoins={this.state.voucherDetails.available_coins}
                        isCoinsVoucher={true}
                        breadcrumbs={breadcrumbs} />

                    <div className="brand-details col-12">
                        <div className="bd-box voucher-box">
                            <div className="row no-gutters">
                                <div
                                    className={this.state.showOrderSection ? "bd-header bg-blue p-5 px-s-2 col-md-6 col-sm-12 col-12" : "bd-header bg-blue p-5 px-s-2 col-12"}>
                                    <div
                                        className={this.state.showOrderSection ? "row vouchar-middle col-xl-10 col-lg-10 col-md-12 col-sm-10 p-s-0" : "row vouchar-middle col-xl-5 col-lg-6 col-md-7 col-sm-10 p-s-0"}>
                                        <div className="sec-card mb-2 col-12">
                                            <VoucherCard VoucherDetail={this.state.voucherDetails} />
                                        </div>
                                        <div className="sec-form col-12">
                                            <div className="mb-2">
                                                <h3 className="m-0 text-center">
                                                    {
                                                        this.state.voucherDetails.discount > 0 ?
                                                            <span><b>₹ {this.state.voucherDetails.amount - this.state.voucherDetails.discount}</b> &nbsp;
                                                                <strike
                                                                    className="strike-price">₹ {this.state.voucherDetails.amount} </strike></span>
                                                            :
                                                            <b>{Helper.formatMoney(this.state.voucherDetails.amount)}</b>
                                                    }
                                                </h3>
                                            </div>
                                            <div className="mb-2">
                                                <NumericInput
                                                    allowNumericCharactersOnly={true}
                                                    onValueChange={this.voucherQtyInput}
                                                    min={0}
                                                    max={100}
                                                    buttonPosition="none"
                                                    placeholder="Quantity"
                                                    className="vouchar-input"
                                                    disabled={this.state.showOrderSection}
                                                    value={this.state.voucherQty}
                                                />

                                            </div>
                                            {
                                                this.state.coinsApplicable != 0 ?
                                                    <div className="mb-2">
                                                        <NumericInput
                                                            allowNumericCharactersOnly={true}
                                                            onValueChange={this.voucherCoinsRedeemInput}
                                                            min={0}
                                                            buttonPosition="none"
                                                            placeholder="GG-Coins"
                                                            className="vouchar-input"
                                                            disabled={this.state.coinsIsDisabled || this.state.showOrderSection}
                                                            value={this.state.voucherCoinsInsert}
                                                        />
                                                        <div className="mt-2 ">
                                                            {
                                                                this.state.coinsIsDisabled ?
                                                                    <Button small={true}
                                                                        className="pull-right vouchar-btn remove"
                                                                        disabled={this.state.showOrderSection}
                                                                        intent="danger" onClick={this.coinsRemove}
                                                                        text="Remove" />
                                                                    :
                                                                    <Button small={true}
                                                                        className="pull-right vouchar-btn redeem"
                                                                        disabled={this.state.showOrderSection}
                                                                        intent="primary" onClick={this.coinsRedeem}
                                                                        text="Redeem" />
                                                            }
                                                        </div>
                                                        <div className="row mt-2">
                                                            <div className="col-md-12 float-left">
                                                                <span className="mt-2 bp3-text-small">Maximum GG-Coin Applicable: {this.state.coinsApplicable}</span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    : ''
                                            }
                                            <div className="row mt-4 pt-2 d-flex justify-content-between">
                                                <div
                                                    className="col-12 d-flex justify-content-between align-items-center">
                                                    <h2 className="m-0">
                                                        <b>{Helper.formatMoney(this.state.grandTotal)}</b></h2>
                                                    <Button intent={Intent.PRIMARY} onClick={this.showPlaceBox}
                                                        className={this.state.showOrderSection ? "d-none" : "bp3-small btn-view mr-3 pull-right mr-s-0"}>Buy
                                                        Now</Button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </div>

                                <div
                                    className={this.state.showOrderSection ? "bd-header bg-blue p-5 px-s-3 col-md-6 col-sm-12 col-12 border-left d-flex justify-content-center flex-column" : "bd-header bg-blue p-5 px-s-2 col-6 d-none"}>
                                    <React.Fragment>
                                        <div className="row">
                                            <div className="col-12">
                                                <div className="mb-3">
                                                    <div
                                                        className="row d-flex justify-content-between align-items-center">
                                                        <div className="col-xl-8 col-lg-8 col-md-8 col-sm-6 col-6">
                                                            <div className="d1 t-title">Total</div>
                                                        </div>
                                                        <div
                                                            className="col-xl-4 col-lg-4 col-md-4 col-sm-6 col-6 text-right">
                                                            <div
                                                                className="d3 t-sub-title"> {Helper.formatMoney(this.state.subTotal)}</div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="mb-3">
                                                    {
                                                        this.state.coinsApplicable != 0 ?
                                                            <div
                                                                className="row d-flex justify-content-between align-items-center">
                                                                <div className="col-xl-8 col-lg-8 col-md-8 col-sm-6 col-6">
                                                                    <div className="d1 t-title">GG Coin Redeemed<img
                                                                        src={CoinsImage}
                                                                        className="coinsImage"
                                                                        width="16px"
                                                                        alt="image not available" /></div>
                                                                </div>
                                                                <div
                                                                    className="col-xl-4 col-lg-4 col-md-4 col-sm-6 col-6 text-right">
                                                                    <div
                                                                        className="d3 t-sub-title">- {this.state.voucherCoinsRedeem ? this.state.voucherCoinsRedeem : 0}</div>
                                                                </div>
                                                            </div>
                                                            : ''
                                                    }
                                                </div>
                                                <hr style={{ borderColor: '#fff' }} />
                                                <div className="mb-3">
                                                    <div
                                                        className="row d-flex justify-content-between align-items-center">
                                                        <div className="col-xl-8 col-lg-8 col-md-8 col-sm-6 col-6">
                                                            <div className="d1 t-title">Sub Total</div>
                                                        </div>
                                                        <div
                                                            className="col-xl-4 col-lg-4 col-md-4 col-sm-6 col-6 text-right">
                                                            <div
                                                                className="d3 t-sub-title">{Helper.formatMoney(this.state.grandTotal)}</div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="mb-3">
                                                    <div
                                                        className="row d-flex justify-content-between align-items-center">
                                                        <div className="col-xl-8 col-lg-8 col-md-8 col-sm-6 col-6">
                                                            <div className="d1 t-title">Payment Charges ({this.state.voucherDetails.pg_percentage} %)</div>
                                                        </div>
                                                        <div
                                                            className="col-xl-4 col-lg-4 col-md-4 col-sm-6 col-6 text-right">
                                                            <div
                                                                className="d3 t-sub-title">+ {Helper.formatMoney(this.state.pgCharge)}</div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <hr style={{ borderColor: '#fff' }} />
                                                <div className="mb-3">
                                                    <div
                                                        className="row d-flex justify-content-between align-items-center">
                                                        <div className="col-xl-8 col-lg-8 col-md-8 col-sm-6 col-6">
                                                            <div className={"d1 t-title bold"}>Grand Total</div>
                                                        </div>
                                                        <div
                                                            className="col-xl-4 col-lg-4 col-md-4 col-sm-6 col-6 text-right">
                                                            <div
                                                                className={"d3 t-sub-title bold"}> {Helper.formatMoney(this.state.payableTotal)}</div>
                                                        </div>
                                                    </div>
                                                    <div
                                                        className="mt-5 d-flex justify-content-between align-items-center">
                                                        <Button intent={Intent.WARNING} onClick={this.EditOrder}
                                                            className="voucharedit-order">Edit Order</Button>
                                                        {
                                                            this.state.paynowLoader ?
                                                                <div className="voucherLoader"><Loader /></div> :
                                                                <Button intent={Intent.PRIMARY} onClick={this.BuyNow}
                                                                    className="voucharplace-order">Place
                                                                    Order</Button>
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </React.Fragment>
                                </div>
                            </div>
                            <div className="bd-body">

                                <Tabs
                                    animate={true}
                                    id="TabsDetails"
                                    key="horizontal"
                                    renderActiveTabPanelOnly={true}
                                    vertical={false}
                                    className="bd-tabs"
                                >
                                    <Tab className="bd-tab" id="tab1" title="How To Use"
                                        panel={<div className="tab-content"
                                            dangerouslySetInnerHTML={{ __html: this.state.voucherDetails.guide }}></div>} />
                                    <Tab className="bd-tab" id="tab2" title="Terms & Conditions"
                                        panel={<div className="tab-content"
                                            dangerouslySetInnerHTML={{ __html: this.state.voucherDetails.terms_conditions }}></div>} />
                                </Tabs>

                            </div>
                        </div>
                    </div>
                </React.Fragment>
            );
        }
    }
}
