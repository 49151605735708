let initialState = {
    BrandList: [],
    BrandDetailList: [],
    PopularBrandList: [],
    GGVoucherDetail:[]
};

const Brands = (state = initialState, action) => {
    switch (action.type) {
        case 'SET_BRANDS':
            return {...state, BrandList: action.setBrands};

        case 'SET_POPULAR_BRANDS':
            return {...state, PopularBrandList: action.setPopularBrands};

        case 'SET_BRAND_DETAILS':
            return {...state, BrandDetailList: action.setBrandDetails};

        default:
            return state;
    }
};

export default Brands;
