import React from 'react';
import KeyboardArrowLeftRounded from '@material-ui/icons/KeyboardArrowLeftRounded';
import KeyboardArrowRightRounded from '@material-ui/icons/KeyboardArrowRightRounded';
import InfiniteCarousel from 'react-leaf-carousel';
import {Link} from "react-router-dom";
import LoadingPopularBrand from "./LoadingPopularBrand";
import CouponCardSecond from "../../core/components/CouponCardSecond";

let counter = 0;
const nextArrow = React.createElement('div', {className: "slider-controller next"},
    React.createElement('span', {className: "icon"}, <KeyboardArrowLeftRounded/>)
);
const prevArrow = React.createElement('div', {className: "slider-controller prev"},
    React.createElement('span', {className: "icon"}, <KeyboardArrowRightRounded/>)
);

export default class PopularBrand extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            loading: props.loading,
            brands: props.brands
        }
    }

    componentWillReceiveProps(nextProps) {
        this.setState(
            {
                loading: nextProps.loading,
                brands: nextProps.brands ? nextProps.brands : []
            }
        )
    }

    render() {
        return (
            this.state.loading ?
                <LoadingPopularBrand/>
                :
                this.state.brands.length ?
                    <div className="popular mt-4">
                        <div className="slider">
                            <h4 className="slider-heading mt-2">Popular Mega Brands</h4>
                            <div className="carousel slide">
                                <div className="row home-slider">
                                    <InfiniteCarousel
                                        breakpoints={[
                                            {
                                                breakpoint: 500,
                                                settings: {
                                                    slidesToShow: 2,
                                                    slidesToScroll: 2,
                                                },
                                            },
                                            {
                                                breakpoint: 768,
                                                settings: {
                                                    slidesToShow: 2,
                                                    slidesToScroll: 2,
                                                },
                                            },
                                        ]}
                                        dots={false}
                                        showSides={true}
                                        sideSize={0}
                                        slidesToScroll={3}
                                        slidesToShow={3}
                                        scrollOnDevice={true}
                                        nextArrow={prevArrow}
                                        prevArrow={nextArrow}
                                    >
                                        {
                                            this.state.brands.map((brand, key) => {
                                                counter++;
                                                if (counter > 6) counter = 1;
                                                return (
                                                    <div className="left pd60" key={key}>
                                                        <Link to={'/brand/' + brand.slug}>
                                                            <CouponCardSecond {...this.props} cardData={brand}
                                                                        cardNumber={counter} cardStyleType={2}/>
                                                        </Link>
                                                    </div>
                                                )
                                            })
                                        }
                                    </InfiniteCarousel>
                                </div>
                            </div>
                        </div>
                        <div className="clearfix"></div>
                    </div>
                    : ""
        );
    }
}
