export const setCategories = (sources) => {
    return {
        type: "SET_CATEGORIES",
        setCategories: sources
    }
};

export const setBrands = (sources) => {
    return {
        type: "SET_BRANDS",
        setBrands: sources
    }
};

export const setVouchers = (source) => {
    return {
        type: "SET_VOUCHERS",
        setVouchers: source
    }
};

export const setVoucherDetails = (source) => {
    return {
        type: "SET_VOUCHER_DETAILS",
        setVoucherDetails: source
    }
};

export const setVoucherOrderHistory = (source) => {
    return {
        type: "SET_VOUCHER_ORDER_HISTORY",
        setVoucherOrderHistory: source
    }
};

export const setVoucherOrderDetails = (source) => {
    return {
        type: "SET_VOUCHER_ORDER_DETAILS",
        setVoucherOrderDetails: source
    }
};

export const setVoucherStatus = (source) => {
    return {
        type: "SET_VOUCHER_STATUS",
        setVoucherStatus: source
    }
};

export const setPopularBrands = (sources) => {
    return {
        type: "SET_POPULAR_BRANDS",
        setPopularBrands: sources
    }
};

export const setBrandDetails = (sources) => {
    return {
        type: "SET_BRAND_DETAILS",
        setBrandDetails: sources
    }
};

export const setSliders = (sources) => {
    return {
        type: "SET_SLIDERS",
        setSliders: sources
    }
};

export const setCarts = (sources) => {
    return {
        type: "SET_CARTS",
        setCarts: sources
    }
};

export const setCartCounts = (sources) => {
    return {
        type: "SET_CART_COUNTS",
        setCartCounts: sources
    }
};

export const setOrderHistory = (sources) => { 
    return {
        type: "SET_ORDER_HISTORY",
        setOrderHistory: sources
    }
};

export const setGGOrderHistory = (sources) => {
    return {
        type: "SET_GG_ORDER_HISTORY",
        setGGOrderHistory: sources
    }
};

export const setGGVoucherTransaction = (sources) => {
    return {
        type: "SET_GG_VOUCHER_TRANSACTION",
        setGGVoucherTransaction: sources
    }
};

export const setGGVoucherTransactionDetails = (sources) => {
    return {
        type: "SET_GG_VOUCHER_TRANSACTION_DETAILS",
        setGGVoucherTransactionDetails: sources
    }
};