import React from 'react';
import {AnchorButton, Button, Dialog, Intent} from "@blueprintjs/core";
import ModalPopUpData from "./ModalPopUpData";
import defaultBrand from "../../core/images/OrderHistory/Defaultbrands.png";
import * as Classes from "@blueprintjs/core/lib/esm/common/classes";
import {changeDateFormat} from "../../core/utils/Helper";
import {Helper} from "gg-react-utilities";

export default class OrderHistory extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            order_history: props.order_history,
            is_dialog_open: false
        }
    }

    componentWillReceiveProps(nextProps) {
        this.setState({
            'order_history': nextProps.order_history
        })
    }

    handleOpen = () => {
        this.setState({'is_dialog_open': true})
    };

    handleClose = () => {
        this.setState({'is_dialog_open': false})
    };

    dateFormat = (date, separator) => {
        return changeDateFormat(date, separator)
    };

    render() {
        /*
            1->Awaiting For Payment - yellow
            2->Payment Success - green
            3->Payment Fail - orange red
            4->Order Failed - orange red
            5->Success - green
        */
        let status_color = "";

        if ("1" === this.state.order_history.order_status) status_color = "bp3-text-warning";
        if ("2" === this.state.order_history.order_status) status_color = "bp3-text-success";
        if ("3" === this.state.order_history.order_status) status_color = "bp3-text-danger";
        if ("4" === this.state.order_history.order_status) status_color = "bp3-text-danger";
        if ("5" === this.state.order_history.order_status) status_color = "bp3-text-success";

        return (
            <React.Fragment>
                <tr>
                    <td>
                        <div className="border-rounded">
                            <img src={defaultBrand} alt={"default brand"}/>
                        </div>
                    </td>
                    <td>
                        <div className="oh-bold">
                            {this.state.order_history.order_id ? this.state.order_history.order_id : "--"}
                        </div>
                        
                    </td>
                    <td>
                        {this.state.order_history.receipt_no ? this.state.order_history.receipt_no : "--"}
                    </td>
                    <td>
                        {
                            this.state.order_history.create_at ?
                                this.dateFormat(
                                    this.state.order_history.create_at,
                                    "-"
                                ) :
                                "--"
                        }
                    </td>
                    <td>
                        <div className="oh-bold">
                            {this.state.order_history.grandtotal ? Helper.formatMoney(this.state.order_history.grandtotal) : "--"}
                        </div>
                    </td>
                    <td>
                        <div
                            className="oh-bold">
                            {this.state.order_history.caseback_choice_text ? this.state.order_history.caseback_choice_text : "--"}
                        </div>
                    </td>
                    <td>
                        <div
                            className={"oh-status " + status_color}>
                            {this.state.order_history.order_status_text ? this.state.order_history.order_status_text : "--"}
                        </div>
                    </td>
                    <td className="brand-button">
                        {
                            this.state.order_history.voucher_detail_view ?
                            <Button
                                small={true}
                                intent="primary"
                                text="View"
                                className="btn-view"
                                onClick={() => this.handleOpen(this.state.order_history.order_id)}
                            />
                            :''
                        }
                        {
                            this.state.order_history.invoice_download_link
                                ?
                                <AnchorButton
                                    href={this.state.order_history.invoice_download_link}
                                    target="_blank"
                                    small={true}
                                    intent="success"
                                    text="Download"
                                    className="btn-download"
                                />
                                : ""
                        }
                    </td>
                </tr>
                <Dialog
                    onClose={this.handleClose}
                    title="Order Details"
                    isOpen={this.state.is_dialog_open}
                    lazy={true}
                    className="bp3-dialog-large"
                    canOutsideClickClose={false}
                >
                    <ModalPopUpData receiptNo={this.state.order_history.receipt_no}/>

                    <div className={Classes.DIALOG_FOOTER}>
                        <div className={Classes.DIALOG_FOOTER_ACTIONS}>
                            <Button intent={Intent.DANGER} onClick={this.handleClose}>Close</Button>
                        </div>
                    </div>
                </Dialog>
            </React.Fragment>
        )
    }
}
