import React from 'react';
import * as Classes from "@blueprintjs/core/lib/esm/common/classes";
import {Button, Switch, Label} from "@blueprintjs/core";
import LoadingModal from './LoadingModalPopup'
import CoinsImage from "../../core/images/gg_coins.png";
import {Helper} from "gg-react-utilities";

export default class ModalPopUpData extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            voucherOrderDetails:[],
            voucherLoadMoreUrl:'',
            isLoadMore:true,
            loadingData:true,
            voucherStatus:[],
            orderId: props.orderId
        }

    }

    componentWillMount() {

        this.props.getVoucherOrderDetail(this.props.orderId);
    }

    componentWillReceiveProps(nextProps) {

        if(this.state.isLoadMore) {

            this.setState({
                voucherOrderDetails: this.state.voucherOrderDetails.concat(nextProps.voucherOrderDetails.data),
                voucherLoadMoreUrl: nextProps.voucherOrderDetails.next_page_url,
                loadingData: false,
                isLoadMore: false
            })
        }else{

            this.setState({
                voucherLoadMoreUrl: nextProps.voucherOrderDetails.next_page_url,
                loadingData: false,
                isLoadMore: false
            })
        }

    }

    changeVoucherStatus = (e) =>{
        this.props.setVoucherStatus(e.target.value)
        let voucherOrderIndex = this.state.voucherOrderDetails.findIndex(crItem => parseInt(crItem.voucher_order_detail_id) === parseInt(e.target.value));
        let voucherOrderData = this.state.voucherOrderDetails;
        voucherOrderData[voucherOrderIndex].voucher_status ? voucherOrderData[voucherOrderIndex].voucher_status = 0 :  voucherOrderData[voucherOrderIndex].voucher_status = 1;
        this.setState({
            voucherOrderDetails: voucherOrderData
        })
    }

    LoadVoucherOrders (){

        this.props.getVoucherOrderDetail(this.props.orderId,this.state.voucherLoadMoreUrl);

        this.setState({
            isLoadMore:true
        })
    }
    render(){
        const orderDetail = this.state.voucherOrderDetails[0];
        return(
            <React.Fragment>
                {
                    this.state.loadingData ? <LoadingModal/>:
                        <div className={Classes.DIALOG_BODY + " popup-voucher"}>

                            <div className="row">
                                <div className="col-4 mb-4">
                                    <Label className="s-title">
                                        Order id
                                        <span className="s-sub-title">{orderDetail.voucher_order_id} </span>
                                    </Label>
                                </div>

                                <div className="col-4 mb-4">
                                    <Label className="s-title">
                                        Voucher Type
                                        <span className="s-sub-title">{orderDetail.voucher_name}</span>
                                    </Label>
                                </div>


                                <div className="col-4 mb-4">
                                    <Label className="s-title">
                                        Quantity
                                        <span className="s-sub-title">{orderDetail.quantity}</span>
                                    </Label>
                                </div>

                                <div className="col-4 mb-4">
                                    <Label className="s-title">
                                        Order Status
                                        <span className="s-sub-title" style={{color:orderDetail.order_status_color}}>{orderDetail.order_status_text}</span>
                                    </Label>
                                </div>

                                <div className="col-4 mb-4">
                                    <Label className="s-title">
                                        Platform
                                        <span className="s-sub-title">{orderDetail.platform}</span>
                                    </Label>
                                </div>

                                <div className="col-4 mb-4">
                                    <Label className="s-title">
                                        Date Of Purchase
                                        <span className="s-sub-title">{orderDetail.order_date}</span>
                                    </Label>
                                </div>
                            </div>

                            <div className="row mt-4">
                                <div className="col-12">
                                    <div className="table-items mb-3">
                                        <div className="row tr-item">
                                            <div className="col-xl-3 col-lg-3 col-md-3 col-sm-3 col-4">
                                                <div className="d1 t-title">Total</div>
                                            </div>
                                            <div className="col-xl-5 col-lg-5 col-md-5 col-sm-5 col-4">
                                                <div className="d2">&nbsp;</div>
                                            </div>
                                            <div className="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-4">
                                                <div className="d3 t-sub-title">{ Helper.formatMoney(orderDetail.sub_total) }   ({Helper.formatMoney((orderDetail.amount - orderDetail.offer_discount_amount))} x {orderDetail.quantity}) </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="table-items mb-3">
                                        <div className="row tr-item">
                                            <div className="col-xl-3 col-lg-3 col-md-3 col-sm-3 col-4">
                                                <div className="d1 t-title">Used Coins<img src={CoinsImage} className="coinsImage" alt="" width="16px" /></div>
                                            </div>
                                            <div className="col-xl-5 col-lg-5 col-md-5 col-sm-5 col-4">
                                                <div className="d2">&nbsp;</div>
                                            </div>
                                            <div className="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-4">
                                                <div className="d3 t-sub-title">- {orderDetail.used_coins ? orderDetail.used_coins : 0 }</div>
                                            </div>
                                        </div>
                                    </div>
                                    <hr/>
                                    <div className="table-items mb-3">
                                        <div className="row tr-item">
                                            <div className="col-xl-3 col-lg-3 col-md-3 col-sm-3 col-4">
                                                <div className="d1 t-title">Sub Total</div>
                                            </div>
                                            <div className="col-xl-5 col-lg-5 col-md-5 col-sm-5 col-4">
                                                <div className="d2">&nbsp;</div>
                                            </div>
                                            <div className="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-4">
                                                <div className="d3 t-sub-title">{ Helper.formatMoney(orderDetail.grand_total) } </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="table-items mb-3">
                                        <div className="row tr-item">
                                            <div className="col-xl-3 col-lg-3 col-md-3 col-sm-3 col-4">
                                                <div className="d1 t-title">Payment Charges ({orderDetail.pg_percentage} %)</div>
                                            </div>
                                            <div className="col-xl-5 col-lg-5 col-md-5 col-sm-5 col-4">
                                                <div className="d2">&nbsp;</div>
                                            </div>
                                            <div className="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-4">
                                                <div className="d3 t-sub-title">+ {Helper.formatMoney(orderDetail.pg_charge)}</div>
                                            </div>
                                        </div>  
                                    </div>
                                    <hr/>
                                    <div className="table-items mb-3">
                                        <div className="row tr-item">
                                            <div className="col-xl-8 col-lg-8 col-md-8 col-sm-8 col-8">
                                                <div className={"d1 t-title bold"}>Grand Total</div>
                                            </div>
                                            <div className="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-4">
                                                <div className={"d3 t-sub-title bold"}>{ Helper.formatMoney(orderDetail.payable_total) }</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {
                            orderDetail.order_status === 1 ?
                                this.state.voucherOrderDetails.map((list, index) => {

                                    return (
                                        <div className="voucher-block" key={index}>
                                            <div className="row">
                                                <div className="col-xl-7 col-lg-7 col-md-7 col-sm-7 col-12">
                                                    <Label className="title">
                                                        Voucher Code
                                                        <span className="sub-title">{list.code}</span>
                                                    </Label>
                                                </div>

                                                <div className="col-xl-3 col-lg-3 col-md-3 col-sm-3 col-12">
                                                    <Label className="title">
                                                        Expiry Date
                                                        <span className="sub-title">{list.expiry}</span>
                                                    </Label>
                                                </div>

                                                <div className="col-xl-2 col-lg-2 col-md-2 col-sm-2 col-12">
                                                    <Label className="title">
                                                        <div>
                                                        {
                                                            list.voucher_status ? 'used' : 'unused'
                                                        }
                                                        </div>
                                                        <Switch defaultChecked={list.voucher_status} value={list.voucher_order_detail_id} onChange={this.changeVoucherStatus} />
                                                    </Label>
                                                </div>

                                            </div>
                                        </div>
                                    )
                                })
                                :
                                ''
                            }
                            <div className="col-12 bs-list-more text-center mt-4">
                                {
                                    this.state.voucherLoadMoreUrl ?
                                        <Button small={true} intent="primary" text="Load More"
                                                className="btn-view mr-2"
                                                onClick={() => this.LoadVoucherOrders()}/>
                                        :
                                        ''
                                }
                            </div>
                                <hr/>
                        </div>
                }
            </React.Fragment>
        );
    }
}
