import React, {Component} from 'react';
import {Alert, AnchorButton, Card, Intent} from "@blueprintjs/core";
import CouponCardSecond from "../../core/components/CouponCardSecond";
import IsCoinComponent from "./IsCoinComponent";
import ChangeableQuantityComponent from "./ChangeableQuantityComponent";
import _ from "lodash";
import {Helper} from "gg-react-utilities";

export default class CartListComponent extends Component {
    constructor(props) {
        super(props);

        this.state = {
            cartData: props.cartData,
            userCoinBalance: props.userCoinBalance,
            isAlertOpen: false,
            confirmSlug: "",
            confirmPrice: ""
        };
    }

    prevTotalAppiledCoin = () => {
        if (!_.isEmpty(this.state.cartData)) {
            let output = _.sumBy(this.state.cartData, function (o) {
                return o.item.applied_coin;
            });

            return output;
        }
        return 0;
    };

    componentWillReceiveProps(nextProps) {
        this.setState({
            cartData: nextProps.cartData,
            userCoinBalance: nextProps.userCoinBalance,
            isAlertOpen: false,
        });
    }

    handleUpdateCart = (slug, price, quantity, applied_coin) => {
        if (quantity <= 0)
            this.props.callbackRemoveCart(slug, price);
        else
            this.props.callbackUpdateCart(slug, price, quantity, applied_coin);

    };

    handleRemoveCart = (slug, price) => {
        this.setState({confirmSlug: "", confirmPrice: ""});

        this.setState({
            isAlertOpen: true,
            confirmSlug: slug,
            confirmPrice: price
        });
    };

    confirmBrandActionYes = () => {
        let slug = this.state.confirmSlug;
        let price = this.state.confirmPrice;

        if (!_.isEmpty(slug) || !_.isEmpty(price)) {
            this.props.callbackRemoveCart(slug, price);
        }
    };

    confirmBrandActionNo = () => {
        this.setState({isAlertOpen: false, confirmSlug: "", confirmPrice: ""});
    };

    handleRedeemCoin = (slug, price, quantity, applied_coin) => {
        this.props.callbackUpdateCart(slug, price, quantity, applied_coin);
    };

    render() {
        return (
            <React.Fragment>

                <Alert
                    icon="trash"
                    intent={Intent.DANGER}
                    isOpen={this.state.isAlertOpen}
                    cancelButtonText="No"
                    onCancel={this.confirmBrandActionNo}
                    confirmButtonText="Yes"
                    onConfirm={this.confirmBrandActionYes}
                >
                    Are you sure you want to remove this brand?
                </Alert>
                {
                    !this.state.cartData ? ""
                        :
                        this.state.cartData.map((list, i) => {
                            let brand = {
                                brand_id: list.item.brand_id,
                                brand_name: list.item.brand_name,
                                slug: list.item.slug,
                                is_favorite: list.item.is_favorite,
                                image: list.item.image,
                                tat: list.item.tat,
                                coin_applicable: list.item.coin_applicable,
                                product_type: list.item.product_type,
                                color_code: list.item.color_code
                            };
                            let counter = 1;
                            let allow = (list.allow === 0) ? "cart-overlay" : "";
                            let allowMessage = (list.allow === 0) ? "This Voucher is not available right now." : "";
                            let isCoin;
                            if (list.item.coin_applicable === 1) {
                                isCoin = <IsCoinComponent
                                    isAllow={list.allow}
                                    maxGgCoin={list.item.max_possible_coin_applicable}
                                    defaultAppliedCoin={list.item.applied_coin}
                                    slug={list.item.slug}
                                    price={list.item.price}
                                    defaultQuantity={list.item.quantity}
                                    callbackRedeemCoin={this.handleRedeemCoin}
                                    userCoinBalance={this.state.userCoinBalance}
                                    callBackPrevTotalAppiledCoin={this.prevTotalAppiledCoin}
                                />;
                            }
                            return (
                                <div className="cart-list" key={i}>
                                    <Card className="col-xl-12 col-12 mb-4">
                                        <div className={"row " + allow}>
                                            <div className='col-xl-5 col-lg-6 col-md-7 col-sm-12 col-12'>
                                                <CouponCardSecond
                                                    {...this.props}
                                                    cardData={brand}
                                                    cardNumber={counter}
                                                    cardStyleType={4}
                                                />
                                            </div>
                                            <div className='col-xl-7 col-lg-6 col-md-5 col-sm-12 col-12 mt-s-3'>
                                                <div className='row'>
                                                    <p className='title col-xl-6 col-lg-6 col-md-6 col-sm-6 col-6'>{list.item.brand_name}</p>
                                                    <p className='total-price text-primary col-xl-6 col-lg-6 col-md-6 col-sm-6 col-6 text-right'>{ Helper.formatMoney(list.item.brand_total) }</p>
                                                </div>
                                                <div className='row'>
                                                    <div className='col-xl-4 col-lg-4 col-md-6 col-sm-6 col-6 mt-1'>
                                                        <p className='price '>{ Helper.formatMoney(list.item.price) }</p>
                                                    </div>
                                                    <div className='col-xl-8 col-lg-8 col-md-6 col-sm-6 col-6'>
                                                        <ChangeableQuantityComponent
                                                            isAllow={list.allow}
                                                            className="bp3-increase-small"
                                                            getQuantity={this.handleUpdateCart}
                                                            defaultQuantity={list.item.quantity}
                                                            maxQuantity={list.item.max_quantity_allow}
                                                            slug={list.item.slug}
                                                            price={list.item.price}
                                                        />
                                                    </div>
                                                </div>
                                                <div className='row mt-2'>
                                                    {isCoin}
                                                </div>
                                            </div>
                                        </div>

                                        <div className="col-12 pl-1">
                                           <p className="cart-desc" 
                                           style={{fontSize: '12px', color: 'red', marginTop: '15px', marginBottom: '0px'}}>
                                           {list.item.upv_coin_msg}
                                           </p>
                                       </div>
                                       
                                        <div className='border-bottom mt-4'/>

                                        <div className='row mt-3'>
                                            <div className='col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12 m-auto'>
                                                <p className={"cart-error-message"}>{allowMessage}</p>
                                            </div>
                                            <div className='col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12 m-auto'>
                                                <AnchorButton minimal={true} intent={Intent.DANGER}
                                                              className=' pull-right cart-remove-button'
                                                    //onClick={() => this.handleRemoveCart(list.item.slug, list.item.price)}
                                                              onClick={() => this.handleRemoveCart(list.item.slug, list.item.price)}
                                                >
                                                    <b>REMOVE</b>
                                                </AnchorButton>
                                            </div>
                                        </div>
                                    </Card>
                                </div>
                            )
                        })
                }
            </React.Fragment>
        );
    }
}
