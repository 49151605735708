import React from 'react';
import {Dialog, Classes, Button, Intent, Position, Toaster} from "@blueprintjs/core";
import {ssoInstance} from "gg-react-utilities";
import Async from 'react-select/async';
import Config from '../../core/Config';
import PlacesAutocomplete, {geocodeByAddress, getLatLng} from 'react-places-autocomplete';
import pin from '../../core/images/svg/pin.svg';
import {Auth} from "gg-react-utilities";

export default class AddressDialog extends React.Component {
    constructor(props) {
        super(props);

        this.state = this.getInitialState();
    }

    getInitialState = () => {
        return {
            hasStreetError: false,
            hasStreetErrorMessage: '',

            hasLandmarkError: false,
            hasLandmarkErrorMessage: '',

            hasAreaError: false,
            hasAreaErrorMessage: '',

            hasCityError: false,
            hasCityErrorMessage: '',

            hasStateError: false,
            hasStateErrorMessage: '',

            hasPincodeError: false,
            hasPincodeErrorMessage: '',

            hasAddressTypeError: false,
            hasAddressTypeErrorMessage: '',

            add_id: null,
            street: null,
            landmark: null,
            area: null,
            latitude: null,
            longitude: null,
            places_id: null,
            city: null,
            state: null,
            address_type: null,
            is_default: '0',
            pincode: 'Pincode'
        };
    };

    componentWillReceiveProps(nextProps) {
        this.setState(nextProps.dialogData, () => {
            if (nextProps.dialogData === {}) {
                this.getInitialState();
            }
        });
    }

    closeDialog = () => {
        this.setState(this.getInitialState(), this.props.closeDialog());
    };

    validateForm = (e) => {
        e.preventDefault();

        if (this.state.state === undefined) {
            this.setState({hasStateErrorMessage: "State field is required", hasStateError: true})
        } else {
            this.setState({hasStateErrorMessage: "", hasStateError: false})
        }

        if (this.state.city === undefined) {
            this.setState({hasCityErrorMessage: "City field is required", hasCityError: true})
        } else {
            this.setState({hasCityErrorMessage: "", hasCityError: false})
        }

        if (this.state.street === undefined) {
            this.setState({hasStreetErrorMessage: "Street field is required", hasStreetError: true})
        } else {
            this.setState({hasStreetErrorMessage: "", hasStreetError: false})
        }

        if (this.state.landmark === undefined) {
            this.setState({hasLandmarkErrorMessage: "Landmark field is required", hasLandmarkError: true})
        } else {
            this.setState({hasLandmarkErrorMessage: "", hasLandmarkError: false})
        }

        if (this.state.area === undefined) {
            this.setState({hasAreaErrorMessage: "Area field is required", hasAreaError: true})
        } else {
            this.setState({hasAreaErrorMessage: "", hasAreaError: false})
        }

        if (this.state.pincode === undefined) {
            this.setState({hasPincodeErrorMessage: "Pincode field is required", hasPincodeError: true})
        } else {
            this.setState({hasPincodeErrorMessage: "", hasPincodeError: false})
        }

        if (this.state.address_type === undefined) {
            this.setState({hasAddressTypeErrorMessage: "Address Type field is required", hasAddressTypeError: true})
        } else {
            this.setState({hasAddressTypeErrorMessage: "", hasAddressTypeError: false})
        }

        setTimeout(() => {
            if (!this.state.hasLandmarkError &&
                !this.state.hasStreetError &&
                !this.state.hasAreaError &&
                !this.state.hasCityError &&
                !this.state.hasStateError &&
                !this.state.hasAddressTypeError &&
                !this.state.hasPincodeError) {

                this.setState({isAreaValidationError: false});

                let addressData = {
                    street: this.state.street,
                    landmark: this.state.landmark,
                    area: this.state.area,
                    latitude: this.state.latitude,
                    longitude: this.state.longitude,
                    places_id: this.state.places_id,
                    city: this.state.city,
                    state: this.state.state,
                    address_type: this.state.address_type,
                    is_default: this.state.is_default.toString(),
                    pincode: this.state.pincode
                };

                this.saveAddress(addressData, this.state.add_id)
            } else {
            }
        }, 5);
    };

    handleChange = area => {
        this.setState({area});
    };

    getOptions = (input) => {
        if (!input) {
            return [];
        }
        if (input.length < 3) {
            return [];
        }

        const ROOT_URL = Config.APP_ACCOUNT_API_URL;
        const SEARCH_PINCODE_API = 'api/v1.0.2/pincodes/';

        return ssoInstance.get(`${ROOT_URL}${SEARCH_PINCODE_API}${input}`)
            .then(response => {
                if (response && response.data && response.data.data) {
                    return response.data.data;
                }
                return [];
            })
            .catch(error => {
                return [];
            });
    };

    onChange = (pincode) => {
        if (!(pincode === '' || pincode === null || pincode === undefined)) {
            this.setState({
                pincode: pincode.pincode,
            });
        }
    };

    saveAddress = (addressData) => {
        if (Auth.isLoggedIn()) {
            const ROOT_URL = Config.APP_ACCOUNT_API_URL;
            const SAVE_ADDRESS_API = 'api/v1.0.2/user/' + Auth.user().user_id + '/address';
            return ssoInstance({
                baseURL: `${ROOT_URL}`,
                url: `${SAVE_ADDRESS_API}`,
                method: 'post',
                data: addressData
            }).then(res => {
                const result = res.data;
                Toaster.create({
                    position: Position.TOP,
                }).show({message: result.message, intent: Intent.SUCCESS})
                this.props.callbackAddressSave(true);
                this.closeDialog();
            }).catch(error => {
                /*(error.response.data.error instanceof Array) && error.response.data.error.map((message, key) => {
                    // toast.error(message, {
                    //     position: toast.POSITION.TOP_CENTER
                    // });
                })*/
            }).then(() => {
                this.setState({
                    showLoader: false,
                });
            });
        }
    };

    handleSelect = area => {
        geocodeByAddress(area)
            .then(results => getLatLng(results[0]))
            .then(latLng => {
               /* let areaSplit = "";
                let areaName = "";
                let cityName = "";
                let stateName = "";
                areaSplit = _.split(area, ',');
                areaName = _.join( _.dropRight(areaSplit, 3), ", ");
                cityName = _.nth(areaSplit, -3);
                stateName = _.nth(areaSplit, -2);*/

                this.setState({
                    latitude: latLng.lat,
                    longitude: latLng.lng,
                    places_id: (latLng.places_id !== undefined) ? latLng.places_id : '',
                    area: area,
                    //city: cityName,
                    //state: stateName,
                    hasAreaError: false,
                    isAreaValidationError: false,
                    hasAreaErrorMessage: '',
                })
            })
            .catch(error => console.error('Error', error));
    };

    getOptionValue = (option) => option.pincode;

    getOptionLabel = (option) => option.pincode;

    render() {
        let defaultPincodeValue = {pincode: this.state.pincode};
        return (
            <Dialog
                isOpen={this.props.dialogState}
                onClose={this.closeDialog}
                canEscapeKeyClose={false}
                canOutsideClickClose={false}
                title={this.props.dialogTitle}
                className="bp3-dialog-large address-dialog">
                <div className={Classes.DIALOG_BODY}>
                    <form>
                        <div className="row">
                            <div className="col-12 mt-1">
                                <div className="bp3-form-group .modifier">
                                    <div className="bp3-form-content">
                                        <div className="bp3-input-group">
                                            <input type="text" placeholder="Street"
                                                   value={(this.state.street != null) ? this.state.street : ''}
                                                   onChange={(e) => {
                                                       this.setState({
                                                           street: e.target.value
                                                       });
                                                   }}
                                                   className={(this.state.hasStreetError) ? 'bp3-input border-danger' : 'bp3-input'}/>
                                        </div>
                                        <span
                                            className="text-danger pull-left">{this.state.hasStreetErrorMessage}</span>
                                    </div>
                                </div>
                            </div>
                            <div className="col-6 mt-1">
                                <div className="bp3-form-group .modifier">
                                    <div className="bp3-form-content">
                                        <div className="bp3-input-group">
                                            <input type="text" placeholder="Landmark"
                                                   value={(this.state.landmark !== null) ? this.state.landmark : ''}
                                                   onChange={(e) => {
                                                       this.setState({
                                                           landmark: e.target.value
                                                       });
                                                   }}
                                                   className={(this.state.hasLandmarkError) ? 'bp3-input border-danger' : 'bp3-input'}/>
                                        </div>
                                        <span
                                            className="text-danger pull-left">{this.state.hasLandmarkErrorMessage}</span>
                                    </div>
                                </div>
                            </div>
                            <div className="col-6 mt-1">
                                <div className="bp3-form-group .modifier">
                                    <div className="bp3-form-content">
                                        <div className="bp3-input-group">
                                            <PlacesAutocomplete
                                                value={(this.state.area != null) ? this.state.area : ''}
                                                onChange={this.handleChange}
                                                onSelect={this.handleSelect}
                                            >
                                                {({getInputProps, suggestions, getSuggestionItemProps, loading}) => (
                                                    <div>
                                                        <input
                                                            {...getInputProps({
                                                                placeholder: 'Search your location',
                                                                className: 'bp3-input hidden-location ' + ((this.state.hasCityError === true) ? 'border-danger' : ''),
                                                            })}
                                                        />
                                                        <span
                                                            className="text-danger pull-left">{this.state.hasAreaErrorMessage}</span>
                                                        <div className="autocomplete-dropdown-container">
                                                            {loading && <div>Loading...</div>}
                                                            {suggestions.map(suggestion => {
                                                                const className = suggestion.active
                                                                    ? 'suggestion-item--active'
                                                                    : 'suggestion-item';
                                                                const style = suggestion.active
                                                                    ? {
                                                                        backgroundColor: '#fafafa',
                                                                        cursor: 'pointer'
                                                                    }
                                                                    : {
                                                                        backgroundColor: '#ffffff',
                                                                        cursor: 'pointer'
                                                                    };
                                                                return (
                                                                    <div
                                                                        {...getSuggestionItemProps(suggestion, {
                                                                            className,
                                                                            style,
                                                                        })}
                                                                    >
                                                                            <span><img alt={"pin"} src={pin}/>
                                                                                {suggestion.description}</span>
                                                                    </div>
                                                                );
                                                            })}
                                                        </div>
                                                    </div>
                                                )}
                                            </PlacesAutocomplete>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-6 mt-1">
                                <div className="bp3-form-group .modifier">
                                    <div className="bp3-form-content">
                                        <div className="bp3-input-group">
                                            <input type="text" placeholder="City"
                                                   value={(this.state.city !== null) ? this.state.city : ''}
                                                   onChange={(e) => {
                                                       this.setState({
                                                           city: e.target.value
                                                       });
                                                   }}
                                                   className={(this.state.hasCityError) ? 'bp3-input border-danger' : 'bp3-input'}/>
                                        </div>
                                        <span className="text-danger pull-left">{this.state.hasCityErrorMessage}</span>
                                    </div>
                                </div>
                            </div>
                            <div className="col-6 mt-1">
                                <div className="bp3-form-group .modifier">
                                    <div className="bp3-form-content">
                                        <div className="bp3-input-group">
                                            <input type="text" placeholder="State"
                                                   value={(this.state.state !== null) ? this.state.state : ''}
                                                   onChange={(e) => {
                                                       this.setState({
                                                           state: e.target.value
                                                       });
                                                   }}
                                                   className={(this.state.hasStateError) ? 'bp3-input border-danger' : 'bp3-input'}/>
                                        </div>
                                        <span className="text-danger pull-left">{this.state.hasStateErrorMessage}</span>
                                    </div>
                                </div>
                            </div>
                            <div className="col-6 mt-1">
                                <div className="bp3-form-group .modifier">
                                    <div className="bp3-form-content">
                                        <div className="bp3-input-group">
                                            <Async
                                                name="pincode"
                                                onChange={(value) => this.onChange(value)}
                                                defaultValue={defaultPincodeValue}
                                                loadOptions={this.getOptions}
                                                placeholder={'Select Pincode'}
                                                getOptionValue={this.getOptionValue}
                                                getOptionLabel={this.getOptionLabel}
                                                className={(this.state.hasPincodeError === true) ? 'pincode-border-danger' : ''}
                                            />
                                            <span
                                                className="text-danger pull-left">{this.state.hasPincodeErrorMessage}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-6 mt-1">
                                <div className="bp3-form-group .modifier">
                                    <div className="bp3-form-content">
                                        <div className="bp3-input-group bp3-intent-danger">
                                            <div className="bp3-select w-100">
                                                <select
                                                    value={(this.state.address_type != null) ? this.state.address_type : ''}
                                                    onChange={(e) => {
                                                        this.setState({
                                                            address_type: e.target.value
                                                        });
                                                    }}>
                                                    <option value="">Select Address Type</option>
                                                    <option value="HOME">HOME</option>
                                                    <option value="WORK">WORK
                                                    </option>
                                                </select>
                                            </div>
                                            <span
                                                className="text-danger pull-left">{this.state.hasAddressTypeErrorMessage}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6 col-sm-12 col-12 mt-1">
                                <div className="bp3-form-group .modifier">
                                    <div className="bp3-form-content">
                                        <div className="bp3-input-group">
                                            <label className="bp3-control bp3-checkbox">
                                                <input type="checkbox"
                                                       onChange={(e) => {
                                                           this.setState({
                                                               is_default: (this.state.is_default === '1' ? '0' : '1')
                                                           });
                                                       }}
                                                       checked={(this.state.is_default === '1') ? true : false}/>
                                                <span className="bp3-control-indicator"></span>
                                                set as default Address?
                                            </label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 mt-1">
                                <div className="pull-right">
                                    <Button intent={Intent.PRIMARY} className="mr-2"
                                            onClick={(e) => this.validateForm(e)}>Save Address</Button>
                                    <Button intent={Intent.NONE} className="bp3-intent- bp3-active"
                                            onClick={this.closeDialog}>Close</Button>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </Dialog>
        );
    }
}
