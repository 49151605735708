import React from 'react';
import KeyboardArrowLeftRounded from '@material-ui/icons/KeyboardArrowLeftRounded';
import { Link } from "react-router-dom";
import { withRouter } from 'react-router-dom';
import { getDocumentTitle } from "../utils/Helper";
import CoinsImage from "../images/gg_coins.png";
import VocalForLocalImage from "../images/coin_voucher.png"
import { AnchorButton, Dialog, Intent, Position, Spinner, Toaster } from "@blueprintjs/core";
import YouTube from 'react-youtube';
import Config from "../Config";

const youtubeOptions = {
    height: '390',
    width: '100%',
    playerVars: {
        autoplay: 0,
        controls: 0,
        loop: 0
    }
};

class TopBar extends React.Component {
    constructor(props) {
        super(props);
        ////document.title = getDocumentTitle(props.title ? props.title : '');
        document.title = props.title ? props.title : '';

        let metaData = document.getElementsByTagName("meta");

        metaData['description'].content = props.description ? props.description : '';
        metaData['keywords'].content = props.keywords ? props.keywords : '';

        this.state = {
            breadcrumbs: props.breadcrumbs ? props.breadcrumbs : null,
            home: [
                // {
                //     to: "/order-history",
                //     label: "Order History"
                // }
                /*,
                {
                    to: "video",
                    label: "Video Tutorial"
                }*/
            ],
            brand: [
                {
                    to: "/order-history",
                    label: "Order History"
                }
                /*,
                {
                    to: "video",
                    label: "Video Tutorial"
                }*/
            ],
            vouchers: [
                {
                    to: "/voucher-history",
                    label: "Order History"
                }
            ],
            cart: [
                {
                    to: "isGGCoin",
                    label: props.isGGCoin ? props.isGGCoin : 0
                }
            ],
            is_dialog_open: false
        }
    };

    componentWillReceiveProps(nextProps) {

        document.title = getDocumentTitle(nextProps.title ? nextProps.title : '');

        this.setState({
            breadcrumbs: nextProps.breadcrumbs,
            cart: [
                {
                    to: "isGGCoin",
                    label: nextProps.isGGCoin ? nextProps.isGGCoin : 0
                }
            ]
        })
    }

    videoDialog = () => {
        return (
            <React.Fragment>
                <Dialog
                    onClose={this.handleClose}
                    isOpen={this.state.is_dialog_open}
                    lazy={false}
                    className="bp3-dialog-large video-dialog"
                >
                    <div className="video-loading">
                        <Spinner intent={Intent.PRIMARY} size={50} />
                    </div>
                    <div className="video-box">
                        <YouTube
                            className={"youtube-video-player"}
                            videoId={Config.YOUTUBE_PLAYER_ID}
                            opts={youtubeOptions}
                            onReady={this._onReady}
                        />
                    </div>
                </Dialog>
            </React.Fragment>
        )
    };

    handleOpen = () => {
        this.setState({ 'is_dialog_open': true })
    };

    handleClose = () => {
        this.setState({ 'is_dialog_open': false })
    };

    _onReady(event) {
        // access to player in all event handlers via event.target
        event.target.pauseVideo();
    }

    render() {
        let firstBreadcrumbs = "";
        let path = this.props.match.path;
        if (this.state.breadcrumbs === null) firstBreadcrumbs = "bold";

        return (
            <React.Fragment>
                <div className="top-bar">

                    <div className="col-12">
                        <div className="row">
                            <div
                                className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12 d-flex align-items-center justify-content-start">
                                <div className="row">
                                   <ul className="bp3-breadcrumbs mb-breadcrumbs">
                                        {

                                            path === "/brand/:slug" || path === "/vouchers" || path === "/ggvoucher" || path === "/order-history" || path === "/voucher-history" || path === "/gg-order-history" || this.props.isCoinsVoucher ?
                                                <li>
                                                    <span className="back-icon"
                                                        onClick={() => this.props.history.goBack()}>
                                                        <KeyboardArrowLeftRounded />
                                                    </span>
                                                </li> : ""
                                        }

                                        {
                                            this.props.isCoinsVoucher && path !== '/voucher/:slug' ?
                                                <li>
                                                    <h1><Link className={"bp3-breadcrumbs deailsPageIcon " + firstBreadcrumbs}
                                                        to="/vouchers">Coin Vouchers</Link></h1></li>
                                                :
                                                ""
                                        }

                                        {
                                            this.state.breadcrumbs ?
                                                this.state.breadcrumbs.length ?
                                                    this.state.breadcrumbs.map((nav, key) => {
                                                        let lastBreadcrumbs = "";
                                                        if (this.state.breadcrumbs.length === key + 1) lastBreadcrumbs = "bold";
                                                        nav.label = nav.label.split(' | ');
                                                        if (nav.label.length >= 1) {
                                                           nav.label = nav.label[0];
                                                            
                                                        }
                                                        
                                
                                                        return (
                                                            <li key={key}>
                                                                <h4 className={"bp3-breadcrumbs bradscrubd_h4 " + lastBreadcrumbs}
                                                                    to={nav.to}>
                                                                    {nav.label}
                                                                </h4>
                                                            </li>
                                                        )
                                                    })
                                                    : ""
                                                : ""
                                        }
                                    </ul>
                                </div>
                            </div>
                            <div
                                className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12 px-s-4 d-flex align-items-center justify-content-end">
                                <div className="row pull-right">
                                    {
                                        this.props.links ?
                                            this.state[this.props.links].length ?
                                                this.state[this.props.links].map((link, key) => {
                                                    if (link.to === "isGGCoin") {
                                                        return <Link className="top-links" to="#"
                                                            key={key}>{"GG Coins: " + link.label}</Link>
                                                    } else if (link.to === "video") {
                                                        return (
                                                            <AnchorButton
                                                                small={true}
                                                                className="top-links"
                                                                key={key}
                                                                onClick={this.handleOpen}
                                                                text={link.label}
                                                            />
                                                        )
                                                    } else {
                                                        return <Link className="top-links mr-5" to={link.to}
                                                            key={key}>{link.label}</Link>;
                                                    }
                                                })
                                                : ""
                                            : ""
                                    }

                                    {
                                        this.props.ggUserCoins ?
                                            <span className="top-without-links pull-right">
                                                Available GG coins<img src={CoinsImage} className="coinsImage" alt={"coin"}
                                                    width="22px" /> :
                                                {
                                                    this.props.isLoggedIn ?
                                                        this.props.userAvailableCoins
                                                        :
                                                        <div className="bp3-skeleton" style={{
                                                            width: "100px",
                                                            height: "19px",
                                                            margin: "5px auto",
                                                            float: "right"
                                                        }}>&nbsp;</div>
                                                }
                                            </span>
                                            :
                                            ''
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                    {this.props.tabManu !== "coinVoucher" ?
                        "" :
                        <div className="row">
                            <div className="col-md-12 col-sm-12 col-xs-12">
                                <img src={VocalForLocalImage} className="w-100 mb-5" />
                            </div>
                        </div>}

                    {
                        this.props.tabManu ?
                            <div className="col-12 mt-4 p-0">
                                <div className="bp3-tabs">
                                    <ul className="bp3-tab-list bp3-large">
                                        <li className="bp3-tab" role="tab"
                                            aria-selected={(this.props.tabManu === "megaBrands") ? true : false}>{this.props.tabManu !== "megaBrands" ?
                                                <Link to="/brand">Mega Brands</Link> : "Mega Brands"}</li>
                                        {/*<li className="bp3-tab" role="tab"
                                            aria-selected={(this.props.tabManu === "coinVoucher") ? true : false}>{this.props.tabManu !== "coinVoucher" ?
                                                <Link to="/vouchers" >Coin Vouchers</Link> : "Coin Vouchers"}</li>*/}
                                    </ul>
                                </div>
                            </div>
                            : ''
                    }

                    {
                        this.props.tabOrderHistory ?
                            <div className="col-12 mt-4">
                                <div className="bp3-tabs">
                                    <ul className="bp3-tab-list bp3-large">
                                        <li className="bp3-tab" role="tab"
                                            aria-selected={(this.props.tabOrderHistory === "megaBrandsOrderHistory") ? true : false}>{this.props.tabOrderHistory !== "megaBrandsOrderHistory" ?
                                                <Link to="/order-history">Mega Brands</Link> : "Mega Brands"}</li>
                                        <li className="bp3-tab" role="tab"
                                            aria-selected={(this.props.tabOrderHistory === "coinVoucherOrderHistory") ? true : false}>{this.props.tabOrderHistory !== "coinVoucherOrderHistory" ?
                                                <Link to="/voucher-history" >Coin Vouchers</Link> : "Coin Vouchers"}</li>
                                        <li className="bp3-tab" role="tab"
                                            aria-selected={(this.props.tabOrderHistory === "ggVoucherOrderHistory") ? true : false}>{this.props.tabOrderHistory !== "ggVoucherOrderHistory" ?
                                                <Link to="/gg-order-history">GG Vouchers</Link> : "GG Vouchers"}</li>
                                    </ul>
                                </div>
                            </div>
                            : ''
                    }
                </div>
                {
                    this.props.isvideoDialog ?
                        this.videoDialog()
                        : ""
                }
            </React.Fragment>
        );
    }
}

export default withRouter(TopBar);
