import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
//import registerServiceWorker from './registerServiceWorker';
import {PackageConfigure} from 'gg-react-utilities';
import {packageConfigure} from "./core/Config";
import { unregister as unregisterServiceWorker } from './registerServiceWorker';

ReactDOM.render(
    <React.Fragment>
        <PackageConfigure {...packageConfigure} />
        <App/>
    </React.Fragment>,
    document.getElementById('root'));
//registerServiceWorker();
unregisterServiceWorker();