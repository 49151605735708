import React, {Component} from 'react';
import { Button, Classes, Dialog, Intent, Tooltip } from "@blueprintjs/core";

export default class DetailDialog extends Component{

    constructor(props){
        super(props);

        this.state={
            autoFocus: true,
            canEscapeKeyClose: true,
            canOutsideClickClose: true,
            enforceFocus: true,
            isOpen: false,
            usePortal: true,
        }
    }

    handleClose=()=>{
        this.setState({
            isOpen: false
        })
    }

    handleOpen=()=>{
        this.setState({
            isOpen: true
        })
    }

    componentWillReceiveProps(nextprops){
        if(nextprops.isOpen!==undefined){
            this.setState({
                isOpen: nextprops.isOpen
            });
        }

    }

    render(){
        return(
            <React.Fragment>
                {
                    this.props.toggle?
                        this.props.toggle:
                        <Button small={true} intent="primary" text="View"
                                className="btn-view mr-2"
                                onClick={() => this.handleOpen()}/>
                }
                <Dialog
                    isOpen={this.state.isOpen}
                    title={this.props.title}
                    className={this.props.className?this.props.className:''}
                    icon={this.props.icon?this.props.icon:"info-sign"}
                    onClose={this.handleClose}
                    {...this.props}
                >
                    <div className={Classes.DIALOG_BODY+'p-1 m-0'} >
                        {this.props.children}
                    </div>
                    {
                        this.props.footer?
                            <div className={Classes.DIALOG_FOOTER}>
                                <div className={Classes.DIALOG_FOOTER_ACTIONS}>
                                    {this.props.footer}
                                </div>
                            </div>
                            :
                            <div className={Classes.DIALOG_FOOTER}>
                                <div className={Classes.DIALOG_FOOTER_ACTIONS}>
                                    <Tooltip content="This button is hooked up to close the dialog.">
                                        <Button intent={Intent.DANGER} onClick={this.handleClose}>Close</Button>
                                    </Tooltip>
                                </div>
                            </div>
                    }
                </Dialog>
            </React.Fragment>
        );
    }

}

