import React from 'react';
import SideBar from "./SideBar";
import Lists from "./Lists";
import { Alignment, Button, ControlGroup, InputGroup, Intent } from "@blueprintjs/core";
import LoadingList from "./LoadingList";
import EmptyState from "../../core/components/EmptyState";
import { Link } from "react-router-dom";
import _ from "lodash";
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import TopBar from "../../core/components/TopBar";

const responsive = {
    superLargeDesktop: {
        // the naming can be any, depends on you.
        breakpoint: { max: 4000, min: 3000 },
        items: 2
    },
    desktop: {
        breakpoint: { max: 3000, min: 1024 },
        items: 2
    },
    tablet: {
        breakpoint: { max: 1024, min: 464 },
        items: 1
    },
    mobile: {
        breakpoint: { max: 464, min: 0 },
        items: 1
    }
};

export default class Brands extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            loadingCategory: true,
            loadingBrand: true,
            hide_load_more: false,
            sidebar_data: [],
            brand_data: [],
            slider_data: [],
            brand_search_status: true,
            api_brand_next_url: true,
            api_brand_name: "",
            api_brand_Voucher_type: [],
            api_brand_category: [],
            api_brand_tat: [],
            api_brand_coin: 0,
            btn_loading: false
        }
    }

    componentDidMount() {
        this.props.unMountBrandPage();
        this.props.getSliders();
        this.props.getCategories();
        this.props.getBrands();
    }

    componentWillReceiveProps(nextProps) {
        if (this.state.api_brand_next_url === false) {
            this.setState({
                brand_data: nextProps.BrandsData.data
            }, () => {
                this.setState({ loadingBrand: false })
            })
        } else {
            this.setState({
                brand_data: this.state.brand_data.concat(nextProps.BrandsData.data)
            }, () => {
                this.setState({ loadingBrand: false })
            })
        }

        this.setState({
            slider_data: nextProps.SliderData.data,
            sidebar_data: nextProps.CategoriesData,
            loadingCategory: false,
            api_brand_next_url: nextProps.BrandsData.next_page_url,
            btn_loading: false
        });

        if (nextProps.BrandsData.next_page_url === null) {
            this.setState({ hide_load_more: true })
        }
    }

    handleBrandDataUpdate = () => {
        let url = this.state.api_brand_next_url;
        let brand_name = this.state.api_brand_name;
        let type = this.state.api_brand_Voucher_type;
        let category = this.state.api_brand_category;
        let tat = this.state.api_brand_tat;
        let coin_applicable = this.state.api_brand_coin;

        this.setState({ btn_loading: true });

        if (url === null) {
            this.setState({ hide_load_more: true });
            return true;
        }

        this.setState({ hide_load_more: false });
        this.props.getBrands(url, brand_name, type, category, tat, coin_applicable);
    };

    handleBrandSearchOnChange = (e) => {
        let input = e.target.value;

        if (input.length === 0) {
            this.handleBrandSearchOnClick();
        }

        let checkFirstCharSpace = /^[a-zA-Z-,]+(\s{0,1}[a-zA-Z-, ])*$/;

        if (input.match(checkFirstCharSpace) === null) {
            this.setState({ api_brand_name: "" });
            return false;
        }

        this.setState({
            api_brand_name: input,
        }, () => {
            /*if (input.length > 2) {
                this.handleBrandSearchOnClick();
            }*/
        });
    };

    handleBrandSearchOnkeyPress = (e) => {
        if (e.keyCode === 13 && this.state.brand_search_status === true) {

            this.setState({ brand_search_status: false })
            this.handleBrandSearchOnClick();
        }
    }

    handleBrandSearchOnClick = () => {
        this.setState({ api_brand_next_url: false, brand_search_status: true }, () => {
            this.handleBrandDataUpdate()
        });
    };

    handleSideBarFilterClick = (isChecked, type, value) => {
        this.setState({ loadingBrand: true });

        let checked = "";
        if (type === "category") {
            checked = this.state.api_brand_category;
            if (isChecked === false) {
                _.remove(checked, function (v) {
                    return v === value;
                });
            } else {
                checked.push(value)
            }
            this.setState({ api_brand_category: checked })
        }

        if (type === "coin") {
            if (isChecked === false) {
                this.setState({ api_brand_coin: "" })
            } else {
                this.setState({ api_brand_coin: value })
            }
        }

        if (type === "voucher") {
            checked = this.state.api_brand_Voucher_type;
            if (isChecked === false) {
                _.remove(checked, function (v) {
                    return v === value;
                });
            } else {
                checked.push(value)
            }
            this.setState({ api_brand_Voucher_type: checked })
        }

        if (type === "delivery") {
            checked = this.state.api_brand_tat;
            if (isChecked === false) {
                _.remove(checked, function (v) {
                    return v === value;
                });
            } else {
                checked.push(value)
            }
            this.setState({ api_brand_tat: checked })
        }

        this.handleBrandSearchOnClick();
    };

    render() {
        let slider = this.state.slider_data
        if (this.state.sidebar_data.length === 0 &&
            this.state.brand_data.length === 0 &&
            this.state.loadingCategory === false &&
            this.state.loadingBrand === false
        ) {
            return (
                <React.Fragment>
                    <EmptyState stateType={"brand"} />
                </React.Fragment>
            );
        } else {
            return (
                <React.Fragment>
                    <div className="brand-details col-12 mb-5 ">
                        <div className="row">
                            <div className="col-md-3">
                                <SideBar
                                    loading={this.state.loadingCategory}
                                    sidebar={this.state.sidebar_data}
                                    onSideBarFilter={this.handleSideBarFilterClick} />
                            </div>
                            <div className="col-md-9">
                                {
                                    this.state.loadingBrand ? <LoadingList /> :
                                        <div className="brand-items mb-5">
                                            <div className="row">
                                                <div className="col-lg-12 col-12 text-center megabrand-side">
                                                    <Carousel responsive={responsive} showDots={true} arrows={false} autoPlay={true}
                                                        autoPlaySpeed={2000}
                                                        infinite={true}>
                                                        {slider != undefined && slider.map((res) =>
                                                            <div className="mb-4">
                                                                <Link to={"/brand/" + res.slug}>
                                                                    <img className="w-100" src={res.image} alt="Deals" />
                                                                </Link>
                                                            </div>)}
                                                    </Carousel>
                                                </div>
                                                <TopBar title="Mega Brands | Cashback Voucher | Global Garner"
                                                    description="Buy your favorite brand from Global Garner & get cashback voucher on your favorite brands at Global Garner, enjoy shopping with Global Garner"
                                                    keywords="Myntra , Myntra Online Shopping, Pantaloons, Fast track, Makemytrip, big basket, bookmyshow, Big Bazzar, Flipkart,flipkart Sale, flipkart online shopping flipkartoffers, Cashback voucher, gift vouchers, discount gift cards, online cashback vouchers, deals"
                                                    links="home"
                                                    isDashboard={true}
                                                    tabManu={"megaBrands"}
                                                    isvideoDialog={true} />
                                                <div className="search-box col-12">
                                                    <ControlGroup fill={true} vertical={false} className="brand-search">
                                                        <InputGroup value={this.state.api_brand_name}
                                                            onChange={this.handleBrandSearchOnChange}
                                                            onKeyDown={this.handleBrandSearchOnkeyPress}
                                                            placeholder="Search your favourite Mega Brand Voucher"
                                                            className="bs-input-search" />
                                                        <Button onClick={this.handleBrandSearchOnClick} icon="search"
                                                            className="bs-input-icon" />
                                                    </ControlGroup>
                                                </div>
                                                <div className="bs-list-items col-12 p-0">
                                                    <div className="bs-list-item">
                                                        <div className="row p-s-2">
                                                            <Lists {...this.props}
                                                                hideLoadMore={this.state.hide_load_more}
                                                                brands={this.state.brand_data} />
                                                        </div>
                                                        <div className="col-12 bs-list-more">
                                                            {
                                                                this.state.hide_load_more ? '' :
                                                                    <React.Fragment>
                                                                        <Button
                                                                            minimal={true}
                                                                            className="bp3-small bp3-secondary"
                                                                            loading={this.state.btn_loading}
                                                                            alignText={Alignment.CENTER}
                                                                            intent={Intent.PRIMARY}
                                                                            text={"Load More"}
                                                                            onClick={this.handleBrandDataUpdate}
                                                                        />
                                                                    </React.Fragment>
                                                            }
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                }
                            </div>
                        </div>
                    </div>
                </React.Fragment>
            );
        }
    }
}
