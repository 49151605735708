import React from 'react';
import {getDocumentTitle} from "../core/utils/Helper";
import {Error} from "gg-react-utilities";
import {Dialog} from "@blueprintjs/core";

export default class index extends React.Component {

    render() {
        try {
            let slug = this.props.match.params.status ? this.props.match.params.status : "";

            let query = new URLSearchParams(this.props.location.search ? this.props.location.search : "");

            document.title = getDocumentTitle(slug);

            let title = "";
            let message = "";
            //let link = "";
            let image = "";
            let redirectLink = "";

            if (query.has('upi')) {

                message = query.get('message');
                title = query.get('title');
                if (slug === "success") {
                    image = require('../core/images/Payment/success.svg');
                }
                if (slug === "failed") {
                    image = require('../core/images/Payment/cancel.svg');
                }
                if (slug === "awaiting") {
                    image = require('../core/images/Payment/error.svg');
                }
                redirectLink = "/order-history";

            } else if (query.has('isVoucher')) {
                if (slug === "success") {
                    if(query.has('orderid')) {
                        let orderId = query.get('orderid');
                        message = 'Order # ' + orderId + ' has been placed successfully and an invoice has been emailed to you.';
                    }else{
                        message = query.get('message');
                    }
                    title = "Your Order is Successfully Placed";

                    //link = <Link to="/voucher-history" className="pb-4">View Order History</Link>;
                    image = require('../core/images/Payment/success.svg');
                }
                if (slug === "failed") {
                    title = query.get('message');
                    message = '';
                    //link = <Link to="/vouchers=" className="pb-4">Go To Home</Link>;
                    image = require('../core/images/Payment/cancel.svg');
                }

                redirectLink = "/voucher-history";

            } else {
                let orderId = query.get('orderid');
                if (slug === "success") {
                    title = "Your Order is successfully placed";
                    message = "Order # " + orderId + " has been placed successfully and an invoice has been emailed to you.";
                    //link = <Link to="/order-history" className="pb-4">View Order History</Link>;
                    image = require('../core/images/Payment/success.svg');
                }
                if (slug === "failed") {
                    title = "Your payment is failed";
                    message = "Order # " + orderId + " You might receive a message from your bank that the payment could not be processed.";
                    //link = <Link to="/" className="pb-4">Go To Home</Link>;
                    image = require('../core/images/Payment/cancel.svg');
                }
                if (slug === "paymentfailed") {
                    title = "Your payment is failed";
                    message = "Order # " + orderId + " You might receive a message from your bank that the payment could not be processed.";
                    //link = <Link to="/" className="pb-4">Go To Home</Link>;
                    image = require('../core/images/Payment/cancel.svg');
                }
                if (slug === "orderfailed") {
                    title = "Your Order is failed";
                    message = "Order # " + orderId + " your payment will be refund shortly.";
                    //link = <Link to="/" className="pb-4">Go To Home</Link>;
                    image = require('../core/images/Payment/cancel.svg');
                }
                if (slug === "orderprocess") {
                    title = "Your Order is successfully placed";
                    message = "Order # " + orderId + " has been placed successfully your voucher number will genareted shortly.";
                    //link = <Link to="/order-history" className="pb-4">View Order History</Link>;
                    image = require('../core/images/Payment/success.svg');
                }
                if (slug === "ordersuccess") {
                    title = "Your Order is successfully placed";
                    message = "Order # " + orderId + " has been placed successfully and an invoice has been emailed to you.";
                    //link = <Link to="/order-history" className="pb-4">View Order History</Link>;
                    image = require('../core/images/Payment/success.svg');
                }
                if (slug === "orderpending") {
                    title = "Your Order is successfully placed";
                    message = "Order # " + orderId + " has been placed successfully and voucher detail updated within 24hrs.";
                    //link = <Link to="/order-history" className="pb-4">View Order History</Link>;
                    image = require('../core/images/Payment/success.svg');
                }

                redirectLink = "/order-history";

            }

            setTimeout(() => {
                window.location.href = redirectLink;
            }, 5000);

            return (
                <React.Fragment>
                    <div className={"mh-100"}>
                        <Dialog
                            isOpen={true}
                            lazy={false}
                            className="bp3-dialog-large payment-status"
                            canOutsideClickClose={false}
                        >
                            <div className="mg-brand-payments">
                                <div className="">
                                    <div className="">
                                        <div className="">
                                            <div className="paymentWrapper">
                                                <div className="upperPaymentWrapper"></div>
                                                <div className="middlePaymentWrapper">
                                                    <div className="circleWrapper">
                                                        <div className="iconWrapper">
                                                            <img src={image} alt=""/>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-8 lowerPaymentWrapper m-auto pb-4">
                                                    <h3>{title}</h3>
                                                    <p>{message}</p>
                                                    {/*{link}*/}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Dialog>
                    </div>
                </React.Fragment>
            )
        } catch (e) {
            return (
                <Error/>
            )
        }
    }
}
