import React, {Component} from 'react';
import BillingComponent from "./BillingComponent";
import ShippingComponent from "./ShippingComponent";
import _ from "lodash";
import {Auth} from "gg-react-utilities";

export default class FormComponent extends Component {
    constructor(props) {
        super(props);

        this.state = {
            loadingData: false,
            is_default_address_open: false,
            user_address: [],
            billingName: "",
            billingEmail: "",
            billingMobile: "",
            billingAddressLine1: "",
            billingCity: "",
            billingState: "",
            billingCountry: "India",
            billingZip: "",
            receiversName: "",
            receiversEmail: "",
            shippingMobile: "",
            shippingAddressLine1: "",
            shippingCity: "",
            shippingState: "",
            shippingCountry: "India",
            shippingZip: "",
            form_validation: props.form_validation
        };
    }

    componentWillMount() {
        if (Auth.isLoggedIn()) {
            this.props.getUserAddress(Auth.user().user_id)
                .then((res) => {
                    let sources = res.data;
                    if (sources.status === true) {
                        this.setState({user_address: sources.data});
                    }
                })
                .catch((errors) => {
                });
        }
    }

    componentWillReceiveProps(nextProps) {
        this.setState({
            form_validation: nextProps.form_validation
        });

        if (nextProps.isAddressSave === true) {
            if (Auth.isLoggedIn()) {
                this.props.getUserAddress(Auth.user().user_id)
                    .then((res) => {
                        let sources = res.data;
                        if (sources.status === true) {
                            this.setState({user_address: sources.data});
                        }
                    })
                    .catch((errors) => {
                    });
            }
        }
    }

    handleBillingAddress = (billing_name, billing_email, billing_mobile, address, object_address) => {
        this.setState({
            billingName: billing_name,
            billingEmail: billing_email,
            billingMobile: billing_mobile,
            billingAddressLine1: address,
            billingCity: object_address.city,
            billingState: object_address.state,
            billingZip: object_address.pincode
        }, () => {
            let newStateData = _.omit(this.state, [
                'loadingData',
                'is_default_address_open',
                'user_address'
            ]);

            this.props.callBackFormData(newStateData)
        });
    };

    handleShippingAddress = (shipping_name, shipping_email, shipping_mobile, address, object_address) => {
        this.setState({
            receiversName: shipping_name,
            receiversEmail: shipping_email,
            shippingMobile: shipping_mobile,
            shippingAddressLine1: address,
            shippingCity: object_address.city,
            shippingState: object_address.state,
            shippingZip: object_address.pincode
        }, () => {
            let newStateData = _.omit(this.state, [
                'loadingData',
                'is_default_address_open',
                'user_address'
            ]);

            this.props.callBackFormData(newStateData);
        });
    };

    handleSameAsABilling = (boolean) => {
        if (boolean === true) {
            this.setState({
                receiversName: this.state.billingName,
                receiversEmail: this.state.billingEmail,
                shippingMobile: this.state.billingMobile,
                shippingAddressLine1: this.state.billingAddressLine1,
                shippingCity: this.state.billingCity,
                shippingState: this.state.billingState,
                shippingZip: this.state.billingZip,
            }, () => {
                let newStateData = _.omit(this.state, [
                    'loadingData',
                    'is_default_address_open',
                    'user_address'
                ]);

                this.props.callBackFormData(newStateData);
            });
        }
    };

    render() {
        let billingData = {
            billingName: this.state.billingName,
            billingEmail: this.state.billingEmail,
            billingMobile: this.state.billingMobile,
            billingAddressLine1: this.state.billingAddressLine1
        };
        return (
            <React.Fragment>
                <BillingComponent userAddress={this.state.user_address}
                                  form_validation={this.state.form_validation}
                                  callbackBillingAddress={this.handleBillingAddress}/>

                <ShippingComponent
                    sameBillingData={billingData}
                    callbackSameAsABilling={this.handleSameAsABilling}
                    userAddress={this.state.user_address}
                    form_validation={this.state.form_validation}
                    callbackShippingAddress={this.handleShippingAddress}/>

            </React.Fragment>
        );
    }
}
