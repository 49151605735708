import React, {Component} from 'react';
import {Dialog, FormGroup, InputGroup, ControlGroup, Intent, Button} from "@blueprintjs/core";
import ModalPopUpDefaultAddress from "./ModalPopUpDefaultAddress";
import {Auth} from 'gg-react-utilities';

export default class ShippingComponent extends Component {
    constructor(props) {
        super(props);

        this.state = {
            is_default_address_open: false,
            selected_address: "",
            selected_object_address: "",
            user_address: props.userAddress,
            shipping_name: "",
            shipping_email: "",
            shipping_mobile: "",
            form_validation: props.form_validation,
            sameAddress:''
        };
    }


    componentWillMount() {
        if (Auth.isLoggedIn()) {
             let firstName = Auth.user().fname !== null && Auth.user().fname !== "" ? Auth.user().fname + " " : "";
            let middleName = Auth.user().mname !== null && Auth.user().mname !== ""  ? Auth.user().mname + " " : "";
            let lastName = Auth.user().lname !== null && Auth.user().lname !== "" ? Auth.user().lname : "";

            this.setState({
                shipping_name: firstName + middleName + lastName,
                shipping_email: Auth.user().email,
                shipping_mobile: Auth.user().mobile,
            })
        }
    }

    componentWillReceiveProps(nextProps) {
        if(nextProps.userAddress.length > 0) {
            let address = localStorage.getItem('newSelectAddress')
            var selectAddress = nextProps.userAddress.filter(a => a.is_default === '1');
            let data = selectAddress[0].street + ',' + selectAddress[0].landmark + ',' + selectAddress[0].area + ',' + selectAddress[0].city + ',' + selectAddress[0].state + '-' + selectAddress[0].pincode
            localStorage.setItem('checkoutShippingAdd', data)
            localStorage.setItem('shippingCity', selectAddress[0].city)
            localStorage.setItem('shippingState', selectAddress[0].state)
            localStorage.setItem('shippingPincode', selectAddress[0].pincode)
            this.setState({
                sameAddress: address === null ? data : address,
                user_address: nextProps.userAddress,
                form_validation: nextProps.form_validation,
            })
        }
    }

    handleDefaultAddressOpen = () => {
        this.setState({'is_default_address_open': true})
    };

    handleDefaultAddressClose = () => {
        this.setState({
            selected_address: '',
            is_default_address_open: false,
            selected_object_address: ''
        }, () => {
            this.props.callbackShippingAddress(
                this.state.shipping_name,
                this.state.shipping_email,
                this.state.shipping_mobile,
                this.state.selected_address,
                this.state.selected_object_address
            );
        });
    };

    handleSelectedAddressClose = (close, address, object_address) => {
        this.setState({
            selected_address: address,
            is_default_address_open: false,
            selected_object_address: object_address
        }, () => {
            this.props.callbackShippingAddress(
                this.state.shipping_name,
                this.state.shipping_email,
                this.state.shipping_mobile,
                this.state.selected_address,
                this.state.selected_object_address
            );
        });
    };

  

    render() {
        let nameMSG = "";
        let emailMSG = "";
        let mobileMSG = "";
        let addrerssMSG = "";
        let nameClass = "";
        let emailClass = "";
        let mobileClass = "";
        let addrerssClass = "";

        if (this.state.form_validation === true) {
            if (this.state.shipping_name === "") {
                nameMSG = "The name field should be required.";
                nameClass = Intent.DANGER;
            }

            if (this.state.shipping_email === "") {
                emailMSG = "The email field should be required.";
                emailClass = Intent.DANGER;
            }

            if (this.state.shipping_mobile === "") {
                mobileMSG = "The mobile field should be required.";
                mobileClass = Intent.DANGER;
            }

            if (this.state.sameAddress === "") {
                addrerssMSG = "The address field should be required.";
                addrerssClass = Intent.DANGER;
            }

            if (typeof this.state.shipping_email !== "undefined" && this.state.shipping_email !== null && this.state.shipping_email !== "") {
                let pattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);
                if (!pattern.test(this.state.shipping_email)) {
                    emailMSG = "Please enter valid email-ID.";
                    emailClass = Intent.DANGER;
                }
            }

            if (typeof this.state.shipping_mobile !== "undefined" && this.state.shipping_mobile !== null && this.state.shipping_mobile !== "") {
                if (!this.state.shipping_mobile.match(/^[0-9]{10}$/)) {
                    mobileMSG = "Please enter valid mobile no.";
                    mobileClass = Intent.DANGER;
                }
            }
        }
        return (
            <React.Fragment>
                <div className="row mb-4">
                    <div className="col-md-12 col-12">
                        <div className="panel panel-default">
                            <div className="panel-heading d-flex">
                                <div className="col-xl-8 col-lg-8 col-md-8 col-sm-8 col-8">
                                    <div className="row">
                                        Shipping Details
                                    </div>
                                </div>

                                {/* <div className="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-4">
                                    <div className="row pull-right">
                                        <Button
                                            onClick={this.handleSameAsABilling}
                                            className={"above-address no-border"}
                                            minimal={true}
                                            intent={Intent.PRIMARY}
                                            text={"As Above"}/>
                                    </div>
                                </div> */}
                            </div>
                            <div className="panel-body">
                                <div className="row">
                                    <div className="col-xl-12 col-lg-12 col-md-12 col-12">
                                        <form className="form-horizontal">
                                            <div className="row form-data">
                                                <div className="col-md-6 col-12 text-group">
                                                    <FormGroup
                                                        helperText={nameMSG} intent={nameClass}>
                                                        <InputGroup
                                                            intent={nameClass}
                                                            placeholder="Shipping Full Name"
                                                            value={this.state.shipping_name}
                                                            onChange={(e) => {
                                                                this.setState({shipping_name: e.target.value}, () => {
                                                                    this.props.callbackShippingAddress(
                                                                        this.state.shipping_name,
                                                                        this.state.shipping_email,
                                                                        this.state.shipping_mobile,
                                                                        this.state.selected_address,
                                                                        this.state.selected_object_address
                                                                    );
                                                                });
                                                            }}
                                                        />
                                                    </FormGroup>
                                                </div>
                                                <div
                                                    className="col-md-6 col-12 text-group mt-xl-0 mt-lg-0 mt-md-0 mt-sm-4 mt-s-3">
                                                    <FormGroup
                                                        helperText={emailMSG} intent={emailClass}>
                                                        <InputGroup
                                                            intent={emailClass}
                                                            placeholder="Shipping Email"
                                                            value={this.state.shipping_email}
                                                            onChange={(e) => {    
                                                                this.setState({shipping_email: e.target.value}, () => {
                                                                    this.props.callbackShippingAddress(
                                                                        this.state.shipping_name,
                                                                        this.state.shipping_email,
                                                                        this.state.shipping_mobile,
                                                                        this.state.selected_address,
                                                                        this.state.selected_object_address
                                                                    );
                                                                });
                                                            }}
                                                        />
                                                    </FormGroup>
                                                </div>
                                                <div className="col-md-6 col-12 text-group mt-4 mt-s-3">
                                                    <FormGroup
                                                        helperText={mobileMSG} intent={mobileClass}>
                                                        <InputGroup
                                                            intent={mobileClass}
                                                            placeholder="Shipping Mobile"
                                                            value={this.state.shipping_mobile}
                                                            onChange={(e) => {
                                                                this.setState({shipping_mobile: e.target.value}, () => {
                                                                    this.props.callbackShippingAddress(
                                                                        this.state.shipping_name,
                                                                        this.state.shipping_email,
                                                                        this.state.shipping_mobile,
                                                                        this.state.selected_address,
                                                                        this.state.selected_object_address
                                                                    );
                                                                });
                                                            }}
                                                        />
                                                    </FormGroup>
                                                </div>
                                                <div className="col-md-6 col-12 text-group mt-4 mt-s-3">
                                                    <FormGroup
                                                        helperText={addrerssMSG} intent={addrerssClass}>
                                                        <ControlGroup fill={true} vertical={false}>
                                                            <InputGroup
                                                                intent={addrerssClass}
                                                                readOnly={"readOnly"}
                                                                placeholder="Shipping Address"
                                                                defaultValue={this.state.selected_address!=''?this.state.selected_address:this.state.sameAddress}
                                                                onChange={(e) => {
                                                                  
                                                                    this.setState({selected_address: e.target.value}, () => {
                                                                        this.props.callbackShippingAddress(
                                                                            this.state.shipping_name,
                                                                            this.state.shipping_email,
                                                                            this.state.shipping_mobile,
                                                                            this.state.selected_address,
                                                                            this.state.selected_object_address
                                                                        );
                                                                    });
                                                                }}
                                                            />
                                                            <Button intent={"primary"}
                                                                    className={"btn-group-icon"}
                                                                    icon="map-marker"
                                                                    onClick={this.handleDefaultAddressOpen}>&nbsp;</Button>
                                                        </ControlGroup>
                                                    </FormGroup>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Dialog
                    onClose={this.handleDefaultAddressClose}
                    title="Select Address"
                    isOpen={this.state.is_default_address_open}
                    lazy={true}
                    className="bp3-dialog-large"
                    canOutsideClickClose={false}
                >
                    <ModalPopUpDefaultAddress
                        userAddress={this.state.user_address}
                        callbackAddNewAddressCloseData={this.handleSelectedAddressClose}/>
                </Dialog>
            </React.Fragment>
        );
    }
}
