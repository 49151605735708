import {connect} from "react-redux";
import {ssoInstance} from "gg-react-utilities";
import Config from "../../core/Config";
import * as actions from "../../core/actions";
import CartComponent from '../component/CartComponent';
import {Toaster, Position, Intent} from "@blueprintjs/core";

const mapStateToProps = state => {
    return {
        CartListData: state.Carts.CartList,
        CartCountData: state.Carts.CartCount,
    }
};

const mapDispatchToProps = dispatch => {
    return {
        unMountCart: () => {
            dispatch(actions.setCartCounts([]));
            dispatch(actions.setCarts([]));
        },

        getCarts: (provider) => {
            return ssoInstance.post(Config.API_URL + "/get-cart", {provider: provider, is_upi: '1'})
                .then((res) => {
                    let sources = res.data;

                    if (sources.status === true) {
                        dispatch(actions.setCarts(sources.data));

                        ssoInstance.get(Config.API_URL + "/cart-count")
                            .then((res) => {
                                let sources = res.data;

                                if (sources.status === true)
                                    dispatch(actions.setCartCounts(sources.data));
                                else
                                    dispatch(actions.setCartCounts([]));
                            })
                            .catch((errors) => {
                            })
                    } else {
                        dispatch(actions.setCarts([]));

                        ssoInstance.get(Config.API_URL + "/cart-count")
                            .then((res) => {
                                let sources = res.data;

                                if (sources.status === true)
                                    dispatch(actions.setCartCounts(sources.data));
                                else
                                    dispatch(actions.setCartCounts([]));
                            })
                            .catch((errors) => {
                            })
                    }
                })
                .catch((errors) => {
                    if (errors.response.status !== 401) {
                        let message = "";
                        if (errors.response) {
                            message = errors.response.message ? errors.response.message : "Something went wrong!";
                        } else {
                            message = "Something went wrong!";
                        }
                        Toaster.create({
                            position: Position.TOP,
                        }).show({message: message, intent: Intent.DANGER});

                        dispatch(actions.setCarts([]));

                        ssoInstance.get(Config.API_URL + "/cart-count")
                            .then((res) => {
                                let sources = res.data;

                                if (sources.status === true)
                                    dispatch(actions.setCartCounts(sources.data));
                                else
                                    dispatch(actions.setCartCounts([]));
                            })
                            .catch((errors) => {
                            })
                    }
                })
        },

        postUpdateCart: (slug, price, quantity, applied_coin) => {
            let filterData = {
                price: price,
                slug: slug,
                quantity: quantity,
                applied_coin: applied_coin
            };
            return ssoInstance.post(Config.API_URL + "/update-cart", filterData);
        },

        postRemoveCart: (slug, price) => {
            let filterData = {
                price: price,
                slug: slug
            };
            return ssoInstance.post(Config.API_URL + "/remove-cart", filterData);
        },
        
        putBrands: (brand_id) => {
            return ssoInstance.put(Config.API_URL + "/favBrand/" + brand_id);
        }
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(CartComponent);

