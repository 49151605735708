import React,{Component} from 'react';
import VouchersContainer from './container/VoucherContainer';
import TopBar from "../core/components/TopBar";

export default class Vouchers extends Component{

    componentDidMount() {
        window.scrollTo(0, 0)
    }

    render(){

        return(

            <div className="container mg-brand-list">
                <div className="row">
                    <TopBar title="Mega Brands | Cashback Voucher | Global Garner"
                            links="vouchers"
                            isDashboard={true}
                            isCoinsVoucher={true}
                            tabManu="coinVoucher" />
                </div>

                <div className="row">
                    <VouchersContainer />
                </div>
            </div>
        )
    }
}