import React from 'react';
import * as Classes from "@blueprintjs/core/lib/esm/common/classes";
import {Button, Intent, Radio, RadioGroup, Tag} from "@blueprintjs/core";
import EmptyState from "../../core/components/EmptyState";

export default class ModalPopUpDefaultAddress extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            user_address: props.userAddress,
            selected_address: "",
            selected_object_address: ""
        };
    }

    componentWillReceiveProps(nextProps) {
        this.setState({
            user_address: nextProps.userAddress
        })
    }

    handleApplyData = () => {
        this.props.callbackAddNewAddressCloseData(true, this.state.selected_address, this.state.selected_object_address);
    };

    handleCloseData = () => {
        this.props.callbackAddNewAddressCloseData(true, "", "");
    };

    handleAddressChange = (e, address) => {
        this.setState({
            selected_address: e.target.value,
            selected_object_address: address
        })
    };

    render() {
        let h100 = (this.state.user_address.length ? "" : "h-100");

        return (
            <React.Fragment>
                <div className={Classes.DIALOG_BODY + " popup-add-address " + h100}>
                    <div className="row">
                        <div className="col-xl-12 col-lg-12 col-md-12 col-12">
                            <form className="form-horizontal">
                                <div className="row form-data">
                                    {
                                        this.state.user_address.length ?
                                            this.state.user_address.map((address, key) => {
                                                let fullAddress = address.street + " , ";
                                                fullAddress += address.landmark + " , ";
                                                fullAddress += address.area + " , ";
                                                fullAddress += address.city + " , ";
                                                fullAddress += address.state + " - ";
                                                fullAddress += address.pincode + "";

                                                let displayAddress = address.street + ",<br/>";
                                                displayAddress += address.landmark + ",<br/>";
                                                displayAddress += address.area + ", ";
                                                displayAddress += address.city + ", ";
                                                displayAddress += address.state + "-";
                                                displayAddress += address.pincode + "";

                                                return (
                                                    <div className="col-md-12 col-12 text-group d-address mb-4 p-3"
                                                         key={key}>
                                                        <Tag key={address.address_type}
                                                             className={"d-address-tag pull-right"}
                                                        >
                                                            {address.address_type}
                                                        </Tag>
                                                        <div className="mb-3"
                                                             dangerouslySetInnerHTML={{__html: displayAddress}}></div>
                                                        <RadioGroup
                                                            onChange={(e) => {
                                                                this.handleAddressChange(e, address)
                                                            }}
                                                            selectedValue={this.state.selected_address}
                                                        >
                                                            <Radio label="Select Address" value={fullAddress}/>
                                                        </RadioGroup>
                                                    </div>
                                                )
                                            })
                                            : <EmptyState stateType={"checkout_address"}/>
                                    }
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
                {
                    this.state.user_address.length !== 0 ?
                        <div className={Classes.DIALOG_FOOTER}>
                            <div className={Classes.DIALOG_FOOTER_ACTIONS}>
                                <Button intent={Intent.DANGER} onClick={this.handleCloseData}>Cancel</Button>
                                <Button intent={Intent.PRIMARY} onClick={this.handleApplyData}>Apply</Button>
                            </div>
                        </div>
                        : <div className={Classes.DIALOG_FOOTER}>
                            <div className={Classes.DIALOG_FOOTER_ACTIONS}>
                            </div>
                        </div>
                }
            </React.Fragment>
        );
    }
}
