import React from 'react';
import Checkout from './container/CheckoutContainer';
import {Auth, NoLogin} from "gg-react-utilities";

export default class CheckoutComponent extends React.Component {
    render() {
        let type = this.props.match.params.type ? this.props.match.params.type : 0;
        return (
            <React.Fragment>
                {
                    Auth.isLoggedIn() ?
                        <Checkout type={type}/>
                        :
                        <div className="container">
                            <div className="no-login">
                                <div className="no-login-middle">
                                    <NoLogin imageShow={true}/>
                                </div>
                            </div>
                        </div>
                }
            </React.Fragment>
        )
    }
}
