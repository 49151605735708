import React from 'react';
import * as Classes from "@blueprintjs/core/lib/esm/common/classes";
import {Intent, Label, Position, Switch, Toaster} from "@blueprintjs/core";
import Config from "../../core/Config";
import {Auth, Helper, ssoInstance} from "gg-react-utilities";
import EmptyState from "../../core/components/EmptyState";

export default class ModalPopUpData extends React.Component {
    constructor(props) {
        super(props);
        
        this.state = {
            loadingData: true,
            receiptNo: props.receiptNo,
            voucher_view: []
        }
    }

    shouldComponentUpdate(nextProps, nextState) {
        return this.state.voucher_view !== nextState.voucher_view;
    }

    getVoucherData = () => {
        ssoInstance.post(Config.API_URL + "/voucher-view", {receipt_no: this.state.receiptNo})
            .then((res) => {
                let sources = res.data;

                if (sources.status === true)
                    this.setState({
                        voucher_view: sources.data,
                        loadingData: false
                    });
                else
                    this.setState({
                        voucher_view: [],
                        loadingData: false
                    });
            })
            .catch((errors) => {
                if (errors.response.status !== 401) {
                    let message = "";
                    if (errors.response) {
                        message = errors.response.message ? errors.response.message : "Something went wrong!";
                    } else {
                        message = "Something went wrong!";
                    }
                    Toaster.create({
                        position: Position.TOP,
                    }).show({message: message, intent: Intent.DANGER});

                    this.setState({
                        voucher_view: [],
                        loadingData: false
                    });
                }
            })
    };
mapDispatchToProps
    componentWillMount() {
        if (Auth.isLoggedIn()) {
            if (this.state.receiptNo !== null && this.state.receiptNo !== "") {
                this.getVoucherData();
            }
        }
    }

    checkIsReceiptNo = () => {
        if (this.state.receiptNo === "" || this.state.receiptNo === null) {
            return (
                <p className="no-receipt">Order can not be viewed as payment is not done.</p>
            );
        } else {
            if (this.state.voucher_view.length === 0) {
                return (
                    <React.Fragment>
                        <EmptyState stateType={"order_detail"}/>
                    </React.Fragment>
                );
            } else {
                return (
                    <React.Fragment>
                        {this.checkIsVoucher()}
                        {this.chechOrderDetail()}
                        {this.checkOrderBrandDetail()}
                    </React.Fragment>
                );
            }
        }
    };

    checkIsVoucher = () => {
        return (
            <div className="fix-order-voucher-list mb-4">
                {
                    this.state.voucher_view.voucher_detail.length ?
                        this.state.voucher_view.voucher_detail.map((item, key) => {
                            if (item.message_show === true) {
                                return (
                                    <p className="no-voucher" key={key}>{item.message}</p>
                                )
                            } else {
                                let defaultChecked = false;
                                let defaultDisabled = false;
                                if (item.is_used === 1) {
                                    defaultChecked = true;
                                }
                                if (item.voucherNumber === null && item.voucherNumber === null) {
                                    defaultDisabled = true
                                }
                                return (
                                    <div className="voucher-block" key={key}>
                                        <div className="row">
                                            <div className="col-xl-6 col-lg-6 col-md-6 col-sm-3 col-6">
                                                <Label className="title">
                                                    Brand Name
                                                    <span className="sub-title">{item.brandName}</span>
                                                </Label>
                                            </div>
                                            <div className="col-xl-3 col-lg-3 col-md-3 col-sm-3 col-6">
                                                <Label className="title">
                                                    Card Price
                                                    <span
                                                        className="sub-title">{ Helper.formatMoney(item.cardPrice) }</span>
                                                </Label>
                                            </div>
                                            <div className="col-xl-3 col-lg-3 col-md-3 col-sm-3 col-12">
                                                <Label className="title">
                                                    Product Type
                                                    <span
                                                        className="sub-title">{item.productType}</span>
                                                </Label>
                                            </div>
                                        </div>
                                        <hr/>
                                        <div className="row mt-2">
                                            <div className="col-xl-3 col-lg-3 col-md-3 col-sm-3 col-6">
                                                <Label className="title">
                                                    Voucher Number
                                                    <span
                                                        className="sub-title">{item.voucherNumber}</span>
                                                </Label>
                                            </div>
                                            <div className="col-xl-3 col-lg-3 col-md-3 col-sm-3 col-6">
                                                <Label className="title">
                                                    Voucher Pin
                                                    <span
                                                        className="sub-title">{item.voucherPin}</span>
                                                </Label>
                                            </div>
                                            <div className="col-xl-3 col-lg-3 col-md-3 col-sm-3 col-6">
                                                <Label className="title">
                                                    Expiry Date
                                                    <span
                                                        className="sub-title">{item.expiryDate}</span>
                                                </Label>
                                            </div>
                                            <div className="col-xl-3 col-lg-3 col-md-3 col-sm-3 col-12">
                                                <Label className="title">
                                                    unused
                                                    <Switch
                                                        onChange={() => this.onChnageBrandVoucherUse(item.order_id, item.voucherNumber, item.voucherPin)}
                                                        defaultChecked={defaultChecked}
                                                        disabled={defaultDisabled}
                                                    />
                                                </Label>
                                            </div>
                                        </div>
                                    </div>
                                )
                            }
                        })
                        : ""
                }
            </div>
        )
    };

    chechOrderDetail = () => {
        if (this.state.voucher_view.order_detail.length !== 0) {
            return (
                <div className="row">
                    <div className="col-4 mb-4">
                        <Label className="s-title">
                            Order id
                            <span className="s-sub-title">{this.state.voucher_view.order_detail.order_id} </span>
                        </Label>
                    </div>
                    <div className="col-4 mb-4">
                        <Label className="s-title">
                            Receipt No.
                            <span className="s-sub-title">{this.state.voucher_view.order_detail.receipt_no} </span>
                        </Label>
                    </div>
                    <div className="col-4 mb-4">
                        <Label className="s-title">
                            Date Of Purchase
                            <span className="s-sub-title">{this.state.voucher_view.order_detail.create_at} </span>
                        </Label>
                    </div>
                    <div className="col-4 mb-4">
                        <Label className="s-title">
                            Cashback Option
                            <span
                                className="s-sub-title">{this.state.voucher_view.order_detail.caseback_choice_text} </span>
                        </Label>
                    </div>
                    <div className="col-4 mb-4">
                        <Label className="s-title">
                            Order Status
                            <span
                                className="s-sub-title c-g">{this.state.voucher_view.order_detail.order_status_text} </span>
                        </Label>
                    </div>
                    <div className="col-4 mb-4">
                        <Label className="s-title">
                            UPV Status
                            <span
                                className="s-sub-title c-g">{this.state.voucher_view.order_detail.upv_status_text} </span>
                        </Label>
                    </div>
                </div>
            )
        }
    };

    checkOrderBrandTotal = () => {
        if (this.state.voucher_view.order_detail.length !== 0) {
            return (
                <React.Fragment>

                    <div className="table-items mb-3">
                        <div className="row tr-item">
                            <div className="col-xl-3 col-lg-3 col-md-3 col-sm-3 col-4">
                                <div className="d1 t-title">Sub Total</div>
                            </div>
                            <div className="col-xl-5 col-lg-5 col-md-5 col-sm-5 col-4">
                                <div className="d2">&nbsp;</div>
                            </div>
                            <div className="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-4">
                                <div className="d3 t-sub-title">
                                    { Helper.formatMoney(this.state.voucher_view.order_detail.subtotal) }
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="table-items mb-3">
                        <div className="row tr-item">
                            <div className="col-xl-3 col-lg-3 col-md-3 col-sm-3 col-4">
                                <div className="d1 t-title">Shipping Charges</div>
                            </div>
                            <div className="col-xl-5 col-lg-5 col-md-5 col-sm-5 col-4">
                                <div className="d2">&nbsp;</div>
                            </div>
                            <div className="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-4">
                                <div className="d3 t-sub-title">
                                    { Helper.formatMoney(this.state.voucher_view.order_detail.shipping_charges) }
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="table-items mb-3">
                        <div className="row tr-item">
                            <div className="col-xl-3 col-lg-3 col-md-3 col-sm-3 col-4">
                                <div className="d1 t-title">Payment Charges</div>
                            </div>
                            <div className="col-xl-5 col-lg-5 col-md-5 col-sm-5 col-4">
                                <div className="d2">&nbsp;</div>
                            </div>
                            <div className="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-4">
                                <div className="d3 t-sub-title">
                                    { Helper.formatMoney(this.state.voucher_view.order_detail.tax) }
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="table-items mb-3">
                        <div className="row tr-item">
                            <div className="col-xl-3 col-lg-3 col-md-3 col-sm-3 col-4">
                                <div className="d1 t-title">GG Coins</div>
                            </div>
                            <div className="col-xl-5 col-lg-5 col-md-5 col-sm-5 col-4">
                                <div className="d2">&nbsp;</div>
                            </div>
                            <div className="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-4">
                                <div className="d3 t-sub-title">&#8377;&nbsp;
                                    {this.state.voucher_view.order_detail.order_coin}
                                </div>
                            </div>
                        </div>
                    </div>

                    <hr/>

                    <div className="table-items mb-3">
                        <div className="row tr-item">
                            <div className="col-xl-8 col-lg-8 col-md-8 col-sm-8 col-8">
                                <div className={"d1 t-title"}>Grand Total</div>
                            </div>
                            <div className="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-4">
                                <div className={"d3 t-sub-title"}>
                                    { Helper.formatMoney(this.state.voucher_view.order_detail.grandtotal) }
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="table-items mb-3">
                        <div className="row tr-item">
                            <div className="col-xl-8 col-lg-8 col-md-8 col-sm-8 col-8">
                                <div className={"d1 t-title bold"}>Payable Total</div>
                            </div>
                            <div className="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-4">
                                <div className={"d3 t-sub-title bold"}>
                                    { Helper.formatMoney(this.state.voucher_view.order_detail.payable_total) }
                                </div>
                            </div>
                        </div>
                    </div>
                </React.Fragment>
            );
        }
    };

    checkOrderBrandDetail = () => {
        return (
            <div className="row mt-4">
                <div className="col-12">
                    {
                        this.state.voucher_view.voucher_detail.length ?
                            this.state.voucher_view.voucher_detail.map((item, key) => {
                                return (
                                    <div className="table-items mb-3" key={key}>
                                        <div className="row tr-item">
                                            <div className="col-xl-3 col-lg-3 col-md-3 col-sm-3 col-4">
                                                <div className="d1 t-title">{item.brandName}</div>
                                            </div>
                                            <div className="col-xl-5 col-lg-5 col-md-5 col-sm-5 col-4">
                                                <div className="d2">&nbsp;</div>
                                            </div>
                                            <div className="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-4">
                                                <div className="d3 t-sub-title">{ Helper.formatMoney(item.cardPrice) }</div>
                                            </div>
                                        </div>
                                    </div>
                                )
                            })
                            : ""
                    }
                    {this.checkOrderBrandTotal()}
                </div>
            </div>
        )
    };

    loading = () => {
        return (
            <div className="row mb-5">
                <div className="col-12 mb-4">
                    <div className="bp3-skeleton" style={{width: "95%", height: "85px", margin: "0 auto"}}>&nbsp;</div>
                </div>

                <div className="col-12 mb-4">
                    <div className="bp3-skeleton" style={{width: "95%", height: "170px", margin: "0 auto"}}>&nbsp;</div>
                </div>

                <div className="col-12 mb-4">
                    <div className="bp3-skeleton" style={{width: "95%", height: "250px", margin: "0 auto"}}>&nbsp;</div>
                </div>
            </div>
        )
    };

    onChnageBrandVoucherUse = (order_id, voucher_no, voucher_pin) => {
        if (Auth.isLoggedIn()) {
            if (this.state.receiptNo !== null && this.state.receiptNo !== "") {
                this.getBrandVoucherUseToggle(order_id, voucher_no, voucher_pin);
            }
        }
    };

    getBrandVoucherUseToggle = (order_id, voucher_no, voucher_pin) => {
        ssoInstance.post(Config.API_URL + "/voucher-used-status", {
            order_id: order_id,
            voucherNumber: voucher_no,
            voucherPin: voucher_pin,
        })
            .then((res) => {
                let sources = res.data;
                let message = "";

                if (sources.status === true) {
                    message = sources.message ? sources.message : "Voucher updated successfully";
                    Toaster.create({
                        position: Position.TOP,
                    }).show({message: message, intent: Intent.SUCCESS});
                } else {
                    message = sources.message ? sources.message : "Something went wrong!";
                    Toaster.create({
                        position: Position.TOP,
                    }).show({message: message, intent: Intent.DANGER});
                }
            })
            .catch((errors) => {
                if (errors.response.status !== 401) {
                    let message = "";
                    if (errors.response) {
                        message = errors.response.message ? errors.response.message : "Something went wrong!";
                    } else {
                        message = "Something went wrong!";
                    }
                    Toaster.create({
                        position: Position.TOP,
                    }).show({message: message, intent: Intent.DANGER});
                }
            })
    };

    render() {
        return (
            <React.Fragment>
                <div className={Classes.DIALOG_BODY + " popup-voucher"}>
                    {
                        this.state.loadingData && this.state.receiptNo !== "" && this.state.receiptNo !== null ?
                            this.loading()
                            :
                            this.checkIsReceiptNo()
                    }
                </div>
            </React.Fragment>
        );
    }
}
