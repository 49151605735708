import React, {Component} from "react";
import {FavoriteBorderRounded, FavoriteRounded} from '@material-ui/icons';
import {Intent, Position, Toaster} from "@blueprintjs/core";
import {setDefaultMegaBrandImage} from "../utils/Helper";

class CouponCardSecond extends Component {
    constructor(props) {
        super(props);

        this.state = {
            cardData: props.cardData,
            cardNumber: props.cardNumber,
            cardStyleType: props.cardStyleType,
            toggle: null
        };
    }

    componentWillReceiveProps(nextProps) {
        this.setState({
            cardData: nextProps.cardData,
            cardNumber: nextProps.cardNumber,
            cardStyleType: nextProps.cardStyleType,
        });
    }

    handleFavoriteToggle = (e, brand_id) => {
        e.preventDefault();
        this.props.putBrands(brand_id)
            .then((res) => {
                let sources = res.data;
                let message = res.data.message;
                let alertColor = Intent.DANGER;

                if (sources.status === true) {
                    alertColor = Intent.SUCCESS;
                    this.setState({toggle: sources.data.is_favorite});
                }

                Toaster.create({
                    position: Position.TOP,
                }).show({message: message, intent: alertColor})

            })
            .catch((errors) => {
                if (errors.response.status !== 401) {
                    let message = "";
                    if (errors.response) {
                        message = errors.response.message ? errors.response.message : "Something went wrong!";
                    } else {
                        message = "Something went wrong!";
                    }
                    Toaster.create({
                        position: Position.TOP,
                    }).show({message: message, intent: Intent.DANGER});
                }
            });
    };

    render() {
        let favicon = "";

        if (0 === this.state.cardData.is_favorite) {
            favicon = <FavoriteBorderRounded onClick={(e) => {
                this.handleFavoriteToggle(e, this.state.cardData.brand_id)
            }}/>;
        }

        if (1 === this.state.cardData.is_favorite) {
            favicon = <FavoriteRounded onClick={(e) => {
                this.handleFavoriteToggle(e, this.state.cardData.brand_id)
            }}/>;
        }

        if (this.state.toggle !== null) {
            if (0 === this.state.toggle) {
                favicon = <FavoriteBorderRounded onClick={(e) => {
                    this.handleFavoriteToggle(e, this.state.cardData.brand_id)
                }}/>;
            }
            if (1 === this.state.toggle) {
                favicon = <FavoriteRounded onClick={(e) => {
                    this.handleFavoriteToggle(e, this.state.cardData.brand_id)
                }}/>;
            }
        }

        /*
        *  0 : NOTHING
        *  1 : BRAND LIST STYLE
        *  2 : HOME POPULAR BRAND LIST
        *  3 : BRAND DETAIL
        * */
        let customClass = "";

        if (1 === this.state.cardStyleType) customClass = "st-1";
        if (2 === this.state.cardStyleType) customClass = "st-2";
        if (3 === this.state.cardStyleType) customClass = "st-3";
        if (4 === this.state.cardStyleType) customClass = "st-4";

        let ggCoin = "";

        if (1 === this.state.cardData.coin_applicable)
            ggCoin =
                <img src={require("../../core/images/gg_coins.svg")} alt="GG Coins Applicable"
                     className="img-fluid coin-img mt-2 mr-3"/>;

        let tat = "";

        if (this.state.cardData.tat === 0) {
            tat = "Instant";
        } else {
            tat = this.state.cardData.tat + " Days"
        }

        let colorCode = this.state.cardData.color_code ? this.state.cardData.color_code.split(',') : [];

        return (
            <React.Fragment>
                <div className={"mega-brand-card " + customClass} data-brandid={this.state.cardData.brand_id}>
                    <div className="brand-card d-flex justify-content-between item-align-center"
                         style={{backgroundImage: 'linear-gradient(135deg, ' + colorCode[0] + ' 0%, ' + colorCode[1] + ' 100%)'}}
                    >
                        <p className="brand-name">{this.state.cardData.brand_name}</p>
                        <p className="mt-minus">{favicon}</p>
                    </div>
                    <div className="text-center">
                        <img
                            src={this.state.cardData.image}
                            className="img-fluid brand-img"
                            alt={this.state.cardData.brand_name}
                            onError={setDefaultMegaBrandImage}
                        />
                        {ggCoin}
                    </div>
                    <div className="d-flex justify-content-between item-align-center pl-3 pr-3 pt-3 pb-2">
                        <p className="brand-delivery text-center m-auto">Delivery: {tat}</p>
                        <p className="brand-product-type text-center m-auto">{this.state.cardData.product_type} Voucher</p>
                    </div>
                </div>
            </React.Fragment>
        )
    }
}

export default CouponCardSecond;
