import {connect} from "react-redux";
import {ssoInstance} from "gg-react-utilities";
import Config from "../../core/Config";
import * as actions from "../../core/actions";
import GGVoucherTransacationComponent from "../components/GGVoucherTransactionComponent";

const mapStateToProps = state => {
    return {
        GGVoucherTransaction: state.OrderHistory.GGVoucherTransaction,
    }
};

const mapDispatchToProps = dispatch => {
    return {
        getGGVoucherTransaction: (userId) => {
            let url = Config.APP_WALLET_URL + "/v1.0.2/vouchers/"+userId+"/list";
            return ssoInstance.get(url)
                .then((res) => {
                    let sources = res.data;
                    //console.log("sources",sources);
                    if (sources.status === true)
                        dispatch(actions.setGGVoucherTransaction(sources.data));
                    else
                        dispatch(actions.setGGVoucherTransaction([]));
                })
                .catch((errors) => {
                    if (errors.response.status !== 401) {
                        /*let message = "";
                        if (errors.response) {
                            message = errors.response.message ? errors.response.message : "Something went wrong!";
                        } else {
                            message = "Something went wrong!";
                        }
                        Toaster.create({
                            position: Position.TOP,
                        }).show({message: message, intent: Intent.DANGER});*/

                        dispatch(actions.setGGOrderHistory([]));
                    }
                })
        }

    }
};

export default connect(mapStateToProps, mapDispatchToProps)(GGVoucherTransacationComponent);
