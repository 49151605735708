import React, {Component} from 'react';
import {Intent, Position, Toaster} from "@blueprintjs/core";

export default class IsCoinComponent extends Component {
    constructor(props) {
        super(props);

        this.state = {
            max_gg_coin: props.maxGgCoin,
            slug: props.slug,
            gg_coin_value: props.defaultAppliedCoin,
            active: (props.defaultAppliedCoin > 0) ? true : false,
            defaultQuantity: props.defaultQuantity,
            price: props.price,
            isAllow: props.isAllow,
            userCoinBalance: props.userCoinBalance
        }
    }

    componentWillReceiveProps(nextProps) {
        this.setState({
            max_gg_coin: nextProps.maxGgCoin,
            slug: nextProps.slug,
            defaultQuantity: nextProps.defaultQuantity,
            price: nextProps.price,
            gg_coin_value: nextProps.defaultAppliedCoin,
            active: (nextProps.defaultAppliedCoin > 0) ? true : false,
            isAllow: nextProps.isAllow,
            userCoinBalance: nextProps.userCoinBalance
        })
    }

    onChangeGgCoin = (e) => {
        const re = /^[0-9\b]+$/;

        if (e.target.value === '' || re.test(e.target.value)) {
            if (this.state.isAllow === 1) {
                let totalCoin = this.props.callBackPrevTotalAppiledCoin();
                let userCoinBalance = this.state.userCoinBalance;

                let checkUserCoin = parseInt(userCoinBalance, 10) - (parseInt(e.target.value, 10) + parseInt(totalCoin, 10));

                if (e.target.value > this.state.max_gg_coin) {
                    let message = "You can redeem only " + this.state.max_gg_coin + " GG Coins ";
                    Toaster.create({
                        position: Position.TOP,
                    }).show({message: message, intent: Intent.DANGER});
                    e.target.value = 0;
                    return false;
                }

                if (checkUserCoin < 0) {
                    let message = "you have insufficient GG Coin.";
                    Toaster.create({
                        position: Position.TOP,
                    }).show({message: message, intent: Intent.DANGER});
                    e.target.value = 0;
                    return false;
                }

                this.setState({gg_coin_value: e.target.value});
            }

        }

    };

    handleRedeemCoin = () => {
        if (this.state.isAllow === 1) {
            if (this.state.gg_coin_value === 0 ||
                this.state.gg_coin_value === "0" ||
                this.state.gg_coin_value === "")
                return false;

            const currentState = this.state.active;
            this.setState({active: !currentState}, () => {
                    if (!this.state.active) {
                        this.setState({
                            gg_coin_value: 0
                        }, () => {
                            this.props.callbackRedeemCoin(this.state.slug, this.state.price, this.state.defaultQuantity, this.state.gg_coin_value)
                        });
                    } else {
                        this.props.callbackRedeemCoin(this.state.slug, this.state.price, this.state.defaultQuantity, this.state.gg_coin_value)
                    }
                }
            );
        }
    };

    render() {
        return (
            <React.Fragment>
                <div className='col-xl-8'>
                    <div className="bp3-control-group bg-coin-color">
                        <p className="m-auto">GG-Coins</p>
                        <div className="bp3-input-group bp3-input-counter-group m-auto w-25">
                            <input
                                disabled={this.state.active ? "disabled" : ""}
                                type="text"
                                className="bp3-input bp3-input-counter bp3-input-counter-small text-center inp-count"
                                placeholder="0" value={this.state.gg_coin_value}
                                onChange={this.onChangeGgCoin}
                            />
                        </div>
                        <div className="">
                            <button
                                className={"bp3-button " + (this.state.active ? "bp3-intent-danger" : "bp3-intent-success")}
                                onClick={this.handleRedeemCoin}>
                                {this.state.active ? "Remove" : "Redeem"}
                            </button>
                        </div>
                    </div>
                </div>
                <div className='col-xl-12'>
                    <h5 className="mt-2">Applicable GG Coins: <b>{this.state.max_gg_coin}</b></h5>
                </div>
            </React.Fragment>
        );
    }
}
