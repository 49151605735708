import React, {Component} from 'react';

export default class ChangeableQuantityComponent extends Component {
    constructor(props) {
        super(props);

        this.state = {
            max_quantity_allow: props.maxQuantity,
            defaultQuantity: props.defaultQuantity,
            slug: props.slug,
            price: props.price,
            isAllow: props.isAllow
        }
    }

    componentWillReceiveProps(nextProps) {
        this.setState({
            max_quantity_allow: nextProps.maxQuantity,
            defaultQuantity: nextProps.defaultQuantity,
            slug: nextProps.slug,
            price: nextProps.price,
            isAllow: nextProps.isAllow
        });
    }

    handleDecreaseQuantity = () => {
        if (this.state.isAllow === 1) {
            if (this.state.defaultQuantity > 0 && this.state.max_quantity_allow > 1 ) {
                this.setState({
                    defaultQuantity: this.state.defaultQuantity - 1
                }, () => {
                    this.props.getQuantity(this.state.slug, this.state.price, this.state.defaultQuantity)
                })
            }
        }
    };

    handleIncreaseQuantity = () => {
        if (this.state.isAllow === 1) {
            if (this.state.defaultQuantity < 10 && this.state.max_quantity_allow > 1 ) {
                this.setState({
                    defaultQuantity: this.state.defaultQuantity + 1
                }, () => {
                    this.props.getQuantity(this.state.slug, this.state.price, this.state.defaultQuantity)
                })
            }
        }
    };

    render() {
        return (
            <React.Fragment>
                <div className="bp3-control-group mb-2 align-items-center d-flex">
                    <button onClick={this.handleDecreaseQuantity}
                            className="bp3-button bp3-decrease-small bp3-decrease">
                        <span className="bp3-icon bp3-icon-minus"></span>
                    </button>
                    <div
                        className="bp3-input-group bp3-input-counter-group">
                        <input type="text"
                               className="bp3-input bp3-input-counter bp3-input-counter-small text-center"
                               defaultValue={this.state.defaultQuantity}
                        />
                    </div>
                    <button onClick={this.handleIncreaseQuantity}
                            className="bp3-button bp3-increase-small bp3-increase">
                        <span className="bp3-icon bp3-icon-plus"></span>
                    </button>
                </div>
            </React.Fragment>
        );
    }
}
