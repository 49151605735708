import React from 'react';
import TopBar from "../core/components/TopBar";
import HomePageContainer from "./containers/HomeContainer";

export default class Home extends React.Component {
    render() {
        return (
            <div className="container mg-brand-home mb-5">
                <div className="row">
                    <TopBar
                        title="Mega Brands | Cashback Voucher | Global Garner"
                        description="Buy your favorite brand from Global Garner & get cashback voucher on your favorite brands at Global Garner, enjoy shopping with Global Garner"
                        keywords="Myntra , Myntra Online Shopping, Pantaloons, Fast track, Makemytrip, big basket, bookmyshow, Big Bazzar, Flipkart,flipkart Sale, flipkart online shopping flipkartoffers, Mega Brand, Cashback voucher, gift vouchers, discount gift cards, online cashback vouchers, deals"
                        links="home"
                        isDashboard={true}
                        tabManu={"megaBrands"}
                        isvideoDialog={true}
                    />
                </div>
                <div className="row">
                    <HomePageContainer/>
                </div>
            </div>
        )
    }
}
